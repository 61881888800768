import React from  'react'
import {TableCell} from '@material-ui/core'
import {withRouter} from 'react-router-dom'
import '../css/button.css'

function bmsIdColumn  (props,row) {
    const handleNavigate=()=>{
        props.history.push({pathname:`/battery/detail/${props.row.id}`, state:{ ...props.row }})
      }
      return (
      <TableCell  align='center'>
        <div>
          <button className='bmsIdButton' onClick={handleNavigate}>
          {props.row.BMSId}</button>
        </div>
      </TableCell>
      )
  }

  export default withRouter(bmsIdColumn)