export const changeProgress = progress => {
  return{
    type: 'changeProgress',
    payload:progress
  } 
}

export const showBar = progress => {
  return{
    type: 'showBar',
    payload:progress
  } 
}


