import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios'
import * as XLSX  from 'xlsx';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
//import Paper from '@material-ui/core/Paper';
import './tradein.css'
import BeatLoader from "react-spinners/BeatLoader";

const Tradein = () => {

    const [tradein, setTradein] = useState();
    const [bottomRange, setbottomRange] = useState();
    const [topRange, setTopRange] = useState();
    const [selected, setSelected] = useState([]);
    const [selected2, setSelected2] = useState([]);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [editRowId, setEditrowId] = useState(null);
    const [resendRow, setResendRow] = useState(null);
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(false)
    const [loadingResend, setLoadingResend] = useState(false)
    const formatter = new Intl.NumberFormat('id', {
        style:'currency',
        currency:'IDR',
        maximumFractionDigits: 0
    })

    function getTradeinReq(){
        axios.get(`${process.env.REACT_APP_BASEURL}/admin/tradein/get-tradein`, {
            headers : {
                'token' : localStorage.getItem('token')
            }
        }).then((response)=>
          {
              setTradein(response.data)
          }
        )      
    }

    const handleChange = event => {
                var updatedList = [...selected];
        if (event.target.checked) {
            updatedList = [...selected, event.target.value];
        } else {
            updatedList.splice(selected.indexOf(event.target.value), 1);
        }
        setSelected(updatedList)
    }

    const handleChange2 = event => {

        var updatedList = [...selected2];
               
        if (selected2.includes(event.target.value)) {
            console.log(event.target.checked)
            updatedList = updatedList.filter(item => item !== event.target.value); 
            
        } else {
            console.log(event.target.checked)
            updatedList = [...selected2, event.target.value];
           
        }
        setSelected2(updatedList)
    }

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setSelected2(tradein?.data.map((li, idx) => li.id.toString()));
        if (isCheckAll) {
          setSelected2([]); 
        }
    }

    const handleSubmit = (ad,e) =>{
        e.preventDefault()

            axios.post(`${process.env.REACT_APP_BASEURL}/admin/tradein/v3/send-tradein`,{
                tradeId:  ad.id,
                bottomRange: parseInt(bottomRange),
                topRange: parseInt(topRange)
            }
                , {
            headers: {
                'token': localStorage.getItem("token"),
                // 'accept':"*/*",
                // 'Content-Type': 'application/x-www-form-urlencoded' 
            }}
    ).then((res)=>{
      alert(`berhasil mengirim trade in no ${ad.id}`)
      setSelected([])
      setTopRange()
      setbottomRange()
      getTradeinReq(); 
    })
    }

    function handlePDF(ad,e){
        setLoading(true)
        axios.get(`${process.env.REACT_APP_BASEURL}/admin/tradein/download-tradein?tradeId=${ad.id}&bottomRange=${ad.status === 'SEND' ? ad.bottomRange : bottomRange}&topRange=${ad.status === 'SEND' ? ad.topRange : topRange}&proposal=[${selected}]`,
        { responseType: "blob" ,
                headers : {'token': localStorage.getItem("token")}}
        )
        .then((res)=>{
            const blob = new Blob([res.data], {type: 'application/pdf'})
            const blobURL = URL.createObjectURL(blob);
            const link = document.createElement('a')
            link.href = blobURL
            window.open(link)
            setEditrowId(null)
            setTopRange()
            setbottomRange()
            setLoading(false)
            })
        .catch((err)=> {
            alert('gagal generate pdf')
            setLoading(false)
        }) 
    }   

    function handlePDFSent(ad,e){
        window.open(`https://ecgobikeid.com${ad.pdf_link}`)
    }
    
    function handleExcel(){
        axios.post(`${process.env.REACT_APP_BASEURL}/admin/tradein/v2/raw-excel-tradein`, {
            tradeIds : JSON.stringify(selected2)
        } , {
            headers : {
                'token' : localStorage.getItem('token')
            }
        }).then((response)=>
          {
            var data=[]
            response.data.data.forEach(element => {
                data.push({
                    id:element.id,
                    name:element.name,
                    phoneNumber:element.phoneNumber,
                    brand:element.brand,
                    model:element.model,
                    type:element.type,
                    year:element.year,
                    mileage:element.mileage,
                    plate:element.plate,
                    image:`=hyperlink("${element.bikeImage[0] === '/' ? process.env.REACT_APP_BASEURL+element.bikeImage : element.bikeImage}")`,
                    salesName:element.staff.name,
                    address:element.staff.address,
                    tanggal:element.createdAt
                })
            })
            var wb = XLSX.utils.book_new()
            let ws = XLSX.utils.json_to_sheet(data)
            ws['!cols'] = [];
            ws['!cols'] = [
                {wch: 3},
                {wch: 15},
                {wch: 15},
                {wch: 10},
                {wch: 10},
                {wch: 10},
                {wch: 8},
                {wch: 8},
                {wch: 8},
                {wch: 80},
                {wch: 20}, 
                {wch: 15},
                {wch: 25},// "characters"
            ];

            XLSX.utils.book_append_sheet(wb,ws, 'mySheet1');
            XLSX.writeFile(wb, 'myExcel.xlsx') 
          }
        )      
    }

    function handleToprange(ad, e){
        const result = e.target.value.replace(/\D/g, '');
        setTopRange(result);
    }

    function handleBotrange(ad, e){
        const result = e.target.value.replace(/\D/g, '');
        setbottomRange(result)
    }
    
    function handleEdit(ad,e){
        e.preventDefault();
        setEditrowId(ad.id)
        setSelected([])
    }

    function handleResend(ad, e){
        setResendRow(ad.id)
        setLoadingResend(true)
        axios.post(`${process.env.REACT_APP_BASEURL}/admin/tradein/v3/re-send-tradein`,{
            tradeId:  ad.id,
        }
            , {
        headers: {
            'token': localStorage.getItem("token"),
        }}
        ).then((res)=>{
            setLoadingResend(false)
            setResendRow(null)
            alert(`berhasil mengirim ulang trade in no ${ad.id}`)
            getTradeinReq();
            setSelected([]) 
        })
        }

    useEffect(()=>{
        getTradeinReq()
    },[])

    useEffect((ad)=>{
       // let allChecked = true;
            if (selected2.length !== tradein?.data.length) {
                setIsCheckAll(false)
            } else ( setIsCheckAll(true) )
        },[selected2]
    )

   
  return (
    <div className='tradein'  style={{paddingTop:'5rem', maxWidth:'100vw'}}>
        <button type="button"  className='addUserBtn'  onClick={handleExcel}>Export Excel</button>
        <input onChange={(e)=>setSearch(e.target.value)} placeholder="Pencarian" style={{height:"2.5rem"}}></input>
        {/* <Paper style={{maxWidth:"100vw"}}> */}
            <Table >
    
                <TableRow >
                    <TableCell style={{fontWeight:"bold"}}><input type='checkbox'  onChange={handleSelectAll} checked={isCheckAll}/></TableCell>
                    <TableCell style={{fontWeight:"bold"}}>ID</TableCell>
                    <TableCell style={{fontWeight:"bold"}}>Tanggal</TableCell>
                    <TableCell style={{fontWeight:"bold", width:"10rem"}}>Name</TableCell>
                    <TableCell style={{fontWeight:"bold"}}>Telp</TableCell>
                    <TableCell style={{fontWeight:"bold"}}>Brand</TableCell>
                    <TableCell style={{fontWeight:"bold", width:"4rem"}}>Model</TableCell>
                    <TableCell style={{fontWeight:"bold", width:"8rem"}}>Type</TableCell>
                    <TableCell style={{fontWeight:"bold", width:"5rem"}}>Year</TableCell>
                    <TableCell style={{fontWeight:"bold"}}>Mileage</TableCell>
                    <TableCell style={{fontWeight:"bold", width:"5rem"}}>Tahun Pajak</TableCell>
                    <TableCell style={{fontWeight:"bold"}}>Estimasi Trade In</TableCell>
                    <TableCell style={{fontWeight:"bold"}}>Status</TableCell>
                    {/* <TableCell style={{fontWeight:"bold"}}>Proposal</TableCell> */}
                    <TableCell style={{fontWeight:"bold"}}>Action</TableCell>
                </TableRow>
            
            
                   
                {tradein?.data.filter((trans)=> {
                        return (
                        search.toLowerCase() === '' 
                        ? trans
                        : trans.name?.includes(search)
                        )
                    }
                        ).map((ad, id)=>{
                                            return (
                                <>
                                    <TableBody key={id} style={
                                    ad.status === 'SEND' ? 
                                    {background:'linear-gradient(251deg, rgba(18,185,2,1) 0%, rgba(255,255,255,1) 100%)'} 
                                    : 
                                    ad.status === 'FAILED' ?  {background:'linear-gradient(251deg, rgba(246,22,22,1) 0%, rgba(255,255,255,1) 100%)'} : {}}>
                                    <TableRow >
                                        <TableCell >
                                            <input type='checkbox'
                                            onChange={handleChange2} 
                                            key={id} 
                                            name='checkone'
                                            value={ad.id} 
                                            checked={selected2.includes(ad.id.toString())}
                                            />
                                        </TableCell>
                                        <TableCell >{ad.id}</TableCell>
                                        <TableCell >{ad.createdAt.slice(0,10)}</TableCell>
                                        <TableCell >{ad.name}</TableCell>
                                        <TableCell >{ad.phoneNumber}</TableCell>
                                        <TableCell >{ad.brand}</TableCell>
                                        <TableCell >{ad.model}</TableCell>
                                        <TableCell >{ad.type}</TableCell>
                                        <TableCell >{ad.year}</TableCell>
                                        <TableCell >{ad.mileage}</TableCell>
                                        <TableCell >{ad.plate}</TableCell>
                                        { 
                                           (ad.bottomRange === null && editRowId !== ad.id) ?  
                                            (<TableCell ><a>Klik edit</a></TableCell> )
                                              :    
                                                (ad.bottomRange === null && editRowId === ad.id) ? 
                                                    (<TableCell >
                                                            <input style={{width:'7rem'}} 
                                                                type='text' pattern='[0-9]*' 
                                                                placeholder='Batas bawah' 
                                                                name='bottomRange' 
                                                                value={bottomRange}  
                                                                onChange={(e)=>handleBotrange(ad,e)}/><br/>
                                                            <input style={{width:'7rem'}} 
                                                                type='text' pattern='[0-9]*' 
                                                                onInput={(e)=>handleToprange(ad,e)} 
                                                                value={topRange} 
                                                                placeholder='Batas Atas' 
                                                                onChange={(e)=>handleToprange(ad,e)}/>
                                                    </TableCell>)
                                                        : (ad.bottomRange != null) &&
                                                            (<TableCell >
                                                                <a>BB :</a> {formatter.format(ad.bottomRange)}<br/>
                                                                <a>BA :</a> {formatter.format(ad.topRange)}
                                                            </TableCell>)
                                        }
                                        <TableCell >{ad.status}</TableCell>
                                        {/* <TableCell >
                                            { 
                                                editRowId !== ad.id ? 
                                                (<a>Klik Edit</a>)
                                             :
                                                (tradein?.proposal.map((prop, id)=>{
                                                    return(
                                                        <div key={id}>
                                                            <label htmlFor={prop.id}>{prop.name}</label>
                                                            <input  type="checkbox" 
                                                                    name="proposal" 
                                                                    id={prop.id} 
                                                                    value={parseInt(prop.id)} 
                                                                    onChange={handleChange} 
                                                                    checked={selected.includes(prop.id.toString())}
                                                            />
                                                        </div>
                                                    )
                                                }))}
                                        </TableCell> */}
                                        <TableCell >
                                            {(editRowId !== ad.id) ? 
                                                (
                                                <>
                                                <button onClick={(e)=>handleEdit(ad,e)}>PDF</button>
                                               
                                                </>
                                                )
                                                    :
                                                        (ad.status === 'SEND' && loading === false) ?
                                                        (
                                                        
                                                        <button onClick={(e)=>handlePDFSent(ad,e)}>Show PDF</button>
                                                        
                                                        )
                                                        :
                                                        (loading === true) && (<BeatLoader  color='green' loading={loading} size={25}/>)
                                              
                                            }
                                            <br/>
                                            <br/>
                                            {
                                                (ad.status === 'REQUESTED' &&  editRowId !== ad.id) 
                                                    ? 
                                                    (<button onClick={(e)=>handleEdit(ad,e)}>Edit</button>) 
                                                        :
                                                        (ad.status === 'REQUESTED' && editRowId === ad.id) 
                                                            ?
                                                            (<button  onClick={(e)=>handleSubmit(ad,e)}>Kirim</button>)
                                                                :
                                                                (ad.status === 'SEND') 
                                                                    &&
                                                                    (<button onClick={(e)=>handleResend(ad,e)}>{(loadingResend === true && resendRow == ad.id)? <BeatLoader  color='green' loading={loadingResend} size={15}/> : <>Resend ({ad.sent_count})</>}</button>)
                                                                        
                                                                            
                                            }
                                        </TableCell>
                                    </TableRow>
                                    </TableBody>
                                </>
                            )
                        })
                        }
                   
        
            </Table>
        {/* </Paper> */}
    </div>
  )
}

export default Tradein