import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios'
import Table from '@material-ui/core/Table';
import Modal from '@material-ui/core/Modal';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import BeatLoader from "react-spinners/BeatLoader";
import ReactPaginate from 'react-paginate';
import { Button } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';

const Bike = () => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      }
    const formatter = new Intl.NumberFormat('id', {
        style:'currency',
        currency:'IDR',
        maximumFractionDigits: 0
      })
    const [orders, setOrders] = useState()
    const [trip, setTrip] = useState()
    const [open, setOpen] = useState(false);
    const [openPayments, setOpenPayments] = useState(false);
    const [openOrders, setOpenOrders] = useState(false);
    const [openBuyers, setOpenBuyers] = useState(false);
    const [search, setSearch] = useState('')
    const [otr, setOTR] = useState('0')
    const [motor, setMotor] = useState()
    const [vehicleNew, setVehicleNew] = useState([])
    const [paketNew, setpaketNew] = useState()
    const [pageCount, setpageCount] = useState()
    const [payment_status, setPayment_status] = useState('')
    
    
    function getData(){
        axios.get(`${process.env.REACT_APP_BASEURL}/newAdmin/rentbike/bike?currentPage=1&orderBy=id&dir=desc&perPage=all`, {
            headers : {
                'token' : localStorage.getItem('token')
            }
        }).then((response)=>
          {
            setOrders(response.data.data.rows)
          }
        ) 
    }

    function getTrip(ad){
        axios.get(`${process.env.REACT_APP_BASEURL}/newAdmin/rentbike/trip?vehicleId=${ad.id}`, {
            headers : {
                'token' : localStorage.getItem('token')
            }
        }).then((response)=>
          {
              setTrip(response.data.data)
          }
        ) 
    }
    


    useEffect(()=>{
      getData()
    },[])

    
  return (
    <div style={{paddingTop:'5rem'}}>
    <Modal 
      open={openPayments}
      onClose={()=>setOpenPayments(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{overflow:"auto", top:"0"}}
    >
      <Box sx={style} style={{textAlign:"center"}}>
      <Link  to={{pathname:'/dailypayment', state:{userId:trip?.trips[0]?.userId}}}><button className='addUserBtn' >Check Daily Payment</button></Link>&nbsp;&nbsp;
      <Link  to={{pathname:'/extrapayment', state:{userId:trip?.id}}}><button className='addUserBtn' >Check Extra Payment</button  ></Link>&nbsp;&nbsp;
      <Link  to={{pathname:'/extrabatt', state:{userId:trip?.id}}}><button className='addUserBtn' >Check Extra Battery</button  ></Link>
      <Table >
        
        <TableRow >
            <TableCell style={{fontWeight:"bold"}} className='tid'>ID</TableCell>
            <TableCell style={{fontWeight:"bold"}}>User ID</TableCell>
            <TableCell style={{fontWeight:"bold"}}>Tanggal</TableCell>
            <TableCell style={{fontWeight:"bold"}}>batteryId</TableCell>
        </TableRow>
    
        
        <TableBody>
            {trip?.trips?.map((ad)=>{
                return (
                    <>
                        <TableRow key={ad.id}>
                            <TableCell>{ad.id}</TableCell>
                            <TableCell>{ad.userId}</TableCell>
                            <TableCell>{ad.createdAt.slice(0,10)}</TableCell>
                            <TableCell>{ad?.batteryId}</TableCell>
                        </TableRow>
                    </>
                )
            })}
        </TableBody>
        
    </Table>
      </Box>
    </Modal>

   
      <input onChange={(e)=>setSearch(e.target.value)} placeholder="Nama Driver" style={{height:"2.5rem"}}></input>
      <button  style={{height:"2.5rem"}}>Search</button>

      
      &nbsp;
      &nbsp;


    <br/>
    <br/>

    <Paper>
        <Table >
        
            <TableRow >
                <TableCell style={{fontWeight:"bold"}} className='tid'>ID</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Tanggal</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Rangka</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Dinamo</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Controller No</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Nopol</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Nama</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Payment Count</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Check Trip</TableCell>
            </TableRow>
        
            
            <TableBody>
                {orders?.filter((ad)=> {
                        return (
                        search.toLowerCase() === '' 
                        ? ad
                        : ad.trips[0]?.user?.name?.includes(search)
                        )
                    }
                        ).map((ad)=>{
                    return (
                        <>
                            <TableRow key={ad.id}>
                                <TableCell>{ad.id}</TableCell>
                                <TableCell>{ad.createdAt.slice(0,10)}</TableCell>
                                <TableCell>{ad?.rangka}</TableCell>
                                <TableCell>{ad.dinamo}</TableCell>
                                <TableCell>{ad.controllerNumber}</TableCell>
                                <TableCell>{ad.nopol}</TableCell>
                                <TableCell>{ad.trips[0]?.user?.name}.</TableCell>
                                <TableCell>{ad.paymentCounter}</TableCell>
                                <TableCell><button onClick={()=>{getTrip(ad);setOpenPayments(true)}}>Detail</button></TableCell>
                            </TableRow>
                        </>
                    )
                })}
            </TableBody>
            
        </Table>
    </Paper>  
    
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={0}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
        containerClassName={'pagination'}
        pageClassName={'pageContainer'}
        pageLinkClassName={'changePageButton'}
        activeClassName={'active'}
      />
  </div>
  )
}

export default Bike