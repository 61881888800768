import React from 'react'
import {Paper,Table,TableHead,TableRow,TableBody,TableCell} from '@material-ui/core'
import '../../css/pageList.css'

export default ({tableSpec,dataDisplayed,openModal})=>
<Paper style={{width: "100%",overflowX: "auto"}}>
    <Table >
        <TableHead >
            <TableRow >
            {tableSpec.filter(item=>item.shown).map(item=><TableCell align='center' key={item.Title} style={{fontFamily: 'Open Sans'}}>{item.Title} </TableCell>)}
            </TableRow >
        </TableHead>
        <TableBody>
            {dataDisplayed.map((row,index) => 
            <TableRow hover key={index} >
                {tableSpec.filter(item=>item.shown).map(item=>{
                switch(item.type){
                    case 'data':{
                        if(!row[item.value])return <TableCell align="center" >-</TableCell>
                        return<TableCell align="center" key={item.Title} style={{fontFamily: 'Lato'}}>{row[item.value]}</TableCell>
                    }
                    case 'action':{
                        return item.component({row,item})
                    }
                    case 'index':{
                        return<TableCell align="center" style={{fontFamily: 'Lato'}} key={item.Title}>{index+1}</TableCell>
                    }
                    default:return null;
                }
                })}
            </TableRow>
            )}
        </TableBody>
    </Table>
</Paper>