import React from 'react'
import { Box, AppBar, Tabs, Tab, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {showBar, changeProgress} from '../../action/progressBar'
import TableBatteryDealer from './components/batteryDealer/tableBatteryDealer'
import TableVehicleDealer from './components/vehicleDealer/tableVehicleDealer'
import '../../css/pageList.css'

function BatteryDealer (props){
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const theme = useTheme();

  const handleChangeTab = (event, tabValue) => {
    setValue(tabValue);
  };

  return(
    <div>
      <div className={classes.root}>
        <AppBar position="static" color="default" style={{marginTop:'70px'}}>
          <Tabs
            value={value}
            onChange={handleChangeTab}
            indicatorColor="primary"
            style={{color:'black'}}
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Battery Dealer Table" {...a11yProps(0)} style={{fontFamily:'Open'}}/>
            <Tab label="Vehicle Dealer Table" {...a11yProps(1)} style={{fontFamily:'Open'}}/>
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <TableBatteryDealer/>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <TableVehicleDealer/>
        </TabPanel>
      </div>
    </div>
  )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography component="div" role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`}
      {...other}
      >
      <Box p={2}>{children}</Box>
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
}));

const mapStateToProps = state => {
  return {
    progress:state.ProgressBar.progress,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeProgress:(value) => {dispatch(changeProgress(value))},
    showBar:(value) => {dispatch(showBar(value))},
  }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(BatteryDealer))