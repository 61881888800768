import React,{useState,useEffect} from "react";
import {showBar, changeProgress} from '../../action/progressBar'
import { insertDataBattery, changePageBattery, lastTotalPageBattery,fetchStatus, lastCurrentPageBattery,insertDataExport, insertDataFlushBattery,totalPageBattery } from '../../action/batteryTable/battery'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {search} from '../../action/userTable/search'
import Utils from '../../utils'
import SearchingField from "../../component/search";
import BatteryGPS from './component/actionBattery'
import AddCommandModal from './component/addModalCommand'
import DayJs from 'dayjs'
import { ExportReactCSV } from '../../component/export'

function MainBattery (props) {
    const [dataBattery, setdataBattery] = useState([])
    const [loading,setLoading]=useState(true)
    const [searchMode,setSearchMode]=useState(false)
    const [canSearch,setCanSearch]=useState(true)
    const [dataBatteryDisplayedSearch,setDataBatteryDisplayedSearch]=useState([])
    const [idBattery, setIdBattery]=useState({id:''})
    const [openModalCommand, setOpenModalCommand]= useState(false)
    const [command,setCommand]= useState({batteryId:'',command:'addbalance',value:''})
    const [dataExportBattery, setDataExportBattery]= useState([])


    useEffect(()=>{setPage()},[])
    useEffect(()=>{setDataDisplayed()},[props.currentPageDataBattery,searchMode])
    useEffect (()=>{setStatus()},[])
    useEffect (()=>{setExportData()},[props.exportingData])

    const handleChangePageWithNumberBattery=(data)=>{setPage(parseInt(data.selected)+1)}
    
    const tableSpec=[
          {Title:'No',obj:['index'],value:'index',shown:true,type:'data'},
          {Title:'Battery ID',obj:['batteryId'],value:'id',shown:false,type:'data'},
          {Title:'BMS ID  ',obj:['bmsId'],value:'bmsId',shown:true,type:'data'},
          {Title:'GPS ID',obj:['battgen2', 'batteryNo'],value:'batteryNo',shown:true,type:'data'},
          {Title:'Remain Cap',obj:['remaincap', 'remaincap'],value:'remaincap',shown:true,type:'data'},
          {Title:'Battery Status', obj:['battgen2', 'status'], value:'status',type:'data',shown:true},
          {Title:'Last Signal', obj:['lastSeen'], value:'lastSeen',type:'data',shown:true},
          {Title:'Data Type',obj:['dataType'],value:'dataType',shown:false,type:'data'},
          {shown:true,type:'action',component:BatteryGPS, setId:setIdBattery, idBattery:idBattery, setOpenModal:setOpenModalCommand, setDataCommand:setCommand,dataCommand:command, props:props},
    ]


    const setPage = async (page) => {
      if(!searchMode){
        if (typeof page !== "number") {
          if (!props.battery[props.limitBattery * parseInt(props.currentPageDataBattery) - 1]) await fetch(1)
          setLoading(false);
        } 
        else {
          if (props.battery.filter(item => item.page == page).length < props.limitBattery) return await fetch(page)
          props.changePageBattery(page);
        }
      }
      else{
        if (props.battery.filter(item => item.page == page &&item.dataType==='search').length < props.limitBattery) return await fetchOtherSearch(page)
        props.changePageBattery(page);
      }
    }

    const fetchOtherSearch = async(page)=>{
      setCanSearch(false)
      try {
        const response = await Utils.Axios(props, 'GET',`/cs/battery/search?search=${props.search}&page=${page?page:1}&limit=20`,true).catch(e=>{throw e})
        props.insertDataBattery(dataBuilder(response.data,'search',props.limit))
        props.changePageBattery(response.data.page)
        props.totalPage(response.data.totalPages)
      }
      catch(e){alert(e.message)}
      finally{setCanSearch(true)}
    }

    const fetch = async(page) => {
        setCanSearch(false)
        try {
          const response = await Utils.Axios(props,'GET',`/cs/battery/all?page=${page}&limit=20`,true).catch(e=>{throw e})
          props.insertDataBattery(dataBuilder(response.data,null,props.limitBattery))
          props.changePageBattery(response.data.page)
          props.totalPage(response.data.totalPages)
        } 
        catch (error) {alert(error.message)}
        finally{setCanSearch(true)}
    }

    const setDataDisplayed = ()=> {
        const data = props.battery.filter(item=>searchMode?item.page==props.currentPageDataBattery&&item.dataType==='search':item.page==props.currentPageDataBattery).map((item,index)=>{
          let finalData = {}
          tableSpec.forEach(element=>{
            if(element.type=='data'){
              const arr = Utils.DataConstructor(item, element.obj,index)
              Object.assign(finalData, {
                [element.value]: arr
              })
            }
          })
          return finalData
        })
        searchMode? setDataBatteryDisplayedSearch(data): setdataBattery(data)
    }

    const fetchSearch = async(page)=>{
      setCanSearch(false)
      try {
        const temp = props.battery.filter(item=>item.dataType!=='search')
        props.insertDataFlushBattery(temp)
        const response = await Utils.Axios(props, 'GET',`/cs/battery/search?search=${props.search}&page=${page?page:1}&limit=20`,true).catch(e=>{throw e})
        if(!searchMode)props.setlastCurrentPageBattery(props.currentPageDataBattery)
        if(!searchMode)props.setlastTotalPagesBattery(props.totalPagesBattery)
        props.insertDataBattery(dataBuilder(response.data,'search',props.limitBattery))
        props.changePageBattery(response.data.page)
        props.totalPage(response.data.totalPages)
        setSearchMode(true)
      }
      catch(e){alert(e)}
      finally{setCanSearch(true)}
    }

    const cancelSearch=(e)=>{
      e.preventDefault()
      setdataBattery([])
      const temp = props.battery.filter(item=>item.dataType!=='search')
      props.insertDataFlushBattery(temp)
      props.totalPage(props.lastTotalPagesBattery)
      props.changePageBattery(props.currentPageDataBattery)
      setSearchMode(false)
    }
    
    const setStatus =  async() => {
      if (props.status!=true) await fetchExport()
    }

    const fetchExport = async() => {
      try {
        const response = await Utils.Axios(props,'POST', `/system/crud/read`, true, {dbname:"x01",include:[{dbname:'battgen2'}]})
        props.insertDataExport(dataBuilderExport(response.data))
        props.fetchStatus(true)
      }
      catch(e){alert(e)}
    }
  
    const setExportData=()=>{
      const filter = props.exportingData.map((item,index)=>{
        let finalData ={}
        tableSpec.forEach(element=>{
          if(element.type==='data'){
            const arr = Utils.DataConstructor(item, element.obj,index)
            Object.assign(finalData, {
                [element.value]: arr
            })
          }
        })
        return finalData
        
      })
      setDataExportBattery(filter)
    } 

    const dataBuilder =(data,dataType,limit)=>{    
        return data.data.map((item,index)=>{
          return{...item,page:data.page,remaincap:item.battgen2.battgen2log[0],dataType,lastSeen:DayJs(item.battgen2.lastSeen).format('HH:mm, DD MMMM YYYY'),index:((data.page-1)*limit)+index+1}})
    }
    
    const dataBuilderExport = (data,dataType)=>{
      return data.data.map((item)=>{return{...item,page:data.page,dataType,lastSeen:DayJs(item.battgen2.lastSeen).format('HH:mm, DD MMMM YYYY'),bmsId:`'${item.bmsId}'`}})
    }

    return(
        <div>
          <div style={{flexDirection:'row',display:'flex', marginTop:'55px'}}>
          <ExportReactCSV csvData={dataExportBattery} fileName='BatteryTable.csv' />
            <div style={{flex:1}}>  
              <SearchingField  props={props} canSearch={canSearch} fetchSearch={fetchSearch} cancelSearch={cancelSearch} searchMode={searchMode}/>
            </div>
          </div>  
            <div style={{flexDirection:'row',display:'flex',marginTop:'15px', alignItems:'center'}}>
                {searchMode?<Utils.Table dataDisplayed={dataBatteryDisplayedSearch} tableSpec={tableSpec} />:<Utils.Table dataDisplayed={dataBattery} tableSpec={tableSpec} />}
            </div>
            {!loading&&!searchMode&&<Utils.Pagination totalPages={props.totalPagesBattery}  props={props} handleChangePageWithNumber={handleChangePageWithNumberBattery} />}
            {!loading&&searchMode&&<Utils.Pagination totalPages={props.totalPagesBattery}  props={props} handleChangePageWithNumber={handleChangePageWithNumberBattery} />}
            <AddCommandModal props={props} isOpen={openModalCommand} setOpen={setOpenModalCommand} setDataCommand={setCommand} dataCommand={command}/>
        </div>
    )
}

const mapStateToProps = state=>{
    return{
      progress:state.ProgressBar.progress,
      battery:state.Batteries.data,
      currentPageDataBattery:state.Batteries.currentPage,
      limitBattery:state.Batteries.limit,
      totalPagesBattery:state.Batteries.totalPages,
      lastCurrentPageBattery:state.Batteries.lastCurrentPage,
      lastTotalPagesBattery:state.Batteries.lastTotalPages,
      search:state.SearchUser.search,
      exportingData:state.Batteries.export,
      status:state.Batteries.mode,
    }
  }
  
  const mapDispatchToProps = dispatch=>{
    return{
      changeProgress:(value)=>{dispatch(changeProgress(value))},
      showBar:(value)=>{dispatch(showBar(value))},
      insertDataBattery:(value)=>{dispatch(insertDataBattery(value))},
      insertDataFlushBattery:(value,type)=>{dispatch(insertDataFlushBattery(value))},
      changePageBattery:(value)=>{dispatch(changePageBattery(value))},
      totalPage:(value)=>{dispatch(totalPageBattery(value))},
      setlastCurrentPageBattery:(value)=>{dispatch(lastCurrentPageBattery(value))},
      setlastTotalPagesBattery:(value)=>{dispatch(lastTotalPageBattery(value))},
      searchData:(value)=>{dispatch(search(value))},
      insertDataExport:(value)=>{dispatch(insertDataExport(value))},
      fetchStatus:(value)=>{dispatch(fetchStatus(value))},
    }
  }

export default  withRouter(connect(mapStateToProps,mapDispatchToProps)(MainBattery));