const defaultState = {
    data: [],
    page: 0,
    totalPages: 0,
    limit: 20,
    lastPage: 0,
    lastCurrentPage: 0,
    lastTotalPages: 0,
    export:[],
    mode:false,
  };
  
  export default (state = defaultState, action) => {
    switch (action.type) {
      case "INSERT_DATA_STOCK_BATTERY":
        return { ...state, data: [...state.data, ...action.payload] };
      case "INSERT_PAGE_STOCK_BATTERY":
        return { ...state, page: action.payload };
      case "INSERT_TOTAL_PAGE_STOCK_BATTERY":
        return { ...state, totalPages: action.payload };
      case "INSERT_LAST_PAGE_STOCK_BATTERY":
        return { ...state, lastPage: action.payload };
      case "LAST_TOTAL_PAGES_STOCK_BATTERY": {
        return { ...state, lastTotalPages: action.payload };
      }
      case "INSERT_DATA_FLUSH_STOCK_BATTERY": {
        return { ...state, data: action.payload };
      }
      case "LAST_CURRENT_PAGE_STOCK_BATTERY": {
        return { ...state, lastCurrentPage: action.payload };
      }
      case 'INSERT_DATA_EXPORT_STOCK_BATTERY':{
        return{...state, export:[...state.export,...action.payload]}
      }
      case 'FETCH_STATUS_STOCK_BATTERY' : {
        return {...state, mode:action.payload}
      }  
      default:
        return state;
    }
  };
  