export const insertVehicleDealer = (payload) => {
  return {
    payload,
    type:'insertDataVehicleDealer'
  } 
}

export const changePageVehicle = payload=>{
  return {type:'changePageVehicle',payload}
}

export const lastTotalPagesVehicle=payload=>{
  return {type:'lastTotalPagesVehicle',payload}
}

export const lastCurrentPageVehicle=payload=>{
  return {type:'lastCurrentPageVehicle',payload}
}

export const totalPageVehicle = payload =>{
  return {type:'totalPageVehicle',payload}
}

export const insertDataExport = (payload)=> {
  return{type:'INSERT_DATA_EXPORT_VEHICLE_DEALER', payload}
}

export const fetchStatusVehicle = payload =>{
  return {
    type:'FETCH_STATUS_VEHICLE_DEALER',
    payload
  }
}

export const insertDataFlush = payload => {
  return { 
    type: "INSERT_DATA_FLUSH_VEHICLE_DEALER", 
    payload 
  }
};

export const insertLastPage = payload => {
  return {
    type: "INSERT_LAST_PAGE_VEHICLE_DEALER",
    payload
  };
};