import Axios from "axios";
import {startLoading,finishLoading} from '../utils/progressBar'

export default async (props, method, url,needToken,body,headers,dbname) => {
    const tokenjwt = needToken?localStorage.getItem('token'):null
    await startLoading(props);
    try {
        const response = await Axios({
        method,
        url: `${process.env.REACT_APP_BASEURL}${url}`,
        headers:headers?{token:needToken?tokenjwt:null,...headers}:{token:needToken?tokenjwt:null},
        data: body?{...body}:null,
        validateStatus: false
        }).catch(e => {throw new Error(e);});
        if (response.status !== 200) throw {message:response.data.message};
        await finishLoading(props);
        return response
    } catch (error) {
        await finishLoading(props);
        throw error
    }
};
