import React from 'react'
import { CSVLink } from 'react-csv'


export const ExportReactCSV = ({csvData, fileName})=>{
    return (
        <button type="button" className='addUserBtn'>
            <CSVLink data={csvData} filename={fileName} enclosingCharacter={`'`} asyncOnClick={true}>Export</CSVLink>
        </button>
    )
}