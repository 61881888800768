export const insertBatteryDealer = (payload) => {
  return {
    payload,
    type:'insertDataBatteryDealer'
  } 
}

export const changePageBattery = payload=>{
  return {type:'changePageBattery',payload}
}

export const lastTotalPagesBattery=payload=>{
  return {type:'lastTotalPagesBattery',payload}
}

export const lastCurrentPageBattery=payload=>{
  return {type:'lastCurrentPageBattery',payload}
}

export const totalPageBattery = payload =>{
  return {type:'totalPageBattery',payload}
}

export const insertDataExport = (payload)=> {
  return{type:'INSERT_DATA_EXPORT_BATTERY_DEALER', payload}
}

export const fetchStatusBattery = payload =>{
  return {
    type:'FETCH_STATUS_BATTERY_DEALER',
    payload
  }
}

export const insertDataFlush = payload => {
  return { 
    type: "INSERT_DATA_FLUSH_BATTERY_DEALER", 
    payload 
  }
};

export const insertLastPage = payload => {
  return {
    type: "INSERT_LAST_PAGE_BATTERY_DEALER",
    payload
  };
};