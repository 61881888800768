import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {showBar, changeProgress} from '../../action/progressBar'
import Utils from '../../utils'
import { makeStyles } from '@material-ui/core/styles'
import {Button, Typography, Card, CardContent } from '@material-ui/core'
import {connect} from 'react-redux'
import dayjs from 'dayjs'
import GoogleMapGps from './component/googleMap'
import DateGps from './component/dateGps'
import HourGps from './component/hourGps'

function BatteryGpsMap(props) {
  const classes = useStyles();
  const data = { ...props.location.state };
  const [loading,setLoading] = useState(false)
  const [dateGPS,setDateGPS] = useState({batteryId:data.id,  endDate:dayjs().format("YYYY-MM-DD"), startDate:dayjs().format("YYYY-MM-DD"), endHour:'23', startHour:'0'}) 
  const [googleMapCenter, setGoogleMapCenter] = useState({center: {lat:-6.121435, lng:106.774124},zoom: 6}) 
  const [googleMap, setGoogleMap] = useState([]) 

  useEffect(()=>{setDateGPS({...dateGPS,batteryId:data.id})},[])

  const handleSubmitDate = async () => {
    setLoading(true)
    try {
      const response = await Utils.Axios(props,'POST','/cs/battery/searchBatteryGPS',true,{...dateGPS}).catch(e=>{throw e})
      if(!response.data.data[0])return alert ('tidak ada riwayat perjalanan') 
      setGoogleMap(dataBuilder(response.data.data))
      const latitude = response.data.data.map(item=>parseFloat(item.latitude))
      const longitude = response.data.data.map(item=>parseFloat(item.longitude))
      setGoogleMapCenter({center:calculateCenter(longitude,latitude),zoom:calculateZoom(longitude,latitude)})
    } catch (error) {
      setLoading(false);
      alert(error.message)
    }
  }
  const dataBuilder =(data)=>{
    return data.map((item)=>{return{lat:parseFloat(item.latitude),lng:parseFloat(item.longitude)}})
}
  
  return (
    <div style={{ marginTop: "60px" }}>
        <div className='dateGpsContainer'>
          <div className='batteryDetailContainer'>
            <Card className={classes.card}>
                <CardContent>
                  <Typography variant="h5" component="h2" style={{marginBottom:'10px'}}>
                    Battery Detail
                  </Typography>
                  {batteryField.map(item=>
                  <Typography key={item.name} variant="body2" component="p" style={{marginBottom:'10px'}}>
                    {item.name} : {data[item.name]}
                  </Typography>
                  )}
                </CardContent>
            </Card>
          </div>
          <div className='dateContainer'>
            <DateGps Date={dateGPS} setDate={setDateGPS}/>
          </div>
          <div className='dateContainer'>  
            <HourGps Hour={dateGPS} setHour={setDateGPS}/>
            <Button style={{margin:'10px 120px 0px 80px'}}variant="contained"color="primary"  onClick={handleSubmitDate}>submit</Button>
          </div>
        </div> 
      <GoogleMapGps mapCenter={googleMapCenter} mapGps={googleMap}/>
    </div>
  );
}

const calculateCenter = (longitude,latitude) => {
  let lat= (Math.max.apply(Math,latitude)+Math.min.apply(Math, latitude))/2
  let lng= (Math.max.apply(Math,longitude)+Math.min.apply(Math, longitude))/2
  return {lat,lng}
}

const calculateZoom =(longitude,latitude)=>{
  let lat=Math.abs(Math.max.apply(Math,latitude)-Math.min.apply(Math, latitude))
  let lng = Math.abs(Math.max.apply(Math,longitude)-Math.min.apply(Math, longitude))
  if (lat>1.5||lng>1.5)return 8
  else if(lat>0.75||lng>0.75) return 10
  else if(lat>0.1||lng>0.1) return 12 
  else return 14 
}

const useStyles = makeStyles({
  card: {
    minWidth: 275,
  }
});

const batteryField=[
  {name:'bmsId'},
  {name:'batteryNo'},
  {name:'status'}
]

const mapStateToProps = state=>{
    return{
      progress:state.ProgressBar.progress,
    }
}

const mapDispatchToProps = dispatch=>{
    return{
      changeProgress:(value)=>{dispatch(changeProgress(value))},
      showBar:(value)=>{dispatch(showBar(value))},
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(BatteryGpsMap));
