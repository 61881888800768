import React from 'react'
import axios from 'axios'
import Table from '@material-ui/core/Table';
import Modal from '@material-ui/core/Modal';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import ReactPaginate from 'react-paginate';
import { Box } from '@material-ui/core';

export default function Rules() {
    const [withdraw, setWithdraw] = useState()
    const [pageCount, setpageCount] = useState()
    const [paket, setPaket] = useState()
    const [isOpen, setIsOpen] = useState(false)
    const [isOpen2, setIsOpen2] = useState(false)
    const [name, setName] = useState()
    const [description, setDescription] = useState()
    const [code, setcode] = useState()
    const [edit, setedit] = useState()
    const [editid, seteditid] = useState()
    const [point, setpoint] = useState()
    const [pointa, setpointa] = useState()
    const [pointb, setpointb] = useState()
    const [custom, setCustom] = useState(false)
    const [category, setcategory] = useState([])
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const formatter = new Intl.NumberFormat('id', {
    style:'currency',
    currency:'IDR',
    maximumFractionDigits: 0
    })

    const getData =() =>{
        axios.get(`https://ecgobikeid.com/newAdmin/appraisal/params/`, {
            headers : {
                'token' : localStorage.getItem('token')
            }})
        .then((res)=>
            {
                
                setWithdraw(res.data.data)
            }
        )
    }
         
    function handleCreatePaket(){
        setIsOpen(!isOpen)
        axios.get(`https://ecgobikeid.com/newAdmin/appraisal/params/create`, {
            headers : {
                'token' : localStorage.getItem('token')
            }})
        .then((res)=>
            {
                setPaket(res.data.category)
            }
        )
    }

    function handleSubmit(){
        setIsOpen(!isOpen)
        axios.post(`https://ecgobikeid.com/newAdmin/appraisal/params/`,{
            name:name,
            description:description,
            code:code,
            category:category,
            point:point,
            point_a:pointa,
            point_b:pointb
        },{
            headers : {
                'token' : localStorage.getItem('token')
            }})
            .then((res)=>(
                getData()
            ))    
    }

    function handleEdit(ad){
        setIsOpen2(true)  
        handleCreatePaket()
        setIsOpen(false)  
        seteditid(ad.id)
        axios.get(`https://ecgobikeid.com/newAdmin/appraisal/params/${ad.id}/edit`,{
            headers : {
                'token' : localStorage.getItem('token')
            }})
            .then((res)=>(
                setedit(res.data.data)
            ))
       
    }

    function handleSubmitEdit(){
        setIsOpen(false)
        axios.put(`https://ecgobikeid.com/newAdmin/appraisal/params/${editid}`,{
            name:name,
            description:description,
            code:code,
            category:category,
            point:point,
            point_a:pointa,
            point_b:pointb
        },{
            headers : {
                'token' : localStorage.getItem('token')
            }})
            .then((res)=>(
                getData()
            ))    
    }

    function handleDeactive(ad){
        axios.post(`https://ecgobikeid.com/newAdmin/appraisal/params/${ad.id}/delete`,{},{
            headers : {
                'token' : localStorage.getItem('token')
            }})
            .then((res)=>(
                getData()
            ))
    }

    useEffect(()=>{
        getData()
    },[])

    useEffect(()=>{
        if(edit){
            setName(edit?.name)
            setDescription(edit?.description)
            setcode(edit?.code)
            setcategory(edit?.category)
            setpoint(edit?.point)
            setpointa(edit?.point_a)
            setpointb(edit?.point_b)
        }
    },[edit])

  return (
    <div style={{padding:'5rem 0 0 0 '}}>
    <Modal
        open={isOpen}
        onClose={()=>setIsOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{overflow:"auto", top:"0"}}
      >
        <Box sx={style} style={{display:'flex', gap:'2rem', flexDirection:'column'}}>
        <div style={{display:'flex', flexDirection:'column'}}>
            <label>Name :</label>
            <input type='text' onChange={(e)=>setName(e.target.value)}/>
        </div>
        <div style={{display:'flex', flexDirection:'column'}}>
            <label>description :</label>
            <input type='text' onChange={(e)=>setDescription(e.target.value)}/>
        </div>
        <div style={{display:'flex', flexDirection:'column'}}>
            <label>code :</label>
            <input type='text' onChange={(e)=>setcode(e.target.value)}/>
        </div>
        <div style={{display:'flex', flexDirection:'column'}}>
            <label>point :</label>
            <input type='text'  defaultValue={edit?.point} onChange={(e)=>setpoint(e.target.value)}/>
        </div>
        <div style={{display:'flex', flexDirection:'column'}}>
            <label>point A :</label>
            <input type='text'  defaultValue={edit?.point_a} onChange={(e)=>setpointa(e.target.value)}/>
        </div>
        <div style={{display:'flex', flexDirection:'column'}}>
            <label>point B :</label>
            <input type='text'  defaultValue={edit?.point_b} onChange={(e)=>setpointb(e.target.value)}/>
        </div>
        <select onChange={(e)=>setcategory(e.target.value)}>
            <option selected disabled>category</option>
            {
                paket?.map((item)=>{
                    return(
                        <option value={item.category}>{item.category}</option>
                    )
                })
            }
            
        </select>
        <button onClick={()=>setCustom(true)}>Custom category</button>
        {
            custom === true && 
            <div style={{display:'flex', flexDirection:'column'}}>
            <label>custom category :</label>
            <input type='text' onChange={(e)=>setcategory(e.target.value)}/>
        </div>
        }
        <button type="button"  className='addUserBtn' onClick={handleSubmit}>Buat Paket</button>
        </Box>
    </Modal>

    <Modal
        open={isOpen2}
        onClose={()=>{setIsOpen2(false);setedit(null)}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{overflow:"auto", top:"0"}}
      >
        <Box sx={style} style={{display:'flex', gap:'2rem', flexDirection:'column'}}>
        <div style={{display:'flex', flexDirection:'column'}}>
            <label>Name :</label>
            <input type='text' defaultValue={edit?.name} onChange={(e)=>setName(e.target.value)}/>
        </div>
        <div style={{display:'flex', flexDirection:'column'}}>
            <label>description :</label>
            <input type='text'  defaultValue={edit?.description} onChange={(e)=>setDescription(e.target.value)}/>
        </div>
        <div style={{display:'flex', flexDirection:'column'}}>
            <label>code :</label>
            <input type='text'  defaultValue={edit?.code} onChange={(e)=>setcode(e.target.value)}/>
        </div>
        <div style={{display:'flex', flexDirection:'column'}}>
            <label>point :</label>
            <input type='text'  defaultValue={edit?.point} onChange={(e)=>setpoint(e.target.value)}/>
        </div>
        <div style={{display:'flex', flexDirection:'column'}}>
            <label>point A :</label>
            <input type='text'  defaultValue={edit?.point_a} onChange={(e)=>setpointa(e.target.value)}/>
        </div>
        <div style={{display:'flex', flexDirection:'column'}}>
            <label>point B :</label>
            <input type='text'  defaultValue={edit?.point_b} onChange={(e)=>setpointb(e.target.value)}/>
        </div>
        <select onChange={(e)=>setcategory(e.target.value)}>
            <option  defaultValue={edit?.category} >{edit?.category}</option>
            {
                paket?.map((item)=>{
                    return(
                        <option value={item.category}>{item.category}</option>
                    )
                })
            }
            
        </select>
        <button onClick={()=>setCustom(true)}>Custom category</button>
        {
            custom === true && 
            <div style={{display:'flex', flexDirection:'column'}}>
            <label>custom category :</label>
            <input type='text' onChange={(e)=>setcategory(e.target.value)}/>
        </div>
        }
        <button type="button"  className='addUserBtn' onClick={handleSubmitEdit}>Edit Paket</button>
        </Box>
    </Modal>
       
       <button type="button"  className='addUserBtn' onClick={handleCreatePaket}>Buat Rules</button>
   <Paper>
       <Table >
       
           <TableRow >
               <TableCell style={{fontWeight:"bold"}} className='tid'>No</TableCell>
               <TableCell style={{fontWeight:"bold"}}>Date</TableCell>
               <TableCell style={{fontWeight:"bold"}}>Code</TableCell>
               <TableCell style={{fontWeight:"bold"}}>Name</TableCell>
               <TableCell style={{fontWeight:"bold"}}>Category</TableCell>
               <TableCell style={{fontWeight:"bold"}}>Point</TableCell>
               <TableCell style={{fontWeight:"bold"}}>Point A</TableCell>
               <TableCell style={{fontWeight:"bold"}}>Point B</TableCell>
               <TableCell style={{fontWeight:"bold"}}>Action</TableCell>
           </TableRow>
       
           {
                
           <TableBody>
               {withdraw?.map((ad,idx)=>{
                   return (
                       <>
                           <TableRow key={ad.id}>
                               <TableCell >{idx+1}</TableCell>
                               <TableCell >{ad.createdAt.slice(0,10)}</TableCell>
                               <TableCell >{ad.code}</TableCell>
                               <TableCell style={{maxWidth:'20rem'}}>{ad.name}</TableCell>
                               <TableCell style={{maxWidth:'25rem'}}>{ad.category}</TableCell>
                               <TableCell >{ad.point}</TableCell>
                               <TableCell >{ad.point_a}</TableCell>
                               <TableCell >{ad.point_b}</TableCell>
                               <TableCell ><button onClick={()=>handleEdit(ad)}>Edit</button>
                                            &nbsp;
                                            <button onClick={()=>handleDeactive(ad)}>Delete</button>
                                </TableCell>
                               
                           </TableRow>
                       </>
                   )
               })}
           </TableBody>
           }

       </Table>
   </Paper>  
   
     <ReactPaginate
       breakLabel="..."
       nextLabel="next >"
       onPageChange=''
       pageRangeDisplayed={5}
       pageCount={pageCount}
       previousLabel="< previous"
       renderOnZeroPageCount={null}
       containerClassName={'pagination'}
       pageClassName={'pageContainer'}
       pageLinkClassName={'changePageButton'}
       activeClassName={'active'}
     />
 </div>
  )
}
