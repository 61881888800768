import React, {useEffect, useState} from 'react'
import {changeProgress,showBar} from '../../action/progressBar'
import {insertData,insertPage,insertLastPage,insertTotalPage,fetchStatus,insertLastCurrentPage, insertDataExport, insertLastTotalPage,insertDataFlush} from '../../action/pendingSubscription'
import Utils from '../../utils'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import dayjs from 'dayjs'
import { ExportReactCSV } from '../../component/export'

function PulsaTable (props) {
    const [dataDisplayed,setDataDisplayed]=useState([])
    const [loading,setLoading]=useState(true)
    const [dataExportPendingSubscription, setDataExportPendingSubscription]= useState([])

    const tableSpec=[
        {Title:'No',obj:['index'],value:'index',shown:true,type:'data'},
        {Title:'id',obj:['id'],value:'pulsaId',shown:false,type:'data'},
        {Title:'Name',obj:['name'],value:'name',shown:true,type:'data'},
        {Title:'Phone Number',obj:['phoneNumber'],value:'phoneNumber',shown:true,type:'data'},
        {Title:'BMS ID',obj:['bmsId'],value:'bmsId',shown:true,type:'data'},
        {Title:'GPS ID',obj:['batteryNo'],value:'batteryNo',shown:true,type:'data'},
        {Title:'Status',obj:['status'],value:'status',shown:true,type:'data'},
        {Title:'Billing For',obj:['billingFor'],value:'billingFor',shown:true,type:'data'},
    ]

    useEffect(()=>{setPage()},[])
    useEffect(()=>{
        setData()
    },[props.currentPage])
    useEffect (()=>{setStatus()},[])
    useEffect (()=>{setExportData()},[props.exportingData])
    
    const setPage=async (page)=>{
        if (typeof page !== "number") {
            if (!props.data[props.limit * parseInt(props.currentPage) - 1]) await fetch(1)
            setLoading(false);
        } 
        else {
            if (props.data.filter(item => item.page == page).length ==0) return await fetch(page)
            props.insertPage(page);
        }
    } 

    const fetch=async (page)=>{
        try {
            const response = await Utils.Axios(props,'GET',`/cs/subscription/pending?page=${page}&limit=${props.limit}`,true).catch(e=>{throw e})
            props.insertData(dataBuilder(response.data,props.limit))
            props.insertPage(response.data.page)
            props.insertTotalPage(response.data.totalPages)
        } catch (error) {
            alert(error.message)
        }
    }


    const setData=()=>{
        if(props.currentPage==0)return
        const filter = props.data.filter(item=>item.page==props.currentPage).map((item,index)=>{
          let finalData ={}
          tableSpec.forEach(element=>{
            if(element.type=='data'){
              const arr = Utils.DataConstructor(item, element.obj,index)
              Object.assign(finalData, {
                  [element.value]: arr
              })
            }
          })
          return finalData 
        })
        setDataDisplayed(filter)
    }

    const setStatus =  async() => {
        if (props.status!=true) await fetchExport()
      }
    
    const fetchExport = async() => {
        try {
          const response = await Utils.Axios(props,'POST', `/system/crud/read`, true, {dbname:"subscription",whereClause:{status:'pending'},
            include:[
                {dbname:'trip',attributes:['userId','batteryId'], 
                include:[
                    { dbname:'user',
                    attributes:['name','phoneNumber']
                    },
                    {
                    dbname:'battgen2',
                    attributes:['batteryNo'],
                    include: [{
                        dbname:'x01',
                        attributes:['bmsId']
                    }]
                    },
                ]}
            ]
            })
            props.insertDataExport(dataBuilderExport(response.data))
            props.fetchStatus(true)
            }
        catch(e){alert(e)}
      }
    
      const setExportData=()=>{
        const filter = props.exportingData.map((item,index)=>{
          let finalData ={}
          tableSpec.forEach(element=>{
            if(element.type==='data'){
              const arr = Utils.DataConstructor(item, element.obj,index)
              Object.assign(finalData, {
                  [element.value]: arr
              })
            }
          })
          return finalData
        })
        setDataExportPendingSubscription(filter)
      } 

    const handleChangePage=(data)=>{setPage(parseInt(data.selected)+1)}


    return (
        <div>
            <div style={{flexDirection:'row',display:'flex',marginTop:'70px'}}>
            <ExportReactCSV csvData={dataExportPendingSubscription} fileName='PendingSubscriptionTable.csv'/>
            </div>
            {<Utils.Table dataDisplayed={dataDisplayed} tableSpec={tableSpec} />}
            {!loading&&<Utils.Pagination totalPages={props.totalPages}  props={props} handleChangePageWithNumber={handleChangePage} />}
        </div>
    )
}

const dataBuilder=(data,limit)=> data.data.map((item,index)=>{return {...item,page:data.page,index:((data.page-1)*limit)+index+1, billingFor:dayjs(item.billingFor).add(7,'h').format("MMMM YYYY")}})

const dataBuilderExport=(data,limit)=> data.data.map((item,index)=>
{ return {...item,
            page:data.page,
            index:((data.page-1)*limit)+index+1, 
            billingFor:dayjs(item.billingFor).add(7,'h').format("MMMM YYYY"),
            name:`${item.trip.user.name}`,
            phoneNumber:`${item.trip.user.phoneNumber}`,
            bmsId:`${item.trip.battgen2.x01.bmsId}`,
            batteryNo:`${item.trip.battgen2.batteryNo}`
        }})

const mapStateToProps=(state)=>{
    return{
        progress:state.ProgressBar.progress,
        data:state.PendingSubscription.data,
        currentPage:state.PendingSubscription.page,
        totalPages:state.PendingSubscription.totalPages,
        lastPage:state.PendingSubscription.lastPage,
        limit:state.PendingSubscription.limit,
        lastCurrentPage:state.PendingSubscription.lastCurrentPage,
        lastTotalPages:state.PendingSubscription.lastTotalPages,
        exportingData:state.PendingSubscription.export,
        status:state.PendingSubscription.mode,
    }
}

const mapDispatchToProps=(dispatch)=>{
    return{
        changeProgress:(load)=>{dispatch(changeProgress(load))},
        showBar:(bool)=>{dispatch(showBar(bool))},
        insertData:(data)=>{dispatch(insertData(data))},
        insertPage:(page)=>{dispatch(insertPage(page))},
        insertLastPage:(page)=>{dispatch(insertLastPage(page))},
        insertTotalPage:(totalPage)=>{dispatch(insertTotalPage(totalPage))},
        insertDataFlush:(value,type)=>{dispatch(insertDataFlush(value,type))},
        setLastCurrentPagePulsa:(value)=>{dispatch(insertLastCurrentPage(value))},
        setLastTotalPagesPulsa:(value)=>{dispatch(insertLastTotalPage(value))},
        insertDataExport:(value)=>{dispatch(insertDataExport(value))},
        fetchStatus:(value)=>{dispatch(fetchStatus(value))},
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PulsaTable))