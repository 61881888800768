import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, withRouter, Route,Redirect,HashRouter } from "react-router-dom";
import { Container } from "@material-ui/core";
import Navbar from "./component/navbar";
import { Provider } from "react-redux";
import { CSSTransition } from "react-transition-group";
import Login from "./screen/login";
import TableUserScreen from "./screen/user/main"
import MainBattery from './screen/battery'
import ProgressBar from "./component/progress";
import BatteryDealerTable from './screen/dealer/dealer'
import BatteryGpsMap from './screen/gpsbatterymap/batteryGps'
import RentalMain from './screen/rental/main'
import RentalDetail from './screen/rental/detail'
import CommandTable from './screen/command'
import PulsaTable from './screen/pulsa'
import PendingSubscription from './screen/pendingSubscription'
import VehicleTable from './screen/vehicle'
import PaymentTable from './screen/payment'
import DetailBattery from './screen/detailbattery'
import PendingCustomer from './screen/pendingcustomer'
import DetailCustomer from './screen/pendingcustomer/detail'
import GoogleMapsMarker from './screen/detailbattery/component/googleMaps'
import lowbattery from './screen/battery/component/table/lowbattery'
import StockBattery from './screen/battery/component/table/stockbattery'
import ServicePoint from './screen/servicePoint/ServicePoint'
import DendaTable from './screen/subscriptionFine'
import { store as reduxStore, persistor } from "./configureStore";
import { PersistGate } from "redux-persist/integration/react";
import "./component/css/routerTransition.css";
import AdminService from "./screen/servicePoint/AdminService";
import Ads from "./screen/promo/Ads";
import Voucher from "./screen/promo/Voucher";
import Promo from "./screen/promo/Promo";
import Event from "./screen/dealer/Event";
import Dealer2 from'./screen/dealer/Dealer2';
import Tradein from "./screen/tradein/Tradein";
import CreateProposal from "./screen/tradein/CreateProposal";
import Order from "./screen/orders/Order";
import Pyramid from "./screen/pyramid/Pyramid";
import Withdraw from "./screen/pyramid/Withdraw";
import Comment from "./screen/thankyou/Comment";
import Leads from "./screen/evmotoLeads/Leads";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Paket from "./screen/evmotoLeads/Paket";
import Rules from "./screen/evmotoLeads/Rules";
import Abnormal from "./screen/battery/Abnormal";
import Swapstation from "./screen/servicePoint/Swapstation";
import Bike from "./screen/rentbike/Bike";
import Dailypayment from "./screen/rentbike/Dailypayment";
import Extrapayment from "./screen/rentbike/Extrapayment";
import Extrabatt from "./screen/rentbike/Extrabatt";
import Affiliates from "./screen/Affiliates/Affiliates";


const Main = withRouter(({ location }) => {
  const routes = [
    { path: "/user", Component: TableUserScreen, needToken:true },
    { path: "/pendingcustomer", Component: PendingCustomer, needToken:true },
    { path: "/pendingcustomerdetail", Component: DetailCustomer, needToken:true },
    { path:'/battery/main', Component: MainBattery, needToken:true },
    { path:'/lowbattery', Component: lowbattery, needToken:true },
    { path:'/stockbattery', Component: StockBattery, needToken:true },
    { path:'/command', Component: CommandTable, needToken:true },
    { path: "/dealer", Component: Dealer2, needToken:true },
    { path:'/pulsa',Component:PulsaTable, needToken:true},
    { path:'/subscription/pending',Component:PendingSubscription, needToken:true},
    { path:'/login', Component: Login, needToken:false },
    { path:'/battery/map/:id',Component:BatteryGpsMap, needToken:true},
    { path:'/rental',Component:RentalMain, needToken:true},
    { path:'/rentaldetail/:id',Component:RentalDetail, needToken:true},
    { path:'/vehicle',Component:VehicleTable, needToken:true},
    { path:'/payment',Component:PaymentTable, needToken:true},
    { path:'/denda',Component:DendaTable, needToken:true},
    { path:'/servicepoint',Component:ServicePoint, needToken:true},
    { path:'/adminservicepoint',Component:AdminService, needToken:true},
    { path:'/battery/detail/:id',Component:DetailBattery, needToken:true},
    { path:'/promo/voucher',Component:Voucher, needToken:true},
    { path:'/promo/ads',Component:Ads, needToken:true},
    { path:'/promo/discount',Component:Promo, needToken:true},
    { path:'/event',Component:Event, needToken:true},
    { path:'/tradein',Component:Tradein, needToken:true},
    { path:'/order',Component:Order, needToken:true},
    { path:'/create-proposal',Component:CreateProposal, needToken:true},
    { path:'/pyramid',Component:Pyramid, needToken:true},
    { path:'/withdraw',Component:Withdraw, needToken:true},
    { path:'/Comments',Component:Comment, needToken:true},
    { path:'/leads',Component:Leads, needToken:true},
    { path:'/paket',Component:Paket, needToken:true},
    { path:'/rules',Component:Rules, needToken:true},
    { path:'/abnormalbatteries',Component:Abnormal, needToken:true},
    { path:'/swapstation',Component:Swapstation, needToken:true},
    { path:'/rentbike',Component:Bike, needToken:true},
    { path:'/dailypayment',Component:Dailypayment, needToken:true},
    { path:'/extrapayment',Component:Extrapayment, needToken:true},
    { path:'/extrabatt',Component:Extrabatt, needToken:true},
    { path:'/affiliates',Component:Affiliates, needToken:true},
  ];

  return (
    <div>
      <ProgressBar />
      <Navbar pathName={location.pathname}>
        <Container className="container">
          {routes.map(({ path, Component,needToken }) => (
            <Route key={path} path={path}>
              {({ match }) => (
                <CSSTransition
                  in={match != null}
                  timeout={300}
                  classNames="page"
                  unmountOnExit
                >
                  <div className="page">
                    {needToken?
                    localStorage.token?<Component/>:<Redirect to={'/login'}/>
                    :<Component />}
                  </div>
                </CSSTransition>
              )}
            </Route>
          ))}
        </Container>
      </Navbar>
    </div>
  );
});

const queryClient =  new QueryClient()
ReactDOM.render(
  
  <Provider store={reduxStore}>
    <QueryClientProvider client={queryClient}>
    <PersistGate loading={null} persistor={persistor}>
    
      <HashRouter>
      
        <Main />
        
      </HashRouter>
     
    </PersistGate>
    </QueryClientProvider>
  </Provider>
  ,

  document.getElementById("root")
);

serviceWorker.unregister();
