import React, {useEffect, useState} from 'react'
import {changeProgress,showBar} from '../../action/progressBar'
import {insertData,insertPage,insertLastPage,insertTotalPage,fetchStatus,insertLastCurrentPage, insertDataExport, insertLastTotalPage,insertDataFlush} from '../../action/pulsaTable'
import Utils from '../../utils'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import SearchingField from '../../component/sortingfield'
import Sorting from '../../component/sorting'
import dayjs from 'dayjs'
import { ExportReactCSV } from '../../component/export'
import bmsIdColumn from '../../component/bmsId'
import {TableCell} from '@material-ui/core'

function PulsaTable (props) {
    const [dataDisplayed,setDataDisplayed]=useState([])
    const [loading,setLoading]=useState(true)
    const [canSearch,setCanSearch]=useState(true)
    const [dataPulsaDisplayedSearch,setdataPulsaDisplayedSearch]=useState([])
    const [searchMode,setSearchMode]=useState(false)
    const [sort, setSort] = useState({whereClause:'',searchValue:''})
    const [dataExportPulsa, setDataExportPulsa]= useState([])


    const tableSpec=[
        {Title:'No',obj:['index'],value:'index',shown:true,type:'data'},
        {Title:'id',obj:['id'],value:'pulsaId',shown:false   ,type:'data'},
        {Title:'Battery Id',obj:['batteryId'],value:'id',shown:false,type:'data'},
        {Title:'Data Type',obj:['dataType'],value:'dataType',shown:false,type:'data'},
        {Title:'Name',obj:['user','name'],value:'name',shown:true,type:'data'},
        {Title:'Phone Number',obj:['user','phoneNumber'],value:'phoneNumber',shown:true,type:'data'},
        {Title:'BMS ID',obj:['battgen2','x01','bmsId'],value:'BMSId',shown:false,type:'data'},
        {Title:'BMS ID',shown:true,type:'action',component:bmsIdColumn, data:props},
        {Title:'Credit(Ah)',obj:['credit'],value:'credit',shown:true,type:'data'},
        {Title:'Status',obj:['status'],value:'status',shown:true,type:'data'},
        {Title:'Date',obj:['createdAt'],value:'customerType',shown:true,type:'data'},
    ]

    useEffect(()=>{setPage()},[])
    useEffect(()=>{setData()},[props.currentPage,searchMode])
    useEffect (()=>{setStatus()},[])
    useEffect (()=>{setExportData()},[props.exportingData])
    
    const setPage=async (page)=>{
        if(!searchMode){
            if (typeof page !== "number") {
              if (!props.data[props.limit * parseInt(props.currentPage) - 1]) await fetch(1)
              setLoading(false);
            } 
            else {
              if (props.data.filter(item => item.page === page).length ===0) return await fetch(page)
              props.insertPage(page);
            }
          }
    } 

    const fetch=async (page)=>{
        try {
            const response = await Utils.Axios(props,'GET',`/cs/pulsa/all?page=${page}&limit=${props.limit}&order=DESC`,true).catch(e=>{throw e})
            props.insertData(dataBuilder(response.data,props.limit))
            props.insertPage(response.data.page)
            props.insertTotalPage(response.data.totalPages)
        } catch (error) {
            alert(error.message)
        }
    }

    const fetchSearch = async()=>{
        setCanSearch(false)
        try {
          const temp = props.data.filter(item=>item.dataType!=='search')
          props.insertDataFlush(temp)
          const response = await Utils.Axios(props, 'POST',`/cs/pulsa/searchbyuser`,true,{...sort}).catch(e=>{throw e})
          if(!searchMode)props.setLastCurrentPagePulsa(props.currentPage)
          if(!searchMode)props.setLastTotalPagesPulsa(props.totalPages)
          props.insertData(dataBuilderSearch(response.data,'search'))
          props.insertPage(response.data.page)
          props.insertTotalPage(response.data.totalPages)
          setSearchMode(true)  
        }
        catch(e){alert(e)}
        finally{setCanSearch(true)}
    }

    const setData=()=>{
        if(props.currentPage===0)return
        const filter = props.data.filter(item=>searchMode?item.page===props.currentPage&&item.dataType==='search':item.page===props.currentPage).map((item,index)=>{
          let finalData ={}
          tableSpec.forEach(element=>{
            if(element.type==='data'){
              const arr = Utils.DataConstructor(item, element.obj,index)
              Object.assign(finalData, {
                  [element.value]: arr
              })
            }
          })
          return finalData 
        })
        searchMode?setdataPulsaDisplayedSearch(filter):setDataDisplayed(filter)
    }

    const cancelSearch=(e)=>{
        e.preventDefault()
        setDataDisplayed([])
        const temp = props.data.filter(item=>item.dataType!=='search')
        props.insertDataFlush(temp)
        props.insertTotalPage(props.lastTotalPages)
        props.insertPage(1)
        setSearchMode(false)
    }

    const handleChangePage=(data)=>{setPage(parseInt(data.selected)+1)}

    const setStatus =  async() => {
      if (props.status!==true) await fetchExport()
    }

    const fetchExport = async() => {
        try {
          const response = await Utils.Axios(props,'POST', `/system/crud/read`, true, {dbname:"pulsagen2",include:[{dbname:'user'}, {dbname:'battgen2',include:[{dbname:'x01'}]}]})
          props.insertDataExport(dataBuilderExport(response.data))
          props.fetchStatus(true)
        }
        catch(e){alert(e)}
      }
    
      const setExportData=()=>{
        const filter = props.exportingData.map((item,index)=>{
          let finalData ={}
          tableSpec.forEach(element=>{
            if(element.type==='data'){
              const arr = Utils.DataConstructor(item, element.obj,index)
              Object.assign(finalData, {
                  [element.value]: arr
              })
            }
          })
          return finalData 
        })
        setDataExportPulsa(filter)
      }

    return (
        <div>
            <div style={{flexDirection:'row',display:'flex', marginTop:'55px'}}>
                <ExportReactCSV csvData={dataExportPulsa} fileName='PulsaTable.csv' />
                <Sorting sort={sort} setSort={setSort} sortBy={sortingItem}/>
                <div style={{flex:1}}>  
                <SearchingField  props={props} canSearch={canSearch} fetchSearch={fetchSearch} setSort={setSort} sort={sort}cancelSearch={cancelSearch} searchMode={searchMode}/>
                </div>
            </div>
            <div style={{flexDirection:'row',display:'flex',marginTop:'15px', alignItems:'center'}}>
            {searchMode?<Utils.Table dataDisplayed={dataPulsaDisplayedSearch} tableSpec={tableSpec} />:<Utils.Table dataDisplayed={dataDisplayed} tableSpec={tableSpec} />}
            </div>
            {!loading&&!searchMode&&<Utils.Pagination totalPages={props.totalPages}  props={props} handleChangePageWithNumber={handleChangePage} />}
        </div>
    )
}

const sortingItem = [
    {sortBy:'Name'},
    {sortBy:'phoneNumber'}
  ]

const dataBuilder=(data,limit)=> data.data.map((item,index)=>{return {...item,page:data.page,index:((data.page-1)*limit)+index+1, createdAt:dayjs(item.createdAt).format("DD-MM-YYYY")}})
const dataBuilderSearch=(data,dataType)=> data.data.map((item,index)=>{return {...item,page:data.page,dataType,index:index+1,createdAt:dayjs(item.createdAt).format("DD-MM-YYYY")}})
const dataBuilderExport = (data,dataType)=>{
    return data.data.map((item,index)=>{return{...item,page:data.page,dataType,index:index+1,createdAt:dayjs(item.createdAt).format("DD-MM-YYYY"),bmsId:item.battgen2?`${item.battgen2.x01.bmsId}'`:'-'}})
  }

const mapStateToProps=(state)=>{
    return{
        progress:state.ProgressBar.progress,
        data:state.Pulsa.data,
        currentPage:state.Pulsa.page,
        totalPages:state.Pulsa.totalPages,
        lastPage:state.Pulsa.lastPage,
        limit:state.Pulsa.limit,
        lastCurrentPage:state.Pulsa.lastCurrentPage,
        lastTotalPages:state.Pulsa.lastTotalPages,
        exportingData:state.Pulsa.export,
        status:state.Pulsa.mode,
    }
}

const mapDispatchToProps=(dispatch)=>{
    return{
        changeProgress:(load)=>{dispatch(changeProgress(load))},
        showBar:(bool)=>{dispatch(showBar(bool))},
        insertData:(data)=>{dispatch(insertData(data))},
        insertPage:(page)=>{dispatch(insertPage(page))},
        insertLastPage:(page)=>{dispatch(insertLastPage(page))},
        insertTotalPage:(totalPage)=>{dispatch(insertTotalPage(totalPage))},
        insertDataFlush:(value,type)=>{dispatch(insertDataFlush(value,type))},
        setLastCurrentPagePulsa:(value)=>{dispatch(insertLastCurrentPage(value))},
        setLastTotalPagesPulsa:(value)=>{dispatch(insertLastTotalPage(value))},
        insertDataExport:(value)=>{dispatch(insertDataExport(value))},
        fetchStatus:(value)=>{dispatch(fetchStatus(value))},
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PulsaTable))