const defaultState = {
  customerTypes:[]
}

export default (state=defaultState, action) => {
  switch (action.type){
    case 'addCustomerType' : {
      return {...state,customerTypes: action.payload }
    }
    default: return state
  }
}