import React, {useState,useEffect} from 'react'
import {changeProgress,showBar} from '../../action/progressBar'
import {insertData, insertPage, insertLastPage, fetchStatus, insertTotalPage, insertDataExport,insertDataFlush,insertLastCurrentPage, insertLastTotalPage } from '../../action/commandTable'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import { Delete } from '@material-ui/icons';
import { TableCell } from '@material-ui/core'
import Utils from '../../utils'
import dayjs from 'dayjs'
import '../../css/pageList.css'
import ModalDeleteCommand from './component/modalDeleteCommand'
import { ExportReactCSV } from '../../component/export'
import SearchingField from '../../component/search'
import {search} from '../../action/userTable/search'

function CommandTable(props) {
    const [dataDisplayed,setDataDisplayed]=useState([])
    const [dataDisplayedSearch,setdataDisplayedSearch]=useState([])
    const [loading,setLoading]=useState(true)
    const [openModalDeleteCommand,setOpenModalDeleteCommand]=useState(false)
    const [idCommandDeleted, setIdCommandDeleted]=useState({id:''})
    const [dataExportCommand, setDataExportCommand]= useState([])
    const [searchMode,setSearchMode]=useState(false)
    const [canSearch,setCanSearch]=useState(true)

    useEffect(()=>{setPage()},[])
    useEffect(()=>{setDisplayedData()},[props.currentPage,searchMode])
    useEffect (()=>{setStatus()},[])
    useEffect (()=>{setExportData()},[props.exportingData])
    
    const tableSpec=[
        {Title:'No',obj:['index'],value:'index',shown:true,type:'data'},
        {Title:'id',obj:['id'],value:'id',shown:false,type:'data'},
        {Title:'Command',obj:['command'],value:'command',shown:true,type:'data'},
        {Title:'GPS ID',obj:['batteryNo'],value:'gpsid',shown:true,type:'data'},
        {Title:'Status',obj:['status'],value:'status',shown:true,type:'data'},
        {Title:'Date',obj:['createdAt'],value:'date',shown:true,type:'data'},
        {shown:true,type:'action',component:deleteButton, setOpenModal:setOpenModalDeleteCommand, setDeleteCommand:setIdCommandDeleted,  },
    ]

    const setPage = async page => {
        if(!searchMode){
          if (typeof page !== "number") {
            if (!props.data[props.limit * parseInt(props.currentPage) - 1]) await fetch(1)
            setLoading(false);
          } 
          else {
            if (props.data.filter(item => item.page == page).length ==0) return await fetch(page)
            props.insertPage(page);
          }
        }
        else{
          if (props.data.filter(item => item.page === page &&item.dataType==='search').length < props.limit) return await fetchOtherSearch(page)
          props.insertPage(page);
        }
    };

    const fetch=async (page)=>{
        try {
            const response = await Utils.Axios(props,'GET',`/cs/command/all?page=${page}&limit=${props.limit}&order=DESC`,true).catch(e=>{throw e})
            props.insertData(dataBuilder(response.data,props.limit))
            props.insertPage(response.data.page)
            props.insertTotalPage(response.data.totalPages)
        } catch (error) {
            alert(error.message)
        }
    }

    const fetchSearch = async(page)=>{
        setCanSearch(false)
        try {
          const temp = props.data.filter(item=>item.dataType!=='search')
          props.insertDataFlush(temp)
          const response = await Utils.Axios(props, 'GET',`/cs/command/search?search=${props.search}&page=${page?page:1}&limit=20`,true).catch(e=>{throw e})
          if(!searchMode)props.setlastCurrentPage(props.currentPage)
          if(!searchMode)props.setlastTotalPages(props.totalPages)
          props.insertData(dataBuilder(response.data,'search'))
          props.insertPage(response.data.page)
          props.insertTotalPage(response.data.totalPages)
          setSearchMode(true)
        }
        catch(e){alert(e)}
        finally{setCanSearch(true)}
      }
    
    const fetchOtherSearch = async(page)=>{
        setCanSearch(false)
        try {
          const response = await Utils.Axios(props, 'GET',`/cs/command/search?search=${props.search}&page=${page?page:1}&limit=20`,true).catch(e=>{throw e})
          props.insertData(dataBuilder(response.data,'search'))
          props.insertPage(response.data.page)
          props.insertTotalPage(response.data.totalPages)
        }
        catch(e){alert(e.message)}
        finally{setCanSearch(true)}
      }

    const setDisplayedData=()=>{
        if(props.currentPage===0)return
        const filter = props.data.filter(item=>searchMode?item.page==props.currentPage&&item.dataType==='search':item.page==props.currentPage).map((item,index)=>{
        let finalData ={}
        tableSpec.forEach(element=>{
            if(element.type==='data'){
            const arr = Utils.DataConstructor(item, element.obj,index)
            Object.assign(finalData, {
                [element.value]: arr
            })
            }
        })
        return finalData
        })
        searchMode?setdataDisplayedSearch(filter):setDataDisplayed(filter)
    }

    const cancelSearch=(e)=>{
        e.preventDefault()
        setDataDisplayed([])
        const temp = props.data.filter(item=>item.dataType!=='search')
        props.insertData(temp)
        props.insertTotalPage(props.lastTotalPages)
        props.insertPage(1)
        setSearchMode(false)
      }
    
    const setStatus =  async() => {
    if (props.status!=true) await fetchExport()
    }

    const fetchExport = async() => {
        try {
          const response = await Utils.Axios(props,'POST', `/system/crud/read`, true, {dbname:"failedcommand"})
          props.insertDataExport(dataBuilder(response.data))
          props.fetchStatus(true)
        }
        catch(e){alert(e)}
      }
    
      const setExportData=()=>{
        const filter = props.exportingData.map((item,index)=>{
          let finalData ={}
          tableSpec.forEach(element=>{
            if(element.type==='data'){
              const arr = Utils.DataConstructor(item, element.obj,index)
              Object.assign(finalData, {
                  [element.value]: arr
              })
            }
          })
          return finalData
        })
        setDataExportCommand(filter)
      }

    const handleChangePageWithNumber=(data)=>{setPage(parseInt(data.selected)+1)}

    const dataBuilder=(data,dataType)=>{
        return data.data.map((item,index)=>{return{...item,page:data.page,dataType,createdAt:dayjs(item.createdAt).format("DD-MM-YYYY"),index:((data.page-1)*props.limit)+index+1}})
    }
    

    return (
        <div>
            <div style={{flexDirection:'row',display:'flex', marginTop:'55px'}}>
                <ExportReactCSV csvData={dataExportCommand} fileName='CommandTable.csv' style={{marginRight:'5px'}}/>
                <div style={{flex:1}}>  
                <SearchingField props={props} canSearch={canSearch} fetchSearch={fetchSearch} cancelSearch={cancelSearch} searchMode={searchMode}/>
                </div>
            </div>
        {searchMode?<Utils.Table dataDisplayed={dataDisplayedSearch} tableSpec={tableSpec} />:<Utils.Table dataDisplayed={dataDisplayed} tableSpec={tableSpec} />}
        {!loading&&!searchMode&&<Utils.Pagination props={props} handleChangePageWithNumber={handleChangePageWithNumber} />}
        {!loading&&searchMode&&<Utils.Pagination props={props} handleChangePageWithNumber={handleChangePageWithNumber} />}
        <ModalDeleteCommand isOpen={openModalDeleteCommand} props={props} setOpen={setOpenModalDeleteCommand} id={idCommandDeleted}/>
        </div>
    )
}

const deleteButton = ({row,item}) =>{
    const handlPressedeDelete=()=>{
        item.setDeleteCommand(row)
        item.setOpenModal(true)
      }
      return (
      <TableCell  align='center'>
        <div className='tooltip'>
          <Delete style={{ cursor:'pointer' }} onClick={handlPressedeDelete}/>
          <span className='tooltiptextuser'>Delete</span>
        </div>
      </TableCell>
      )
}

  const mapStateToProps=(state)=>{
    return{
        progress:state.ProgressBar.progress,
        data:state.Command.data,
        currentPage:state.Command.page,
        totalPages:state.Command.totalPages,
        lastPage:state.Command.lastPage,
        limit:state.Command.limit,
        exportingData:state.Command.export,
        search:state.SearchUser.search,
        status:state.Command.mode,
    }
}

const mapDispatchToProps=(dispatch)=>{
    return{
        changeProgress:(load)=>{dispatch(changeProgress(load))},
        showBar:(bool)=>{dispatch(showBar(bool))},
        insertData:(data)=>{dispatch(insertData(data))},
        insertPage:(page)=>{dispatch(insertPage(page))},
        insertLastPage:(page)=>{dispatch(insertLastPage(page))},
        insertTotalPage:(totalPage)=>{dispatch(insertTotalPage(totalPage))},
        insertDataExport:(value)=>{dispatch(insertDataExport(value))},
        insertDataFlush:(value,type)=>{dispatch(insertDataFlush(value))},
        setlastCurrentPage:(value)=>{dispatch(insertLastCurrentPage(value))},
        setlastTotalPages:(value)=>{dispatch(insertLastTotalPage(value))},
        searchData:(value)=>{dispatch(search(value))},
        fetchStatus:(value)=>{dispatch(fetchStatus(value))},
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(CommandTable))