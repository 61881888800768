import LoginUser from "./login";
import ProgressBar from './progressBar'
import NavigationLoading from './navigation'
import Users from './userTable/mainUserTable'
import SearchUser from './userTable/search'
import AddUser from './userTable/addUser'
import BatteryDealer from './dealerTable/batteryDealer'
import VehicleDealer from './dealerTable/vehicleDealer'
import Batteries from './batteryTable/mainBatteryTable'
import MainRental from './rentalTable/main'
import DetailRental from './rentalTable/detail'
import Command from './commandTable'
import SearchMode from './userTable/searchMode'
import Pulsa from './pulsaTable'
import PendingSubscription from './pendingSubscription'
import Vehicle from './vehicleTable'
import Payment from './paymentTable'
import Bms from './detailBatteryTable/bmsTable'
import Gps from './detailBatteryTable/gpsTable'
import Xa2 from './detailBatteryTable/xa2Table'
import Recharge from './detailBatteryTable/rechargeTable'
import PendingCustomer from './pendingCustomer'
import LowBattery from './batteryTable/lowBatteryTable'
import StockBattery from './batteryTable/stockBattery'
import Denda from './subscriptionFine'
import {combineReducers} from "redux";

const allReducers = combineReducers({
  LoginUser,
  SearchMode,
  ProgressBar,
  NavigationLoading,
  Users,
  SearchUser,
  AddUser,
  BatteryDealer,
  VehicleDealer,
  Batteries,
  MainRental,
  DetailRental,
  Command,
  Pulsa,
  Vehicle,
  Payment,
  Bms,
  Gps,
  Xa2,
  Recharge,
  PendingCustomer,
  LowBattery,
  StockBattery,
  Denda,
  PendingSubscription
});

export default allReducers;
