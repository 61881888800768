import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios'
import { useLocation } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import Modal from '@material-ui/core/Modal';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import BeatLoader from "react-spinners/BeatLoader";
import ReactPaginate from 'react-paginate';
import { Button } from '@material-ui/core';


export default function Extrapayment() {
    
    const location = useLocation()
    const [orders, setOrders] = useState()
    const [pageCount, setpageCount] = useState()

    function getData(){
        axios.get(`${process.env.REACT_APP_BASEURL}/newAdmin/rentbike/extra_payment?vehicleId=${location.state.userId}`, {
            headers : {
                'token' : localStorage.getItem('token')
            }
        }).then((response)=>
          {
         
         console.log(response.data.vehicle.vehicle_extrapayments.length)
            setOrders(response.data.vehicle)
          }
        ) 
    }

    useEffect(()=>{
        getData()
    },[])

  return (

    <div style={{paddingTop:'5rem'}}>
      {orders?.vehicle_extrapayments?.length === 0 ?  <a>No Extra Payment</a>
      :  
    <Paper>
    <Table style={{paddingTop:'5rem'}}>
    
        <TableRow >
            <TableCell style={{fontWeight:"bold"}} className='tid'>ID</TableCell>
            <TableCell style={{fontWeight:"bold"}}>Tanggal</TableCell>
            <TableCell style={{fontWeight:"bold"}}>Rangka</TableCell>
            <TableCell style={{fontWeight:"bold"}}>Dinamo</TableCell>
            <TableCell style={{fontWeight:"bold"}}>Controller No</TableCell>
            <TableCell style={{fontWeight:"bold"}}>Nopol</TableCell>
            <TableCell style={{fontWeight:"bold"}}>Daily Payment</TableCell>
            <TableCell style={{fontWeight:"bold"}}>Payment Count</TableCell>
            <TableCell style={{fontWeight:"bold"}}>Check Trip</TableCell>
        </TableRow>
    
        
        <TableBody>
            {orders?.vehicle_extrapayments.map((ad)=>{
                return (
                    <>
                        <TableRow key={ad.id}>
                            <TableCell>{ad.id}</TableCell>
                            <TableCell>{ad.createdAt.slice(0,10)}</TableCell>
                            <TableCell>{ad?.rangka}</TableCell>
                            <TableCell>{ad.dinamo}</TableCell>
                            <TableCell>{ad.controllerNumber}</TableCell>
                            <TableCell>{ad.nopol}</TableCell>
                            <TableCell>{ad.dailyPayment}</TableCell>
                            <TableCell>{ad.paymentCounter}</TableCell>
                            {/* <TableCell><button onClick={()=>{getTrip(ad);setOpenPayments(true)}}>Detail</button></TableCell> */}
                        </TableRow>
                    </>
                )
            })}
        </TableBody>
        
    </Table>
</Paper>  
}
</div>
    
  )
}
