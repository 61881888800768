import React, { Fragment,useState,useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Select, FormControl, InputLabel } from "@material-ui/core";
import '../css/pageList.css' 

export default (props)=> {
  const classes = useStyles();
  const [whereClause,setWhereClause]=useState({})
  
  useEffect(() => {
    const temp = Object.keys(props.sort).filter(item=>item!=='whereClauseUser'&&item!=='whereClauseBattery'&&item!=='whereClauseRemark')
    let obj ={}
    temp.forEach(item=>{
      obj[item]=props.sort[item]
    })
    props.setSort({...whereClause,...obj});
  }, [whereClause])

  const handleType = (item) =>{
    setWhereClause({[item.type]:item.sortBy})
  }  

  return (
    <Fragment>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">Search By</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
          >
            {props.sortBy.map((item,index) => {
              return(
              <li  className='containerSelectRental'value={item.sortBy} key={item.sortBy}  >
                <button className='selectList' onClick={handleType.bind(null,item)} value={item.type}>{item.sortBy}</button>
              </li>
            )})}
          </Select>
        </FormControl>
    </Fragment>
  );
}

const useStyles = makeStyles(theme=>({
    formControl: {
      margin: theme.spacing(1.8),
      minWidth: 120 ,
      maxWidth: 200,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));