import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios'
import Table from '@material-ui/core/Table';
import Modal from '@material-ui/core/Modal';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import * as XLSX  from 'xlsx';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import BeatLoader from "react-spinners/BeatLoader";
import ReactPaginate from 'react-paginate';
import { useQuery } from '@tanstack/react-query';

const Leads = () => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };
    const style2 = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        alignItems: 'center',
        width: '40%',
        boxShadow: 24,
        p: 4,
      }
    const formatter = new Intl.NumberFormat('id', {
        style:'currency',
        currency:'IDR',
        maximumFractionDigits: 0
      })
    const [withdraw, setWithdraw] = useState()
    const [withdrawInit, setWithdrawInit] = useState()
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [currentPage, setCurrentPage] = useState(1)
    const [paketResult, setPaketResult] = useState()
    const [perPage, setPerPage] = useState(10)
    const [salesId, setSalesId] = useState('')
    const [search, setSearch] = useState('')
    const [loading, SetLoading] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [isOpen2, setIsOpen2] = useState(false)
    const [paketOnly, setPaketOnly] = useState(1)
    const [pageCount, setpageCount] = useState()
    const permissions = localStorage.getItem('permissions')
    
    const getData =()=>{
        setIsOpen(true)
        SetLoading(true)
            axios.get(`${process.env.REACT_APP_BASEURL}/newAdmin/appraisal/leads/?currentPage=${currentPage}&orderBy=id&dir=desc&salesId=${salesId}&startDate=${startDate}&endDate=${endDate}&leadPointOnly=${paketOnly}&perPage=${perPage}&search=${search}`, {
                headers : {
                    'token' : localStorage.getItem('token')
                }})
            .then((res)=>
                {   
                    SetLoading(false)
                    setIsOpen(false)
                    setpageCount(res.data.data.count/perPage)
                    setWithdraw(res.data.data.rows)
                }
            )
         
    }

    const getPromotorList =() =>{
        axios.get(`${process.env.REACT_APP_BASEURL}/newAdmin/appraisal/leads/salesList`, {
            headers : {
                'token' : localStorage.getItem('token')
            }})
        .then((res)=>
            {
                setWithdrawInit(res.data.data)
            }
        )
    }

    const recalculate =() =>{
        setIsOpen(true)
        SetLoading(true)
        axios.post(`${process.env.REACT_APP_BASEURL}/newAdmin/appraisal/leads/recalculate/all`,{}, {
            headers : {
                'token' : localStorage.getItem('token')
            }})
        .then((res)=>
            {
                SetLoading(false)
                    setIsOpen(false)
            alert('berhasil recalculate semua data')
            getData()
            }
        )
    }

    const cekPaket =(ad) =>{
        axios.get(`${process.env.REACT_APP_BASEURL}/newAdmin/appraisal/leads/${ad.id}/check`, {
            headers : {
                'token' : localStorage.getItem('token')
            }})
        .then((res)=>
            {
                setPaketResult(res.data)
                setIsOpen2(true)
            }
        )
    }

    const { isLoading, refetch } = useQuery(
        {
            queryKey: ['projects', currentPage, salesId, startDate, endDate, paketOnly, perPage],
            queryFn: async () => {await getData(currentPage,salesId, startDate, endDate, paketOnly, perPage)},
            keepPreviousData : true,
            refetchOnWindowFocus: false
    });
   
    const  handlePageClick = async (data)=>{
        setCurrentPage(data.selected+1  )
    }

    function handleExcel(){
        
            var data=[]
            withdraw.forEach(element => {
                let rule=[]
                let point=[]
                let paket=[]
                let paketA=[]
                let paketB=[]
                element.leads.map((items)=>{
                    rule[items.category] = items.code
                    point['point '+items.code[0]] = items.point
                })
                element.paket.map((items)=>{
                    paket['paket']=items.name
                })
                element.paket_a.map((items)=>{
                    paket['paket A']=items.name
                })
                element.paket_b.map((items)=>{
                    paket['paket B']=items.name
                })
                data.push({
                    'id':element.id,
                    'date':element.createdAt.slice(0,10),
                    'name':element.name,
                    'phoneNumber':element.phoneNumber,
                    'address':element.address,
                    'lead point':element.lead_point,
                    'lead point A':element.lead_point_a,
                    'lead point B':element.lead_point_b,
                    ...rule,
                    ...point,
                    ...paket,
                    ...paketA,
                    ...paketB
                })
            })
            var wb = XLSX.utils.book_new()
            let ws = XLSX.utils.json_to_sheet(data)
            ws['!cols'] = [];
            ws['!cols'] = [
                {wch: 10},
                {wch: 15},
                {wch: 15},
                {wch: 20},
                {wch: 20},
                {wch: 10},
                {wch: 10},
                {wch: 8},{wch: 8},{wch: 8},{wch: 8},{wch: 8},{wch: 8},{wch: 8},{wch: 8},
                {wch: 8},{wch: 8},{wch: 8},{wch: 8},{wch: 8},{wch: 8},{wch: 8},{wch: 8},
                {wch: 8},{wch: 8},{wch: 8},{wch: 8},{wch: 8},{wch: 8},
                {wch: 8},{wch: 8},{wch: 8},{wch: 8},{wch: 8},{wch: 8},{wch: 8},
                {wch: 8},
                {wch: 8},
                {wch: 8},
                {wch: 8},
                {wch: 8},
                {wch: 8},
                {wch: 8},
                {wch: 8},
                {wch: 8}, 
                {wch: 8},
                {wch: 8},
                {wch: 8},
                {wch: 8},
                

                {wch: 8},// "characters"
            ];

            XLSX.utils.book_append_sheet(wb,ws, 'mySheet1');
            XLSX.writeFile(wb, 'myExcel.xlsx') 
    }    
    


    useEffect(()=>{
        getPromotorList()
    },[])
    
  return (
    <div style={{padding:'5rem 0 0 0 '}}>
    <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{overflow:"auto", top:"0"}}
      >
        <Box sx={style2} style={{display:'flex', gap:'2rem', flexDirection:'column'}}>
        <BeatLoader  color='white' loading={loading} size={50}/>
        </Box>
    </Modal>

    <Modal
        open={isOpen2}
        onClose={()=>setIsOpen2(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{overflow:"auto", top:"0"}}
      >
        <Box sx={style} style={{display:'flex', gap:'2rem', flexDirection:'column'}}>
        <Table >
            <TableRow >
                <TableCell style={{fontWeight:"bold"}}>Paket</TableCell>
                <TableCell style={{fontWeight:"bold"}}>DP</TableCell>
            </TableRow>

            <TableBody>
                {paketResult?.package?.map((ad)=>{
                    return(
                        <>
                        <TableRow key={ad.id}>
                            <TableCell>{ad.name}</TableCell>
                            <TableCell>{formatter.format(ad.min_dp)}</TableCell>
                        </TableRow>
                        </>
                    )
                })}
            </TableBody>
        </Table>

        <Table >
            <TableRow >
                <TableCell style={{fontWeight:"bold"}}>Paket A</TableCell>
                <TableCell style={{fontWeight:"bold"}}>DP</TableCell>
            </TableRow>

            <TableBody>
                {paketResult?.package_a?.map((ad)=>{
                    return(
                        <>
                        <TableRow key={ad.id}>
                            <TableCell>{ad.name}</TableCell>
                            <TableCell>{formatter.format(ad.min_dp)}</TableCell>
                        </TableRow>
                        </>
                    )
                })}
            </TableBody>
        </Table>

        <Table >
            <TableRow >
                <TableCell style={{fontWeight:"bold"}}>Paket B</TableCell>
                <TableCell style={{fontWeight:"bold"}}>DP</TableCell>
            </TableRow>

            <TableBody>
                {paketResult?.package_b?.map((ad)=>{
                    return(
                        <>
                        <TableRow key={ad.id}>
                            <TableCell>{ad.name}</TableCell>
                            <TableCell>{formatter.format(ad.min_dp)}</TableCell>
                        </TableRow>
                        </>
                    )
                })}
            </TableBody>
        </Table>
        </Box>
    </Modal>

    <select style={{height:"2.3rem"}} onChange={(e)=>{setSalesId(e.target.value);setPerPage('all')}}>
            <option value=''>Filter by sales</option>
            {
                withdrawInit?.map((salesid)=>{
                    return(
                        <option value={salesid.id}>{salesid.name}</option>
                    )
                })
            }
    </select>
    &nbsp;
    <select style={{height:"2.3rem"}} onChange={(e)=>setPerPage(e.target.value)}>
            <option disabled selected>Per Page</option>
            <option value='10'>10</option>
            <option value='30'>30</option>
            <option value='all'>All</option>
    </select>  
    &nbsp;
    <select style={{height:"2.3rem"}} onChange={(e)=>setPaketOnly(e.target.value)}>
            <option value='0'>Show All Data</option>
            <option value='1' selected>Show Paket Only</option>
    </select>  
    &nbsp;
    <label>Start date : </label>
    <input style={{height:"2.3rem"}} type='date' defaultValue='' onChange={(e)=>setStartDate(e.target.value)}/>
    &nbsp;
    <label>End date : </label>
    <input style={{height:"2.3rem"}} type='date' defaultValue='' onChange={(e)=>setEndDate(e.target.value)}/>
    &nbsp;
    <input onChange={(e)=>{setSearch(e.target.value)}} placeholder="Pencarian" style={{height:"2.3rem"}}></input>
    <button className='addUserBtn' onClick={getData}>Search</button>
    &nbsp;
    <button className='addUserBtn' onClick={recalculate}>Recalculate All Data</button>
    &nbsp;
    <button type="button"  className='addUserBtn'  onClick={handleExcel}>Export Excel</button>

    <Paper>
        <Table >
            <TableRow >
                <TableCell style={{fontWeight:"bold"}} className='tid'>ID</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Date</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Name</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Phone</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Address</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Point</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Point A</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Point B</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Staff</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Rules</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Paket</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Paket A</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Paket B</TableCell>
                {/* <TableCell style={{fontWeight:"bold"}}>Cek</TableCell> */}
            </TableRow>
        
            {
                 
            <TableBody>
                {withdraw?.filter((ad)=> {
                        return (
                        search.toLowerCase() === '' 
                        ? ad
                        : ad.name?.includes(search)
                        )
                    }
                        ).map((ad)=>{
                    return (
                        <>
                            <TableRow key={ad.id}>
                                <TableCell >{ad.id}</TableCell>
                                <TableCell >{ad.createdAt.slice(0,10)}</TableCell>
                                <TableCell >{ad.name}</TableCell>
                                <TableCell >{ad.phoneNumber}</TableCell>
                                <TableCell >{ad.address}</TableCell>
                                <TableCell >{ad.lead_point}</TableCell>
                                <TableCell >{ad.lead_point_a}</TableCell>
                                <TableCell >{ad.lead_point_b}</TableCell>   
                                <TableCell >{ad.staff?.name}</TableCell>                             
                                <TableCell >{ad?.leads.map((data)=>{
                                    return(
                                    <a>{data.code}, </a>
                                    )
                                })}
                                </TableCell>
                               
                                <TableCell >{ad.paket?.map((data)=>{
                                    return(
                                    <a>{data.name}, </a>
                                    )
                                })}
                                </TableCell>   
                                 <TableCell >{ad.paket_a?.map((data)=>{
                                    return(
                                    <a>{data.name}, </a>
                                    )
                                })}
                                </TableCell>   
                                 <TableCell >{ad.paket_b?.map((data)=>{
                                    return(
                                    <a>{data.name}, </a>
                                    )
                                })}
                                </TableCell>   
                                {/* <TableCell >{ad.lead_point == null ? <></> : <button onClick={()=>cekPaket(ad)}>Cek</button>}</TableCell>   */}
                            </TableRow>
                        </>
                    )
                })}
            </TableBody>
            }

        </Table>
    </Paper>  
    
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
        containerClassName={'pagination'}
        pageClassName={'pageContainer'}
        pageLinkClassName={'changePageButton'}
        activeClassName={'active'}
      />
  </div>
  )
}

export default Leads