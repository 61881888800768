let defaultState={
  progress:0,
  showBar:false
}

export default function progressBar(state=defaultState,action) {
  switch (action.type){
    case 'changeProgress':{
      return {...state, progress:action.payload}
    }
    case 'showBar':{
      return{...state, showBar:action.payload}
    }
    default:return state   
  }
}
