export const insertData = (payload) =>{
    // if(type&&type==='flush') return {type:'insertDataFlush',payload}
    return {
      type:'INSERT_DATA_LOW_BATTERY',
      payload
    }
  }
  
  export const insertDataFlush=(payload)=>{
    return {type:'INSERT_DATA_FLUSH_LOW_BATTERY',payload}
  }
  export const insertDataExport = (payload)=> {
    return{type:'INSERT_DATA_EXPORT_BATTERY', payload}
  }
  export const fetchStatus = payload =>{
    return {
      type:'FETCH_STATUS_BATTERY',
      payload
    }
  }
  
export const insertPage = (payload) =>{
  return {
    type:'INSERT_PAGE_LOW_BATTERY',
    payload
  }
}