import React from 'react'
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardDatePicker,} from '@material-ui/pickers';

function DateTime(props) {
    const handleDateChange=(type,date)=>{
        props.setDateTime({...props.dateTime, [type]:`${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`})
    }
    return(
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {dateField.map(item=>
                <KeyboardDatePicker
                margin="normal"
                id={item.name}
                name={item.name}
                label={item.label}
                format="MM/dd/yyyy"
                value={props.dateTime[item.name]}
                onChange={handleDateChange.bind(null,[item.name])}
                key={item.name}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                />
            )}
            </MuiPickersUtilsProvider>
    )
}

export default DateTime

const dateField= [
    {name:'startDate', label:'Start Date'},
    {name:'endDate', label:'End Date'}
  ]
  