const defaultState = {
    data:[],
    limit:20,
    currentPage:0,
    totalPages:1,
    lastCurrentPage:0,
    lastTotalPages:0,
    export:[],
    mode:false,
   }
   
   export default (state=defaultState , action) => {
     switch(action.type){
       case 'INSERT_DATA_BATTERY' : {
         return {...state, data:[...state.data,...action.payload]}
       }
       case 'INSERT_DATA_FLUSH_BATTERY':{
         return {...state, data:action.payload}
       }
       case 'CHANGE_PAGE_BATTERY':{
         return{...state,currentPage:action.payload}
       }
       case 'LAST_TOTAL_PAGES_BATTERY':{
         return{...state,lastTotalPages:action.payload}
       }
       case 'LAST_CURRENT_PAGE_BATTERY':{
         return{...state,lastCurrentPage:action.payload}
       }
       case 'TOTAL_PAGE_BATTERY':{
         return{...state, totalPages:action.payload}
       }
       case 'INSERT_DATA_EXPORT_BATTERY':{
        return{...state, export:[...state.export,...action.payload]}
       }
       case 'FETCH_STATUS_BATTERY' : {
        return {...state, mode:action.payload}
       }
       default: return state
     }
   }
   