const defaultState = {
    data: [],
    mode:false,
  };
  
  export default (state = defaultState, action) => {
    switch (action.type) {
      case "INSERT_DATA_PENDING_CUSTOMER":
        return { ...state, data: [...state.data, ...action.payload] };
      case 'FETCH_STATUS' : {
        return {...state, mode:action.payload}
      }  
      default:
        return state;
    }
  };
  