import React, { useEffect, useState } from 'react'
import {connect} from 'react-redux'
import { createTheme } from '@material-ui/core/styles'
import {withRouter} from 'react-router-dom'
import axios from 'axios'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell, { tableCellClasses } from '@material-ui/core/TableCell';
import { TextField } from "@material-ui/core";
import TableContainer from '@material-ui/core/Table';
import TableHead from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';



const Swapstation = () => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

    const [openAddSP, setOpenAddSP] = useState(false);
    const [openAddAdm,setOpenAddAdm] = useState(false)
    const [openAddAdmin,setOpenAddAdmin] = useState(false)
    const [openAddBatt,setOpenAddBatt] = useState(false)
    const [sp, setSp] = useState();
    const [spointID, setSpointID] = useState();
    const [newSP, setNewSP] = useState();
    const [batteryList, setBatteryList] = useState();
    const [batteryNo, setBatteryNo] = useState('');
    const [batteryID, setBatteryID] = useState('');
    const [readyBattery, setReadyBattery] = useState();
    const [file1, setFile1] = useState()
    const [file2, setFile2] = useState()
    const [address, setAddress] = useState()
    const [name, setname] = useState()
    const [open_time, setOpentime] = useState()
    const [phoneNumber, setPhoneNumber] = useState()
    const [close_time, setClosetime] = useState()
    const [longitude, setLongitude] = useState()
    const [latitude, setLatitude] = useState()
    const [status, setstatus] = useState()
    const [description, setDescription] = useState()

    
    function getData(){
    axios.get(`${process.env.REACT_APP_BASEURL}/newAdmin/service_point/`, {
        headers : {
            'token': `${localStorage.getItem('token')}`
        }})
    .then((res)=>
        {
       
        setSp(res.data.data.rows)}
    )
    }

    function handleSubmit(e){
        e.preventDefault()
 
        const dataUpload = new FormData();

        dataUpload.append('cover', file1);
        dataUpload.append('avatar', file2);
        dataUpload.append('name', name);
        dataUpload.append('phoneNumber',phoneNumber );
        dataUpload.append('address',address );
        dataUpload.append('description', description);
        dataUpload.append('latitude', latitude);
        dataUpload.append('longitude', longitude);
        dataUpload.append('open_time', open_time);
        dataUpload.append('close_time', close_time);
        dataUpload.append('status', status);

        axios.post(`${process.env.REACT_APP_BASEURL}/newAdmin/service_point/`, dataUpload, {
            headers : {
                'token' : localStorage.getItem('token')
            }
        }).then(alert('Service Point Baru Berhasil Ditambahkan'))
        .catch((err)=>{
            alert(err.response.data.message)
        })
        getData();
    }

    function getBatteryList(spoint,e){
        axios.get(`${process.env.REACT_APP_BASEURL}/newAdmin/service_point/${spoint.id}/battery`,{
             headers : {
            'token': `${localStorage.getItem('token')}`
        }}).then((res)=>{
           setBatteryList(res.data.data)
           setOpenAddAdm(true)
           setSpointID(spoint.id)
         })
         
    }

    function getReadyBatteryList(){
        axios.get(`${process.env.REACT_APP_BASEURL}/newAdmin/service_point/battery/create?batteryNo=${batteryNo}`,{
             headers : {
            'token': `${localStorage.getItem('token')}`
        }}).then((res)=>{
            setReadyBattery(res.data.message)
            setOpenAddBatt(true)
            setBatteryID(res.data.message[0].id)
         })
         
    }

    function handleSubmitAddBatt(e){
        e.preventDefault()
        axios.post(`${process.env.REACT_APP_BASEURL}/newAdmin/service_point/${spointID}/battery`, {
            batteryId:batteryID
        }, {
            headers : {
                'token' : localStorage.getItem('token')
            }
        })
        .then((res)=>
            alert('Baterai Baru Berhasil Ditambahkan'))
            .catch((err)=>{
                alert(err.response.data.message)
            })
            getBatteryList(spointID);
    }

    function handleDeleteBattery(data,e){
        axios.post(`${process.env.REACT_APP_BASEURL}/newAdmin/service_point/${data.service_battery?.sevicePointId}/battery/delete`,{
            batteryId:data.id
        },{
            headers : {
                'token' : localStorage.getItem('token')
            }
        }).then((res)=>
           { alert('Baterai berhasil dihapus dari service')
            getBatteryList(spointID)}
        )
        .catch((err)=>{
            alert(err.response.data.message)
        })
    }

    function handleDeleteSpoint(spoint,e){
        axios.post(`${process.env.REACT_APP_BASEURL}/newAdmin/service_point/${spoint.id}/delete`,{},{
            headers : {
                'token' : localStorage.getItem('token')
            }
        }).then((res)=>{
            alert('Berhasil menghapus service point')
                getData()
        })
        .catch((err)=>{
            alert(err)
        })
    }

    function handleAddAdmin(spoint,e){
        axios.post(`${process.env.REACT_APP_BASEURL}/admin/service_staff/create`,{...newSP},{
            headers : {
                'token' : localStorage.getItem('token')
            }
        }).then((res)=>{
            alert('Berhasil menambah admin')
                getData()
        })
        .catch((err)=>{
            alert(err)
        })
    }

    function handleChange(e){
        let data={...newSP}
        data[e.target.name]=e.target.value
        data['servicePointId']=spointID
        setNewSP(data)
    }

    useEffect(() => {
            getData();
        }, [])

  return (
    <div style={{paddingTop:'5rem'}}>
        <Modal
        open={openAddAdm}
        onClose={()=>setOpenAddAdm(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} >
        <button type="button"  className='addUserBtn' onClick={getReadyBatteryList}>Add New Battery to Service Point</button>
        
        <Table >
       
            <TableRow >
                <TableCell style={{fontWeight:"bold"}} className='tid'>ID</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Battery No</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Action</TableCell>
            </TableRow>
        
            <TableBody>
                {
                    batteryList?.map((data)=>{
                        return(
                        <TableRow>
                            <TableCell>{data.id}</TableCell>
                            <TableCell>{data.batteryNo}</TableCell>
                            <TableCell><button onClick={()=>handleDeleteBattery(data)}>Delete</button></TableCell>
                        </TableRow>
                        )
                    })
                }
            </TableBody>
            </Table>
        </Box>
        </Modal>

        <Modal
        open={openAddBatt}
        onClose={()=>setOpenAddBatt(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} >
        <span style={{fontWeight:"bolder", fontSize:"1.5rem"}}>Add New Battery</span>
        <br/>
        <br/>
        <input type="text" onChange={(e)=>setBatteryNo(e.target.value)}/> <button onClick={getReadyBatteryList}>Search Battery</button>
        <br/>
        <br/>
                <select value={batteryID} onChange={(e)=>setBatteryID(e.target.value)}>
                {
                    readyBattery?.map((data)=>{
                        return(
                            <option selected value={data.id}>{data.batteryNo}</option>
                        )
                    })
                }
                </select>
                &nbsp;
        <button onClick={handleSubmitAddBatt}>Add Battery</button>        
        </Box>
        </Modal>

        <Modal
        open={openAddSP}
        onClose={()=>setOpenAddSP(false )}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{textAlign:"center"}}>
        <span style={{fontWeight:"bolder", fontSize:"1.5rem"}}>Add New Service Point</span>
            <div className='containerAddUser'   >
            <TextField style={{width:"100%"}} id="outlined-basic" label="Name" variant="outlined" name='name' onChange={(e)=>setname(e.target.value)}/>
            </div>
            <div className='containerAddUser'>
            <TextField style={{width:"100%"}} id="outlined-basic" label="Phone" variant="outlined" name="phoneNumber" onChange={(e)=>setPhoneNumber(e.target.value)}/>
            </div>
            <div className='containerAddUser'>
            <TextField style={{width:"100%"}} id="outlined-basic" label="Address" variant="outlined" name='address' onChange={(e)=>setAddress(e.target.value)}/>
            </div>
            <div className='containerAddUser'>
            <TextField style={{width:"100%"}} id="outlined-basic" label="Description" variant="outlined" name='description' onChange={(e)=>setDescription(e.target.value)}/>
            </div>
            <div className='containerAddUser'>
            <TextField  style={{width:"100%"}} id="outlined-basic" label="Longitude" variant="outlined" name='longitude' onChange={(e)=>setLongitude(e.target.value)}/>
            </div>
            <div className='containerAddUser'>
            <TextField style={{width:"100%"}} id="outlined-basic" label="Latitude" variant="outlined" name='latitude' onChange={(e)=>setLatitude(e.target.value)}/>
            </div>
            <div className='containerAddUser'>
            <input style={{width:"100%"}} id="outlined-basic" type='time' label="Open Time" variant="outlined" name='open_time' onChange={(e)=>setOpentime(e.target.value)}/>
            </div>
            <div className='containerAddUser'>
            <input style={{width:"100%"}} id="outlined-basic" type='time' label="Close-Time" variant="outlined" name='close_time' onChange={(e)=>setClosetime(e.target.value)}/>
            </div>
            <div className='containerAddUser'>
                <input  id="outlined-basic" label="Cover" type='file' variant="outlined" name='cover' onChange={(e)=>setFile1(e.target.files[0])}/>
                <input  id="outlined-basic" label="Avatar" type='file' variant="outlined" name='Avatar' onChange={(e)=>setFile2(e.target.files[0])}/>
            </div>
            <div className='containerAddUser'>
            <select style={{width:"100%"}} id="outlined-basic" label="Status" variant="outlined" name='status' onChange={(e)=>setstatus(e.target.value)}>
                <option selected disabled>Status</option>
                <option value='1'>Active</option>
                <option value='0'>Non Active</option>
            </select>
            </div>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
                Submit
              </Button>
              <br/><br/>
                    <Button variant="contained" color="primary" onClick={()=>setOpenAddSP(!openAddSP)}>
                        Close
                    </Button>
        </Box>
        </Modal>

        <Modal
        open={openAddAdmin}
        onClose={()=>setOpenAddAdmin(false )}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{textAlign:"center"}}>
        <span style={{fontWeight:"bolder", fontSize:"1.5rem"}}>Add New Admin</span>
            <div className='containerAddUser'   >
            <TextField style={{width:"100%"}} id="outlined-basic" label="Name" variant="outlined" name='name' onChange={handleChange}/>
            </div>
            <div className='containerAddUser'>
            <TextField style={{width:"100%"}} id="outlined-basic" label="Phone" variant="outlined" name="phoneNumber" onChange={handleChange}/>
            </div>
            <div className='containerAddUser'>
            <TextField style={{width:"100%"}} id="outlined-basic" label="Address" variant="outlined" name='address' onChange={handleChange}/>
            </div>
            <div className='containerAddUser'>
            <TextField style={{width:"100%"}} id="outlined-basic" label="email" variant="outlined" name='email' onChange={handleChange}/>
            </div>
            <div className='containerAddUser'>
            <TextField  style={{width:"100%"}} id="outlined-basic" label="password" variant="outlined" name='password' onChange={handleChange}/>
            </div>
            <div className='containerAddUser'>
            <select style={{width:"100%"}} id="outlined-basic" label="role" variant="outlined" name='role' onChange={handleChange}>
                <option selected disabled>Status</option>
                <option value='admin'>Admin</option>
                <option value='staff'>Staff</option>
            </select>
            </div>
            <Button variant="contained" color="primary" onClick={handleAddAdmin}>
                Submit
              </Button>
              <br/><br/>
                    <Button variant="contained" color="primary" onClick={()=>setOpenAddSP(!openAddSP)}>
                        Close
                    </Button>
        </Box>
        </Modal>
      

        <button type="button"  className='addUserBtn' onClick={()=>setOpenAddSP(!openAddSP)}>Add New Service Point</button>
        <Paper>
        <Table >
       
            <TableRow >
                <TableCell style={{fontWeight:"bold"}} className='tid'>No</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Name</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Address</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Telp</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Swap?</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Action</TableCell>
            </TableRow>
        
            <TableBody>
                {sp?.map((spoint)=>{
                    return (
                        <>
                            <TableRow>
                                <TableCell >{spoint.id}</TableCell>
                                <TableCell >{spoint.name}</TableCell>
                                <TableCell >{spoint.address}</TableCell>
                                <TableCell >{spoint.tel}</TableCell>
                                <TableCell >{spoint.swapstation == true ? '✔' : 'X'}</TableCell>
                                <TableCell style={{width:"13rem"}}>
                                    <button onClick={()=>handleDeleteSpoint(spoint)}>Hapus</button> &nbsp;
                                    <button onClick={()=>getBatteryList(spoint)}>Cek Baterai</button>&nbsp;
                                    <button onClick={()=>{setOpenAddAdmin(true);setSpointID(spoint.id)}}>Add Admin</button>
                                </TableCell>
                            </TableRow>
                        </>
                    )
                })}
            </TableBody>
        </Table>
        </Paper>
    </div>
  )
  
}
const mapStateToProps = state=>{
    return{
      progress:state.ProgressBar.progress,
      data:state.PendingCustomer.data,
      status:state.PendingCustomer.mode
    }
}


export default withRouter(connect(mapStateToProps)(Swapstation))