import React from "react";
import { AppBar, Toolbar, IconButton, Typography, Slide } from "@material-ui/core";
import {Menu} from "@material-ui/icons"
import clsx from "clsx";

export const Header = ({ handleDrawerToggle, classes, pathName }) => (
  <div>
    <Slide
      direction="down"
      appear={false}
      in={pathName === "/login" ? false : true}
    >
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            className={clsx(classes.menuButton)}
          >
            <Menu />
          </IconButton>
          <Typography variant="h6" noWrap style={{fontFamily: 'Open Sans'}}>
            Ecgobike 
          </Typography>
        </Toolbar>
      </AppBar>
    </Slide>
  </div>
)
