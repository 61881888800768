import { createStore,applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import allReducers from './reducer'
import thunk from 'redux-thunk'

const persistConfig = {
  key: 'root',
  storage,
  whitelist:[]
}

const persistedReducer = persistReducer(persistConfig, allReducers)


export const store = createStore(persistedReducer,applyMiddleware(thunk))
export const persistor = persistStore(store)