import React from "react";
import { Modal, Backdrop, Fade, Select, MenuItem, FormControl, InputLabel, TextField  ,Button  } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Axios from "../../../utils/axios";

function ModalCommand(props) {
  const classes = useStyles();
  const handleClose = () => {
    props.setOpen(false);
  };

  const handleCommandType =(event) =>{
    props.setDataCommand({...props.dataCommand, [event.target.name]:event.target.value})
  }

  const handleSubmit = async event => {
    event.preventDefault()
    try {
        const response = await Axios(props.props, "POST", "/cs/command/create",true,props.dataCommand).catch(e => {throw e})
        alert(response.data.message)
        window.location.reload(true)
      } catch (error) {
        alert(error.message);
      }
    
  }
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.isOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={props.isOpen}>
            <div className={classes.paper}>
              <div style={{display:'flex',justifyContent:'flexStart'}}>
                <FormControl  className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">Command here</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value='addbalance'
                    onChange={handleCommandType}
                    name='command'
                  >
                      <MenuItem value='addbalance'>
                        Add Balance
                      </MenuItem>
                  </Select>
                </FormControl>
                <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="value"
                label="Value"
                name="value"
                autoComplete="value"
                autoFocus
                value={props.dataCommand.value}
                onChange={handleCommandType}
                style={{marginRight:'10px', minWidth:'60%'}}
                />
              </div>
              <div style={{display:'flex', justifyContent:'flex-end'}}>
                <Button variant="contained" color="secondary" onClick={handleClose} style={{margin:'10px 10px 0 0'}}>cancel</Button>
                <Button variant="contained" color="primary" onClick={handleSubmit} style={{margin:'10px 10px 0 0'}}>Submit</Button>
              </div>  
            </div> 
        </Fade>
      </Modal>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: "25%"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minWidth: '700px',
    display:'flex',
    flexDirection:'column'
  }
}));

export default ModalCommand;
