import React, {useState, useEffect} from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell, { tableCellClasses } from '@material-ui/core/TableCell';
import { TextField } from "@material-ui/core";
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import axios from 'axios';

const Ads = () => {
    
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [newAds, setNewAds] = useState();
    const [ads, setAds] = useState();

      function getData(){
        axios.get(`${process.env.REACT_APP_BASEURL}/admin/webads`, {
            headers : {
                'token' : localStorage.getItem('token')
            }
        }).then((response)=>
          {
              setAds(response.data.data)
          }
        )      
      }

    useEffect(() => {
       getData()
      }, [])

    function handleSubmit(e){
        e.preventDefault()
        axios.post(`${process.env.REACT_APP_BASEURL}/admin/webads/create`, newAds, {
            headers : {
                'token' : localStorage.getItem('token')
            }
        }).then((res)=> {
            alert('berhasil menambahkan iklan')
            getData();
        })
        
    }

    function handleChange(e){
        let data = {...newAds}
        data[e.target.name]= e.target.value; 
        setNewAds(data);
      }

    function handleDelete(ad,e){
        e.preventDefault()
        axios.delete(`${process.env.REACT_APP_BASEURL}/admin/webad/delete/${ad.id}`, {
            headers : {
                'token' : localStorage.getItem('token')
            }
        })
        getData()
    }

  return (
    <div style={{paddingTop:'5rem'}}>
         <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{textAlign:"center"}}>
        <span style={{fontWeight:"bolder", fontSize:"1.5rem"}}>Add New ads</span>
            <div className='containerAddUser'   >
            <TextField style={{width:"100%"}} id="outlined-basic" label="Name" variant="outlined" name='name' onChange={handleChange}/>
            </div>
            <div className='containerAddUser'>
            <TextField style={{width:"100%"}} id="outlined-basic" label="Phone" variant="outlined" name="phoneNumber" onChange={handleChange}/>
            </div>
            <div className='containerAddUser'>
            <TextField style={{width:"100%"}} id="outlined-basic" label="Address" variant="outlined" name='address' onChange={handleChange}/>
            </div>
            <div className='containerAddUser'>
            <TextField style={{width:"100%"}} id="outlined-basic" label="email" variant="outlined" name='email' onChange={handleChange}/>
            </div>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
                Submit
              </Button>
        </Box>
      </Modal>
        <button type="button"  className='addUserBtn'  onClick={handleOpen}>Add New Ads</button>
            <Paper>
        <Table >
       
            <TableRow >
                <TableCell style={{fontWeight:"bold"}} className='tid'>No</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Name</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Address</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Telp</TableCell>
                <TableCell style={{fontWeight:"bold"}}>eMail</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Action</TableCell>
            </TableRow>
        
           
            <TableBody>
                {ads?.map((ad)=>{
                    return (
                        <>
                            <TableRow>
                                <TableCell >{ad.id}</TableCell>
                                <TableCell >{ad.name}</TableCell>
                                <TableCell >{ad.address}</TableCell>
                                <TableCell >{ad.phone}</TableCell>
                                <TableCell >{ad.phone}</TableCell>
                                <TableCell ><button>Edit</button> <button onClick={(e)=>handleDelete(ad,e)}>Delete</button></TableCell>
                            </TableRow>
                        </>
                    )
                })}
            </TableBody>
           
        </Table>
        </Paper>
    </div>
  )
}

export default Ads