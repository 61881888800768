import React from 'react'
import {TextField,Button} from '@material-ui/core'

function SearchingField (props) {

    const handleSearchEnter=(e)=>{if(e.key==='Enter'&&props.canSearch) props.fetchSearch()}
    const handleSearchChange=(e)=>{props.props.searchData(e.target.value)}

    return (
            <div style={{flexDirection:'row',display:'flex', alignItems:'center',justifyContent:'flex-end'}}>
                {props.searchMode&&<Button onClick={props.cancelSearch} style={{flex:'0.15',backgroundColor:'#91000d',width:'100%',height: '25%',color: 'white', marginTop:'5px'}} >Cancel Search</Button>}
                {props.searchMode&&<div style={{flex:0.03}} />}
                <TextField variant="outlined" margin="normal" style={{flex:0.57}}
                label='Search'
                onKeyPress={handleSearchEnter}
                onChange={handleSearchChange}
                />
            </div>
    )
}


export default SearchingField