import React, {useState, useEffect} from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell, { tableCellClasses } from '@material-ui/core/TableCell';
import { MenuItem, Select, TextField, InputLabel, FormControl } from "@material-ui/core";
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import axios from 'axios';


const Promo = () => {
    
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [newPromo, setNewPromo] = useState();
    const [promo, setPromo] = useState();
    const [voucher, setvoucher] = useState();
    const [ads, setAds] = useState();
    
   
      function getPromoData(){
        axios.get(`${process.env.REACT_APP_BASEURL}/admin/web/vouchers`, {
            headers : {
                'token' : localStorage.getItem('token')
            }
        }).then((response)=>
          {
              setPromo(response.data.data)
          }
        )      
      }

      function getVoucherData(){
        axios.get(`${process.env.REACT_APP_BASEURL}/admin/voucherads`, {
            headers : {
                'token' : localStorage.getItem('token')
            }
        }).then((response)=>
          {
              setvoucher(response.data.data)
          }
        )      
      }

      function getAdsData(){
        axios.get(`${process.env.REACT_APP_BASEURL}/admin/webads`, {
            headers : {
                'token' : localStorage.getItem('token')
            }
        }).then((response)=>
          {
              setAds(response.data.data)
          }
        )   
      }
      
      useEffect(() => {
        getAdsData();
        getVoucherData();
        getPromoData();
      }, [])

      useEffect(() => {
        console.log(newPromo)
      }, [newPromo])

      function handleChange(e) {
        let data = {...newPromo}
        data[e.target.name]= e.target.value; 
        setNewPromo(data);
      }

      function handleSubmit(e){
        e.preventDefault()
        axios.post(`${process.env.REACT_APP_BASEURL}/admin/bind/webtovoucher`, newPromo, {
            headers : {
                'token' : localStorage.getItem('token')
            }
        }).then((res)=> {
            alert('promo baru telah berhasil dibuat');
            getPromoData();
        })
    }



  return (
    <div style={{paddingTop:'5rem'}}>
        <div style={{padding:'3rem'}}>

        <select name='voucherId' onChange={handleChange}>
            <option>Select Voucher</option>
            {voucher?.map((vc)=><>
                
                <option value={vc.id}>{vc.name} </option>
            </>)}
        </select>

        <select name='webadsId' onChange={handleChange}>
        <option>Select Ads</option>
        {ads?.map((ad)=><>
                
                <option value={ad.id}>{ad.name} </option>
            </>)}
        </select>

        <input name='name' placeholder='Nama promo' onChange={handleChange}/>
        <input name='description' placeholder='description' onChange={handleChange}/>
        <input name='code' placeholder='code' onChange={handleChange}/>
        <button onClick={handleSubmit}>Buat Promo</button>
        </div>
        <Paper>
        <Table >
       
            <TableRow >
                <TableCell style={{fontWeight:"bold"}} className='tid'>No</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Name</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Address</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Code</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Link Promo</TableCell>
            </TableRow>
        
           
            <TableBody>
                {promo?.map((promo)=>{
                    return (
                        <>
                            <TableRow>
                                <TableCell >{promo.id}</TableCell>
                                <TableCell >{promo.name}</TableCell>
                                <TableCell >{promo.address}</TableCell>
                                <TableCell >{promo.code}</TableCell>
                                <TableCell >https://ecgoevmoto.com/coupon/{promo.token}</TableCell>
                            </TableRow>
                        </>
                    )
                })}
            </TableBody>
           
        </Table>
        </Paper> 
       
    </div>
  )
}

export default Promo