import React, { useState, useEffect } from "react";
import BatteryModal from './modalStockBattery'
import AxiosAsli from 'axios'
import Axios from "../../../../utils/axios";

function EditBatteryDealerModal(props) {
  const [stockBattery, setStockBattery] = useState({batteryId:"", remarks:"-",id:""});
  const [placeholderBmsNumber]=useState({name:'Bms Number'});
  const [batterySuggestions, setBatterySuggestions]= useState([]);
  const [searchValuePhoneNumber,setSearchValuePhoneNumber]= useState('');
  const [searchValueBmsNumber,setSearchValueBmsNumber]= useState('');

  useEffect(()=>{if(searchValueBmsNumber!=='')findBattery()},[searchValueBmsNumber])
  useEffect(()=>{setStockBattery({...stockBattery,id:props.idStockBatteryEdit.stockBatteryId})},[props.isOpen])

  let findBatteryTimeout =null

  const findBattery =async () => {
    if(findBatteryTimeout)clearTimeout(findBatteryTimeout)
    if(searchValueBmsNumber.split("").length>3){
      findBatteryTimeout= setTimeout(async ()=>{
        try {
          const response = await AxiosAsli.get(`${process.env.REACT_APP_BASEURL}/cs/battery/downshift?filterBy=bmsId&search=${searchValueBmsNumber}&page=${1}&limit=5`,{headers:{token:localStorage.token}}).catch(e => {throw e});
          setBatterySuggestions(response.data.data)
        } catch (e) {}
      },1000)
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      const response = await Axios(props.props,"PUT","/cs/battery/updatebatteryremark",true,stockBattery).catch(e => {throw e});
      alert(response.data.message);
      window.location.reload(true);
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <div>
      <BatteryModal 
      stockBattery={stockBattery}
      setStockBattery={setStockBattery}
      setSearchValueBmsNumber={setSearchValueBmsNumber}
      searchValueBmsNumber={searchValueBmsNumber}
      batterySuggestions={batterySuggestions}
      placeholderBmsNumber={placeholderBmsNumber}
      handleSubmit={handleSubmit}
      isOpen={props.isOpen}
      setOpen={props.setOpen}
      idDealer={props.idStockBatteryEdit.stockBatteryId}
      />
    </div>
  );
}

export default EditBatteryDealerModal;