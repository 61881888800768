const defaultState = {
  search:'',
}

export default (state=defaultState,action) => {
  switch(action.type){
    case 'searching' : {
      return {...state, search:action.payload}
    }
    default: return state
  }
}