import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Modal, Backdrop, Fade } from "@material-ui/core";
import Axios from "../../../utils/axios";
import {addCustomerType} from '../../../action/userTable/addUser'
import "../../../css/addUser.css";
import {connect} from 'react-redux'
import {
  TextField,
  Button,
  Select,
  InputLabel,
  FormControl,
  MenuItem
} from "@material-ui/core";
import '../../../css/dateGPSBattery.css'

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));

function EditUserModal(props) {
  const classes = useStyles();
  const [userDetail, setUserDetail] = useState({
    name: '',
    phoneNumber: "",
    email: "",
    address: "",
    customerTypeId: null
  });


  
  const changeDataDisplayed=()=>{
    const find = props.customertype.find(item=>item.name===props.userDetail.customerType)
    setUserDetail({...props.userDetail,customerTypeId:find?find.id:null})
  }

  useEffect(()=>{changeDataDisplayed()},[props.userDetail])
  useEffect(() => {if(!props.customertype[0])getCustomerList()}, []);

  const getCustomerList = async()=>{
    try {
      const response = await Axios(props.props,'GET','/cs/user/customertype',true).catch(e=>{throw e})
      props.addCustomerType(response.data.data)
    } catch (error) {
      alert(error.message)
    }
  }

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleChange = event => {
    setUserDetail({ ...userDetail, [event.target.name]: event.target.value });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      const response = await Axios(props.props, "PUT", "/cs/user/update",true,userDetail).catch(e => {throw e})
      alert(response.data.message)
      window.location.reload(true)
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.isOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={props.isOpen}>
          <div className={classes.paper}>
            <div className="titleModal">
              <span>Edit user detail</span>
            </div>
            {fields.map(item => 
                <div className="containerAddUser" key={item.name}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name={item.name}
                    label={item.label}
                    id={item.name}
                    autoComplete={item.name}
                    onChange={handleChange}
                    key={item.name}
                    value={userDetail[item.name]}
                  />
                </div>
            )}
            <div className="containerSelect">
              <FormControl className={classes.formControl}>
                <InputLabel>Customer Type</InputLabel>
                <Select labelId="customerTypeId" id="customerTypeId" name='customerTypeId' value={userDetail.customerTypeId} onChange={handleChange}>
                  {props.customertype.map(item=>
                    <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                    )}
                </Select>
              </FormControl>
            </div>
            <div className="buttonContainer">
              <Button variant="contained" color="primary" onClick={handleSubmit}>
                Submit
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

const fields = [
  { name: "name", label: "Name" },
  { name: "phoneNumber", label: "Phone Number" },
  { name: "email", label: "Email" },
  { name: "address", label: "Address" }
];

const stateProps = state =>{
  return {
    customertype:state.AddUser.customerTypes
  }
}

const dispatchProps = dispatch => {
  return {
    addCustomerType:(value)=>{
      dispatch(addCustomerType(value))
    }
  }
}

export default connect(stateProps,dispatchProps)(EditUserModal)
