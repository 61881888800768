import React, { Fragment }  from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core'

export default function HourGps (props) {
  const classes= useStyles()
  const handleHourChange =(hour) =>{
    props.setHour({...props.Hour, [hour.target.name]:hour.target.value})
}
  
  return (
    <Fragment >
      {hourField.map(item=>
        <FormControl key ={item.name} className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">{item.name}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={props.Hour[item.name]}
          onChange={handleHourChange}
          name={item.name}
          key={item.name}
        >
          {hour.map(item=><MenuItem key={item}value={item}>{item}</MenuItem>)}
        </Select>
      </FormControl>
      )}
  </Fragment>
  )
}

const hour = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]

const hourField=[
  {name:'startHour', label:'Start Hour'},
  {name:'endHour', label:'End Hour'}
]

const useStyles = makeStyles(theme=>({
  formControl: {
    margin: theme.spacing(1.8),
    minWidth: 120,
    maxWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));