const defaultState = {
    data: [],
    page: 0,
    totalPages: 0,
    limit: 20,
    lastPage: 0,
    lastCurrentPage: 0,
    lastTotalPages: 0,
    export:[],
  };
  
  export default (state = defaultState, action) => {
    switch (action.type) {
      case "INSERT_DATA_BATTERY_RECHARGE":
        return { ...state, data: [...state.data, ...action.payload] };
      case "INSERT_PAGE_BATTERY_RECHARGE":
        return { ...state, page: action.payload };
      case "INSERT_TOTAL_PAGE_BATTERY_RECHARGE":
        return { ...state, totalPages: action.payload };
      case "INSERT_LAST_PAGE_BATTERY_RECHARGE":
        return { ...state, lastPage: action.payload };
      case "LAST_TOTAL_PAGES_BATTERY_RECHARGE": {
        return { ...state, lastTotalPages: action.payload };
      }
      case "INSERT_DATA_FLUSH_BATTERY_RECHARGE": {
        return { ...state, data: action.payload };
      }
      case "LAST_CURRENT_PAGE_BATTERY_RECHARGE": {
        return { ...state, lastCurrentPage: action.payload };
      }
      case 'INSERT_DATA_EXPORT_BATTERY_RECHARGE':{
        return{...state, export:[...state.export,...action.payload]}
      }
      default:
        return state;
    }
  };