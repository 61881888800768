import React,{useState,useEffect} from 'react'
import { insertData, insertPage, insertLastPage, insertTotalPage, insertLastCurrentPage, insertDataExport, insertLastTotalPage,insertDataFlush} from '../../../action/batteryDetailTable/bmsTable'
import {changeProgress,showBar} from '../../../action/progressBar'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import Utils from '../../../utils'
import dayjs from 'dayjs'

function BmsTable(props) {
    const data = props.location.pathname.split('/')[3]
    const [dataDisplayed,setDataDisplayed]=useState([])
    const [dataBmsDisplayedSearch,setdataBmsDisplayedSearch]=useState([])
    const [searchMode,setSearchMode]=useState(false)
    const [loading,setLoading]=useState(true)
    const [canSearch,setCanSearch]=useState(true)
    const [batteryId] = useState({whereClause:data}) 
    
    useEffect(()=>{setPage()},[])
    useEffect(()=>{setDisplayedData()},[props.currentPage,searchMode,props.data])

    const setPage=async (page)=>{
        if(!searchMode){
            if (typeof page !== "number") {
              if (props.data.filter(item => item.page == page&&item.batteryId==data).length ==0) await fetch(1)
              setLoading(false);
            } 
            else {
              if (props.data.filter(item => item.page == page&&item.batteryId==data).length ==0) return await fetch(page)
              props.insertPage(page);
            }
          }
    }

    const tableSpec=[
        {Title:'No',obj:['index'],value:'index',shown:true,type:'data'},
        {Title:'id',obj:['id'],value:'id',shown:false,type:'data'},
        {Title:'Data Type',obj:['dataType'],value:'dataType',shown:false,type:'data'},
        {Title:'Voltage',obj:['voltage'],value:'voltage',shown:true,type:'data'},
        {Title:'Mosfet Temperature',obj:['mosfetTemperature'],value:'mosfetTemperature',shown:true,type:'data'},
        {Title:'BatteryId',obj:['batteryId'],value:'batteryId',shown:false,type:'data'},
        {Title:'Remain Capacity',obj:['remaincap'],value:'remaincap',shown:true,type:'data'},
        {Title:'Full Capacity',obj:['fullcap'],value:'fullcap',shown:true,type:'data'},
        {Title:'SOC',obj:['soc'],value:'soc',shown:true,type:'data'},
        {Title:'Time Stamp',obj:['createdAt'],value:'createdAt',shown:true,type:'data'},
        {Title:'batteryId',obj:['batteryId'],value:'batteryId',shown:false,type:'data'},
      ]

    const fetch=async (page)=>{
        try {
            const response = await Utils.Axios(props,'POST',`/internal/newbattery/bms?page=${page}&limit=${props.limit}&order=DESC`,true,{...batteryId}).catch(e=>{throw e})
            props.insertData(dataBuilder(response.data,props.limit,'all'))
            props.insertPage(response.data.page)
            props.insertTotalPage(response.data.totalPages)
        } catch (error) {
            alert(error.message)
        }
    }

    const setDisplayedData=()=>{
        if(props.currentPage==0)return
        const filter = props.data.filter(item=>searchMode?item.page==props.currentPage&&item.dataType=='search':item.page==props.currentPage&&item.batteryId==data).map((item,index)=>{
          let finalData ={}
          tableSpec.forEach(element=>{
            if(element.type=='data'){
              const arr = Utils.DataConstructor(item, element.obj,index)
              Object.assign(finalData, {
                  [element.value]: arr
              })
            }
          })
          return finalData 
        })
        searchMode?setdataBmsDisplayedSearch(filter):setDataDisplayed(filter)
      }

    const cancelSearch=(e)=>{
        e.preventDefault()
        setDataDisplayed([])
        const temp = props.data.filter(item=>item.dataType!=='search')
        props.insertDataFlush(temp)
        props.insertTotalPage(props.lastTotalPages)
        props.insertPage(1)
        setSearchMode(false)
    }

    const handleChangePage=(data)=>{setPage(parseInt(data.selected)+1)}
    const dataBuilder=(data,limit,dataType)=> data.data.map((item,index)=>{return {...item,page:data.page,dataType,createdAt:dayjs(item.createdAt).format('DD MMMM YYYY HH:mm'),index:((data.page-1)*limit)+index+1}})

    return(
        <div>
        <div style={{flexDirection:'row',display:'flex',marginTop:'15px', alignItems:'center'}}>
            {searchMode?<Utils.Table dataDisplayed={dataBmsDisplayedSearch} tableSpec={tableSpec} />:<Utils.Table dataDisplayed={dataDisplayed} tableSpec={tableSpec} />}
            </div>
            {!loading&&!searchMode&&<Utils.Pagination totalPages={props.totalPages}  props={props} handleChangePageWithNumber={handleChangePage} />}
        </div>
    )
}


const mapStateToProps=(state)=>{
    return{
        progress:state.ProgressBar.progress,
        data:state.Bms.data,
        currentPage:state.Bms.page,
        totalPages:state.Bms.totalPages,
        lastPage:state.Bms.lastPage,
        limit:state.Bms.limit,
        lastCurrentPage:state.Bms.lastCurrentPage,
        lastTotalPages:state.Bms.lastTotalPages,
        exportingData:state.Bms.export,
    }
}

const mapDispatchToProps=(dispatch)=>{
    return{
        changeProgress:(load)=>{dispatch(changeProgress(load))},
        showBar:(bool)=>{dispatch(showBar(bool))},
        insertData:(data)=>{dispatch(insertData(data))},
        insertPage:(page)=>{dispatch(insertPage(page))},
        insertLastPage:(page)=>{dispatch(insertLastPage(page))},
        insertTotalPage:(totalPage)=>{dispatch(insertTotalPage(totalPage))},
        insertDataFlush:(value,type)=>{dispatch(insertDataFlush(value,type))},
        setLastCurrentPageBms:(value)=>{dispatch(insertLastCurrentPage(value))},
        setLastTotalPagesBms:(value)=>{dispatch(insertLastTotalPage(value))},
        insertDataExport:(value)=>{dispatch(insertDataExport(value))},
    }
}
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(BmsTable))