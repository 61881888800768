import React, {useState, useEffect} from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell, { tableCellClasses } from '@material-ui/core/TableCell';
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import axios from 'axios';

const Event = () => {
    
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [newEvent, setNewEvent] = useState();
    const [event, setevent] = useState();
    
    function getData(){
        axios.get(`${process.env.REACT_APP_BASEURL}/admin/get/events`, {
            headers : {
                'token' : localStorage.getItem('token')
            }
        }).then((response)=>
          {
              setevent(response.data.data)
          }
        )      
    }

    useEffect(() => {
        getData();
      }, [])

    function handleSubmit(e){
        e.preventDefault()
        axios.post(`${process.env.REACT_APP_BASEURL}/admin/event/create`, newEvent, {
            headers : {
                'token' : localStorage.getItem('token')
            }
        })
        getData()
    }

    function handleDelete(ad, e){
        e.preventDefault()
        axios.delete(`${process.env.REACT_APP_BASEURL}/admin/delete/event/${ad.id}`, {
            headers : {
                'token' : localStorage.getItem('token')
            }
        })
        getData()
    }

    function handleChange(e) {
        let data = {...newEvent}
        data[e.target.name]= e.target.value; 
        setNewEvent(data);
      }

  return (
    <div style={{paddingTop:'5rem'}}>
         <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{textAlign:"center"}}>
        <span style={{fontWeight:"bolder", fontSize:"1.5rem"}}>Add New Event</span>
            <div className='containerAddUser'   >
            <TextField style={{width:"100%"}} id="outlined-basic" label="Name" variant="outlined" name='name' onChange={handleChange}/>
            </div>
            <div className='containerAddUser'>
                <a>Start Date : </a>
            <input type='date' name='start_date' onChange={handleChange}/>
            </div>
            <div className='containerAddUser'>
            <a>End Date : </a>
            <input type='date' name='end_date' onChange={handleChange}/>
            </div>
            <div className='containerAddUser'>
            <TextField style={{width:"100%"}} id="outlined-basic" label="Discount" variant="outlined" name='discount' onChange={handleChange}/>
            </div>
            <div className='containerAddUser'>
                <FormControl fullWidth>
                    <InputLabel>Discount Type</InputLabel>
                        <Select onChange={handleChange} name='discountType'>
                            <MenuItem value='amount'>Amount</MenuItem>
                            <MenuItem value='percentage'>Percentage</MenuItem>
                        </Select>
                </FormControl>
            </div>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
                Submit
              </Button>
        </Box>
      </Modal>
        <button type="button"  className='addUserBtn'  onClick={handleOpen}>Add New Event</button>
            <Paper>
        <Table >
       
            <TableRow >
                <TableCell style={{fontWeight:"bold"}} className='tid'>No</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Name</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Start</TableCell>
                <TableCell style={{fontWeight:"bold"}}>End</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Discount</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Discount Type</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Action</TableCell>
            </TableRow>
        
           
            <TableBody>
                {event?.map((ad)=>{
                    return (
                        <>
                            <TableRow>
                                <TableCell >{ad.id}</TableCell>
                                <TableCell >{ad.name}</TableCell>
                                <TableCell >{ad.start_date}</TableCell>
                                <TableCell >{ad.end_date}</TableCell>
                                <TableCell >{ad.discount}</TableCell>
                                <TableCell >{ad.discountType}</TableCell>
                                <TableCell ><button>Edit</button> <button onClick={(e)=>handleDelete(ad,e)}>Delete</button></TableCell>
                            </TableRow>
                        </>
                    )
                })}
            </TableBody>
           
        </Table>
        </Paper>
    </div>
  )
}

export default Event