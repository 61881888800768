import React,{useState,useEffect} from 'react'
import { insertData, insertPage, insertLastPage, insertTotalPage, fetchStatus,insertLastCurrentPage, insertDataExport, insertLastTotalPage,insertDataFlush} from '../../action/paymentTable'
import {changeProgress,showBar} from '../../action/progressBar'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import SearchingField from '../../component/sortingfield'
import Sorting from '../../component/sorting'
import Utils from '../../utils'
import dayjs from 'dayjs'
import { ExportReactCSV } from '../../component/export'
import bmsIdColumn from '../../component/bmsId'
// import DatePayment from  './component/date'

function PaymentTable (props) {
    const [dataDisplayed,setDataDisplayed]=useState([])
    const [loading,setLoading]=useState(true)
    const [canSearch,setCanSearch]=useState(true)
    const [dataPaymentDisplayedSearch,setdataPaymentDisplayedSearch]=useState([])
    const [searchMode,setSearchMode]=useState(false)
    const [sort, setSort] = useState({whereClause:'',searchValue:''})
    const [dataExportPayment, setDataExportPayment]= useState([])
    // const [datePayment,setDatePayment] = useState({endDate:new Date(), startDate:new Date()}) 

    useEffect(()=>{setPage()},[])
    useEffect(()=>{setDisplayedData()},[props.currentPage,searchMode])
    useEffect (()=>{setStatus()},[])
    useEffect (()=>{setExportData()},[props.exportingData])
    
    const setPage=async (page)=>{
        if(!searchMode){
            if (typeof page !== "number") {
              if (!props.data[props.limit * parseInt(props.currentPage) - 1]) await fetch(1)
              setLoading(false);
            } 
            else {
              if (props.data.filter(item => item.page == page).length ==0) return await fetch(page)
              props.insertPage(page);
            }
          }
    }

    const tableSpec=[
        {Title:'No',obj:['index'],value:'index',shown:true,type:'data'},
        {Title:'id',obj:['id'],value:'pulsaId',shown:false,type:'data'},
        {Title:'Data Type',obj:['dataType'],value:'dataType',shown:false,type:'data'},
        {Title:'Invoice Id',obj:['invoiceId'],value:'invoiceId',shown:true,type:'data'},
        {Title:'Battery Id',obj:['trip','batteryId'],value:'id',shown:false,type:'data'},
        {Title:'Address',obj:['user','address1'],value:'address1',shown:false,type:'data'},
        {Title:'Name',obj:['user','name'],value:'name',shown:true,type:'data'},
        {Title:'Phone Number',obj:['user','phoneNumber'],value:'phoneNumber',shown:true,type:'data'},
        {Title:'Bms Id',obj:['trip','battgen2','x01','bmsId'],value:'BMSId',shown:false,type:'data'},
        {Title:'BMS Id',shown:true,type:'action',component:bmsIdColumn, data:props},
        {Title:'Amount (IDR)',obj:['amount'],value:'amount',shown:true,type:'data'},
        {Title:'Payment Type',obj:['paymentType'],value:'paymentType',shown:true,type:'data'},
        {Title:'Payment Status',obj:['paymentStatus'],value:'paymentStatus',shown:true,type:'data'},
        {Title:'Date',obj:['createdAt'],value:'createdAt',shown:true,type:'data'},
      ]
    

    const fetch=async (page)=>{
        try {
            const response = await Utils.Axios(props,'GET',`/cs/payment/all?page=${page}&limit=${props.limit}&order=DESC`,true).catch(e=>{throw e})
            props.insertData(dataBuilder(response.data,props.limit,'all'))
            props.insertPage(response.data.page)
            props.insertTotalPage(response.data.totalPages)
        } catch (error) {
            alert(error.message)
        }
    }
    
    const fetchSearch = async()=>{
        setCanSearch(false)
        try {
          const temp = props.data.filter(item=>item.dataType!=='search')
          props.insertDataFlush(temp)
          const response = await Utils.Axios(props, 'POST',`/cs/payment/searchbyuser`,true,{...sort}).catch(e=>{throw e})
          if(!searchMode)props.setLastCurrentPagePayment(props.currentPage)
          if(!searchMode)props.setLastTotalPagesPayment(props.totalPages)
          props.insertData(dataBuilderSearch(response.data,'search'))
          props.insertPage(response.data.page)
          props.insertTotalPage(response.data.totalPages)
          setSearchMode(true)
          
        }
        catch(e){alert(e)}
        finally{setCanSearch(true)}
    }
    
    const setDisplayedData=()=>{
        if(props.currentPage==0)return
        const filter = props.data.filter(item=>searchMode?item.page==props.currentPage&&item.dataType=='search':item.page==props.currentPage).map((item,index)=>{
          let finalData ={}
          tableSpec.forEach(element=>{
            if(element.type=='data'){
              const arr = Utils.DataConstructor(item, element.obj,index)
              Object.assign(finalData, {
                  [element.value]: arr
              })
            }
          })
          return finalData 
        })
        searchMode?setdataPaymentDisplayedSearch(filter):setDataDisplayed(filter)
      }

    const cancelSearch=(e)=>{
        e.preventDefault()
        setDataDisplayed([])
        const temp = props.data.filter(item=>item.dataType!=='search')
        props.insertDataFlush(temp)
        props.insertTotalPage(props.lastTotalPages)
        props.insertPage(1)
        setSearchMode(false)
    }

    const setStatus =  async() => {
      if (props.status!=true) await fetchExport()
    }
    
    const fetchExport = async() => {
        try {
          const response = await Utils.Axios(props,'POST', `/system/crud/read`, true, {dbname:"payment",whereClause:{paymentStatus:'paid'},
          include:[
            {dbname:'user'},
            {dbname:'trip',attributes:['batteryId'], 
              include:[
                {
                  dbname:'battgen2',
                  attributes:{exclude:['createdAt','updatedAt']},
                  include: [{
                    dbname:'x01',
                    attributes:['bmsId']
                  }]
                },
            ]}
          ]
        })
          props.insertDataExport(dataBuilderSearch(response.data))
          props.fetchStatus(true)
        }
        catch(e){alert(e)}
      }
    
      const setExportData=()=>{
        const filter = props.exportingData.map((item,index)=>{
          let finalData ={}
          tableSpec.forEach(element=>{
            if(element.type==='data'){
              const arr = Utils.DataConstructor(item, element.obj,index)
              Object.assign(finalData, {
                  [element.value]: arr
              })
            }
          })
          return finalData
          
        })
        setDataExportPayment(filter)
      }


    const handleChangePage=(data)=>{setPage(parseInt(data.selected)+1)}

    const dataBuilder=(data,limit,dataType)=> data.data.map((item,index)=>{return {...item,page:data.page,dataType,createdAt:dayjs(item.createdAt).format('DD MMMM YYYY'),index:((data.page-1)*limit)+index+1}})
    const dataBuilderSearch=(data,dataType)=> data.data.map((item,index)=>{return {...item,page:data.page,createdAt:dayjs(item.createdAt).format('DD MMMM YYYY'),dataType,index:index+1}})
      
    return (
        <div>
            <div style={{flexDirection:'row',display:'flex', marginTop:'55px'}}>
            <ExportReactCSV csvData={dataExportPayment} fileName='PaymentTable.csv'/>
                {/* <DatePayment props={props} Date={datePayment} setDate={setDatePayment}/> */}
                <Sorting sort={sort} setSort={setSort} sortBy={sortingItem}/>
                <div style={{flex:1}}>  
                  <SearchingField  props={props} canSearch={canSearch} fetchSearch={fetchSearch} setSort={setSort} sort={sort}cancelSearch={cancelSearch} searchMode={searchMode}/>
                </div>
            </div>
            <div style={{flexDirection:'row',display:'flex',marginTop:'15px', alignItems:'center'}}>
            {searchMode?<Utils.Table dataDisplayed={dataPaymentDisplayedSearch} tableSpec={tableSpec} />:<Utils.Table dataDisplayed={dataDisplayed} tableSpec={tableSpec} />}
            </div>
            {!loading&&!searchMode&&<Utils.Pagination totalPages={props.totalPages}  props={props} handleChangePageWithNumber={handleChangePage} />}
        </div>
    )
}

const sortingItem = [
    {sortBy:'Name'},
    {sortBy:'phoneNumber'}
  ]

const stateProps=(state)=>{
    return{
        progress:state.ProgressBar.progress,
        data:state.Payment.data,
        currentPage:state.Payment.page,
        totalPages:state.Payment.totalPages,
        lastPage:state.Payment.lastPage,
        limit:state.Payment.limit,
        lastCurrentPage:state.Payment.lastCurrentPage,
        lastTotalPages:state.Payment.lastTotalPages,
        exportingData:state.Payment.export,
        status:state.Payment.mode,
    }
}

const dispatchProps=(dispatch)=>{
    return{
        changeProgress:(load)=>{dispatch(changeProgress(load))},
        showBar:(bool)=>{dispatch(showBar(bool))},
        insertData:(data)=>{dispatch(insertData(data))},
        insertPage:(page)=>{dispatch(insertPage(page))},
        insertLastPage:(page)=>{dispatch(insertLastPage(page))},
        insertTotalPage:(totalPage)=>{dispatch(insertTotalPage(totalPage))},
        insertDataFlush:(value,type)=>{dispatch(insertDataFlush(value,type))},
        setLastCurrentPagePayment:(value)=>{dispatch(insertLastCurrentPage(value))},
        setLastTotalPagesPayment:(value)=>{dispatch(insertLastTotalPage(value))},
        insertDataExport:(value)=>{dispatch(insertDataExport(value))},
        fetchStatus:(value)=>{dispatch(fetchStatus(value))},
    }
}

export default withRouter(connect(stateProps,dispatchProps)(PaymentTable))