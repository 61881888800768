import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Drawer, CssBaseline, List, ListItem, ListItemText, ListItemIcon,InputLabel,FormControl,Select ,MenuItem,} from "@material-ui/core";
import { blue } from '@material-ui/core/colors'
import BuildIcon from '@material-ui/icons/Build';
import PhonelinkSetup from '@material-ui/icons/PhonelinkSetup';
import {Storefront, MoneyOff,AccountBox, VpnKey, HomeWork,BatteryChargingFull, AccountBalanceWallet, FlashOnRounded, LocalAtm, Motorcycle, Receipt,PersonAddDisabled,BatteryCharging20} from '@material-ui/icons'
import { NavLink } from "react-router-dom";
import { Header } from "./Header";
import { circularLoading } from "../action/navigation";
import { connect } from "react-redux";
import ConfirmationNumber from '@material-ui/icons/ConfirmationNumber';
import QueuePlayNext from '@material-ui/icons/QueuePlayNext';
import LocalOffer from '@material-ui/icons/LocalOffer';

function DrawerNavigator(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [currentStack,setCurrentStack]=React.useState(props.pathName)
  const [age, setAge] = React.useState('');

  useEffect(() => {if (props.pathName === "/login") setOpen(false)}, [props.pathName]);
  
  const handleDrawerToggle = () => {setOpen(!open);};
  const changeStack=(name)=>{setCurrentStack(name)}
  const handleChange = event => {
    setAge(event.target.value);
  };


  return (
    <div>
      <div className={classes.root}>
        <CssBaseline />
        {Header({ handleDrawerToggle, classes, pathName: props.pathName })}
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <div className={classes.drawerHeader} />
          <List >
          {sideBarOption.map((item) => (
              (<>
              <NavLink  exact onClick={changeStack.bind(null,item.path)} to={item.path} activeClassName="active" style={{textDecoration:'none',color:'black', fontFamily: 'Open Sans'}} key={item.name}>
                <ListItem  button key={item.name} style={{backgroundColor:currentStack===item.path?'lightgrey':'white',marginBottom:10}}>
                  <ListItemText  primary={item.name} />
                  <ListItemIcon>{<item.icon style={{ color: blue[900] }}/>}</ListItemIcon>
                </ListItem>
              </NavLink>
              </>
              )
              
            ))}
          <div style={{display:'flex', justifyContent:'flexStart', alignItems:'center',paddingLeft:'16px',paddingRight:'16px'}}>
          </div>

            <div style={{display:'flex', justifyContent:'flexStart', alignItems:'center',paddingLeft:'16px',paddingRight:'16px'}}>
                      <FormControl className={classes.formControl}>
                        <InputLabel >Battery</InputLabel>
                        <Select>
                          {
                            batteryBarOption.map((item,index) => {
                            return(
                              <NavLink  exact onClick={changeStack.bind(null,item.path)} to={item.path} activeClassName="active" style={{textDecoration:'none',color:'black', fontFamily: 'Open Sans'}} key={item.name} value={item.name}>
                                <ListItem  button key={item.name} style={{backgroundColor:currentStack===item.path?'lightgrey':'white',marginBottom:10,display:'flex'}}>
                                  <ListItemText  primary={item.name} />
                                  <ListItemIcon>{<item.icon style={{ color: blue[900] }}/>}</ListItemIcon>
                                </ListItem>
                              </NavLink>
                          )})
                          }
                        </Select>
                      </FormControl>
            </div>

              {/* <div style={{display:'flex', justifyContent:'flexStart', alignItems:'center',paddingLeft:'16px',paddingRight:'16px'}}>
                      <FormControl className={classes.formControl}>
                        <InputLabel >Promo</InputLabel>
                        <Select>
                          {
                            promoBarOption.map((item,index) => {
                            return(
                              <NavLink  exact onClick={changeStack.bind(null,item.path)} to={item.path} activeClassName="active" style={{textDecoration:'none',color:'black', fontFamily: 'Open Sans'}} key={item.name} value={item.name}>
                                <ListItem  button key={item.name} style={{backgroundColor:currentStack===item.path?'lightgrey':'white',marginBottom:10,display:'flex'}}>
                                  <ListItemText  primary={item.name} />
                                  <ListItemIcon>{<item.icon style={{ color: blue[900] }}/>}</ListItemIcon>
                                </ListItem>
                              </NavLink>
                          )})
                          }
                        </Select>
                      </FormControl>
              </div> */}
    
                <div style={{display:'flex', justifyContent:'flexStart', alignItems:'center',paddingLeft:'16px',paddingRight:'16px'}}>
                        <FormControl className={classes.formControl}>
                          <InputLabel >Service Point</InputLabel>
                          <Select>
                            {
                              serviceBarOption.map((item,index) => {
                              return(
                                <NavLink  exact onClick={changeStack.bind(null,item.path)} to={item.path} activeClassName="active" style={{textDecoration:'none',color:'black', fontFamily: 'Open Sans'}} key={item.name} value={item.name}>
                                  <ListItem  button key={item.name} style={{backgroundColor:currentStack===item.path?'lightgrey':'white',marginBottom:10,display:'flex'}}>
                                    <ListItemText  primary={item.name} />
                                    <ListItemIcon>{<item.icon style={{ color: blue[900] }}/>}</ListItemIcon>
                                  </ListItem>
                                </NavLink>
                            )})
                            }
                          </Select>
                        </FormControl>
                </div>

                  <div style={{display:'flex', justifyContent:'flexStart', alignItems:'center',paddingLeft:'16px',paddingRight:'16px'}}>
                        <FormControl className={classes.formControl}>
                          <InputLabel >Dealer</InputLabel>
                          <Select>
                            {
                              dealerBarOption.map((item,index) => {
                              return(
                                <NavLink  exact onClick={changeStack.bind(null,item.path)} to={item.path} activeClassName="active" style={{textDecoration:'none',color:'black', fontFamily: 'Open Sans'}} key={item.name} value={item.name}>
                                  <ListItem  button key={item.name} style={{backgroundColor:currentStack===item.path?'lightgrey':'white',marginBottom:10,display:'flex'}}>
                                    <ListItemText  primary={item.name} />
                                    <ListItemIcon>{<item.icon style={{ color: blue[900] }}/>}</ListItemIcon>
                                  </ListItem>
                                </NavLink>
                            )})
                            }
                          </Select>
                        </FormControl>
                  </div>

                    <div style={{display:'flex', justifyContent:'flexStart', alignItems:'center',paddingLeft:'16px',paddingRight:'16px'}}>
                          <FormControl className={classes.formControl}>
                            <InputLabel >Trade In</InputLabel>
                            <Select>
                              {
                                tradeinBarOption.map((item,index) => {
                                return(
                                  <NavLink  exact onClick={changeStack.bind(null,item.path)} to={item.path} activeClassName="active" style={{textDecoration:'none',color:'black', fontFamily: 'Open Sans'}} key={item.name} value={item.name}>
                                    <ListItem  button key={item.name} style={{backgroundColor:currentStack===item.path?'lightgrey':'white',marginBottom:10,display:'flex'}}>
                                      <ListItemText  primary={item.name} />
                                      <ListItemIcon>{<item.icon style={{ color: blue[900] }}/>}</ListItemIcon>
                                    </ListItem>
                                  </NavLink>
                              )})
                              }
                            </Select>
                          </FormControl>
                    </div>

                      <div style={{display:'flex', justifyContent:'flexStart', alignItems:'center',paddingLeft:'16px',paddingRight:'16px'}}>
                            <FormControl className={classes.formControl}>
                              <InputLabel >Appraisal</InputLabel>
                              <Select>
                                {
                                  leadsBarOption.map((item,index) => {
                                  return(
                                    <NavLink  exact onClick={changeStack.bind(null,item.path)} to={item.path} activeClassName="active" style={{textDecoration:'none',color:'black', fontFamily: 'Open Sans'}} key={item.name} value={item.name}>
                                      <ListItem  button key={item.name} style={{backgroundColor:currentStack===item.path?'lightgrey':'white',marginBottom:10,display:'flex'}}>
                                        <ListItemText  primary={item.name} />
                                        <ListItemIcon>{<item.icon style={{ color: blue[900] }}/>}</ListItemIcon>
                                      </ListItem>
                                    </NavLink>
                                )})
                                }
                              </Select>
                            </FormControl>
                      </div>


           
          </List>
        </Drawer>
        <main className={clsx(classes.content, {[classes.contentShift]: open})}>
          {props.children}
        </main>
      </div>
    </div>
  );
}

const drawerWidth = 300;

export const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#0d47a1'
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 1
  },
  formControl: {
    minWidth: 250,
    marginBottom:20,
    maxHeight:47.29
  },
  selectEmpty: {
    marginTop: theme.spacing(0.5),
  },
}));

let sideBarOption =[
  { name: "User", path: "/user" ,icon: AccountBox},
  { name: "Pending Customer", path: "/pendingcustomer" ,icon: PersonAddDisabled},
  { name: "Vehicle", path: "/vehicle", icon: Motorcycle },
  { name: "Affiliates", path: "/affiliates", icon: Receipt },
  { name: "Pending Subscription", path: "/subscription/pending", icon: MoneyOff },
  { name: "Payment", path: "/payment",icon: Receipt},
  { name: "Pre Order", path: "/order", icon: LocalAtm },
  { name: "Rent Bike", path: "/rentbike", icon: LocalAtm },
  { name: "Login", path: "/login",icon: VpnKey },

  // { name: "Command", path: "/command", icon: FlashOnRounded},
  // { name: "Pulsa", path: "/pulsa", icon: LocalAtm },
  // { name: "Denda", path: "/denda", icon: MoneyOff },
  // { name: "Rental", path: "/rental",icon: AccountBalanceWallet},
]

let batteryBarOption =[
  {name:'Battery ', path:"/battery/main", icon:BatteryChargingFull},
  {name:'Low Battery ', path:"/lowbattery", icon:BatteryCharging20},
  {name: 'Stock Battery', path:'/stockbattery',icon:HomeWork },
  {name: 'Abnormal Battery', path:'/abnormalbatteries',icon:HomeWork }
]

let promoBarOption =[
  {name:'Promo', path:'/promo/discount',icon: LocalOffer},
  { name: "Ads", path: "/promo/ads",icon: QueuePlayNext },
  { name: "Voucher  ", path: "/promo/voucher",icon: ConfirmationNumber },  
]

let dealerBarOption =[
  { name: "Dealer", path: "/dealer",icon: Storefront},  
  { name: "Event", path: "/event",icon: QueuePlayNext },
]

let serviceBarOption=[
  { name: "Service Point", path: "/swapstation",icon: BuildIcon},
  { name: "Admin Service Point", path: "/adminservicepoint",icon: PhonelinkSetup}
]

let tradeinBarOption =[
  { name: "Trade In", path: "/tradein",icon: Storefront},  
  { name: "Create Proposal", path: "/create-proposal",icon: QueuePlayNext },
]

let leadsBarOption =[
  { name: "Leads", path: "/leads",icon: AccountBox }, 
  { name: "Paket", path: "/paket",icon: QueuePlayNext },
  { name: "Rules", path: "/rules",icon: ConfirmationNumber },
]

const stateProps = state => {
  return {
    navbar: state.NavigationLoading
  };
};

const dispatchProps = dispatch => {
  return {
    circularLoading: payload => {
      dispatch(circularLoading(payload));
    }
  };
};
export default connect(stateProps, dispatchProps)(DrawerNavigator);
