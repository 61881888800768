import React,{useState,useEffect} from "react";
import {TableCell} from "@material-ui/core";
import {Edit,AssignmentInd} from '@material-ui/icons';
import {connect} from 'react-redux'
import {showBar, changeProgress} from '../../action/progressBar'
import {insertData,insertDataFlush,changePage,totalPage,fetchStatus,lastCurrentPage,lastTotalPages,insertDataExport} from '../../action/userTable/user'
import {searchMode} from '../../action/userTable/searchMode'
import {search} from '../../action/userTable/search'
import {withRouter} from 'react-router-dom'
import AddUserModal from './component/addUserModal'
import EditUserModal from './component/editUserModal'
import KtpUserModal from './component/ktpModal'
import Utils from '../../utils'
import SearchingField from '../../component/search'
import { ExportReactCSV } from '../../component/export'
import "../../css/pageList.css";
import { set } from "date-fns";

function MainUser(props) {
  const [dataUserDisplayed,setdataUserDisplayed]=useState([])
  const [dataUserDisplayedSearch,setdataUserDisplayedSearch]=useState([])
  const [searchMode,setSearchMode]=useState(false)
  const [loading,setLoading]=useState(true)
  const [canSearch,setCanSearch]=useState(true)
  const [addUserModalOpen, setAddUserModalOpen]=useState(false)
  const [editUserModalOpen, setEditUserModalOpen]=useState(false)
  const [ktpModalOpen, setKtpModalOpen]=useState(false)
  const [dataEditUser,setDataEditUser]=useState({})
  const [dataExportUser, setDataExportUser]= useState([])

  useEffect(()=>{setPage()},[])
  useEffect(()=>{setDisplayedData()},[props.currentPage,searchMode])
  useEffect (()=>{setStatus()},[])
  useEffect (()=>{setExportData()},[props.exportingData])

  const handleChangePageWithNumber=(data)=>{setPage(parseInt(data.selected)+1)}

  const setPage = async page => {
    if(!searchMode){
      if (typeof page !== "number") {
        if (!props.dataUser[props.limit * parseInt(props.currentPage) - 1]) await fetch(1)
        setLoading(false);
      } 
      else {
        if (props.dataUser.filter(item => item.page == page).length ==0) return await fetch(page)
        props.changePage(page);
      }
    }
    else{
      if (props.dataUser.filter(item => item.page === page &&item.dataType==='search').length < props.limit) return await fetchOtherSearch(page)
      props.changePage(page);
    }
  };

  const fetchOtherSearch = async(page)=>{
    setCanSearch(false)
    try {
      const response = await Utils.Axios(props, 'GET',`/cs/user/search?search=${props.search}&page=${page?page:1}&limit=20`,true).catch(e=>{throw e})
      props.insertDataUser(dataBuilder(response.data,'search'))
      props.changePage(response.data.page)
      props.totalPage(response.data.totalPages)
    }
    catch(e){alert(e.message)}
    finally{setCanSearch(true)}
  }

  const setDisplayedData=()=>{
    if(props.currentPage===0)return
    const filter = props.dataUser.filter(item=>searchMode?item.page==props.currentPage&&item.dataType==='search':item.page==props.currentPage).map((item,index)=>{
      let finalData ={}
      tableSpec.forEach(element=>{
        if(element.type==='data'){
          const arr = Utils.DataConstructor(item, element.obj,index)
          Object.assign(finalData, {
              [element.value]: arr
          })
        }
      })
      return finalData
    })
    searchMode?setdataUserDisplayedSearch(filter):setdataUserDisplayed(filter)
  }

  const cancelSearch=(e)=>{
    e.preventDefault()
    setdataUserDisplayed([])
    const temp = props.dataUser.filter(item=>item.dataType!=='search')
    props.insertDataUserFlush(temp)
    props.totalPage(props.lastTotalPages)
    props.changePage(1)
    setSearchMode(false)
  }

  const fetch=async(page)=>{
    setCanSearch(false)
    try {
      const response = await Utils.Axios(props,'GET',`/cs/user/all?page=${page}&limit=20`,true).catch(e=>{throw e})
      props.insertDataUser(dataBuilder(response.data,'all'))
      props.changePage(response.data.page)
      props.totalPage(response.data.totalPages)
    } 
    catch (error) {alert(error.message)}
    finally{setCanSearch(true)}
  }

  const fetchSearch = async(page)=>{
    setCanSearch(false)
    try {
      const temp = props.dataUser.filter(item=>item.dataType!=='search')
      props.insertDataUserFlush(temp)
      const response = await Utils.Axios(props, 'GET',`/cs/user/search?search=${props.search}&page=${page?page:1}&limit=20`,true).catch(e=>{throw e})
      if(!searchMode)props.setlastCurrentPage(props.currentPage)
      if(!searchMode)props.setlastTotalPages(props.totalPages)
      props.insertDataUser(dataBuilder(response.data,'search'))
      props.changePage(response.data.page)
      props.totalPage(response.data.totalPages)
      setSearchMode(true)
    }
    catch(e){alert(e)}
    finally{setCanSearch(true)}
  }

  const setStatus =  async() => {
    if (props.status!=true) await fetchExport()
  }

  const fetchExport = async() => {
    try {
      const response = await Utils.Axios(props,'POST', `/system/crud/read`, true, {dbname:"user", include:[{dbname:'customerType'}]})
      props.insertDataExport(dataBuilderExport(response.data))
      props.fetchStatus(true)
    }
    catch(e){alert(e)}
  }

  const setExportData=()=>{
    const filter = props.exportingData.map((item,index)=>{
      let finalData ={}
      tableSpec.forEach(element=>{
        if(element.type==='data'){
          const arr = Utils.DataConstructor(item, element.obj,index)
          Object.assign(finalData, {
              [element.value]: arr
          })
        }
      })
      return finalData
    })
    setDataExportUser(filter)
  } 

  const tableSpec=[
    // {Title:'No',obj:['index'],value:'index',shown:true,type:'index'},
    {Title:'User Id',obj:['id'],value:'id',shown:true,type:'data'},
    {Title:'Data Type',obj:['dataType'],value:'dataType',shown:false,type:'data'},
    {Title:'Name',obj:['name'],value:'name',shown:true,type:'data'},
    {Title:'Phone Number',obj:['phoneNumber'],value:'phoneNumber',shown:true,type:'data'},
    {Title:'Email',obj:['email'],value:'email',shown:true,type:'data'},
    {Title:'Address',obj:['address1'],value:'address',shown:true,type:'data'},
    {Title:'Customer Type',obj:['customerType','name'],value:'customerType',shown:true,type:'data'},
    {Title:'Status',obj:['status'],value:'status',shown:true,type:'data'},
    {shown:true,type:'action',component:editUser, openModalKtp:setKtpModalOpen, openModal:setEditUserModalOpen,userDetail:dataEditUser,setUserDetail:setDataEditUser}
  ]

  return (
    <div> 
      <div style={{flexDirection:'row',display:'flex', marginTop:'55px'}}>
        <ExportReactCSV csvData={dataExportUser} fileName='UserTable.csv' style={{marginRight:'5px'}}/>
        <button type="button" onClick={()=>{setAddUserModalOpen(true)}} className='addUserBtn'>Add New User</button>
        <div style={{flex:1, marginBottom:'10px'}}>
          <SearchingField props={props} canSearch={canSearch} fetchSearch={fetchSearch} cancelSearch={cancelSearch} searchMode={searchMode}/>  
        </div>
      </div>
      {searchMode?<Utils.Table dataDisplayed={dataUserDisplayedSearch} tableSpec={tableSpec} />:<Utils.Table dataDisplayed={dataUserDisplayed} tableSpec={tableSpec} />}
      <AddUserModal isOpen={addUserModalOpen} props={props} setOpen={setAddUserModalOpen} />
      <EditUserModal isOpen={editUserModalOpen} userDetail={dataEditUser} props={props} setOpen={setEditUserModalOpen} />
      <KtpUserModal isOpen={ktpModalOpen} userDetail={dataEditUser} props={props} setOpen={setKtpModalOpen} />
      {!loading&&!searchMode&&<Utils.Pagination props={props} handleChangePageWithNumber={handleChangePageWithNumber} />}
      {!loading&&searchMode&&<Utils.Pagination props={props} handleChangePageWithNumber={handleChangePageWithNumber} />}
    </div>
  )
}

const editUser=({row,item})=>
{
  const handlePressedEdit=()=>{
    item.setUserDetail(row)
    item.openModal(true)
  }

  const handlePressedKtp=()=>{
    item.setUserDetail(row)
    item.openModalKtp(true)
  }
  return <TableCell  align='center'style={{minWidth:'100px'}}>
  <div className='tooltip'>
    <Edit key={row.id} onClick={handlePressedEdit} style={{ cursor:'pointer',marginRight:'5px'}}/>
    <span className='tooltiptextuser'>Edit</span>
  </div>
  <div className='tooltip'>
    <AssignmentInd key={row.id} onClick={handlePressedKtp} style={{ cursor:'pointer',marginRight:'5px'}}/>
    <span className='tooltiptextuser'>KTP</span>
  </div>
  </TableCell>
}

const dataBuilder =(data,dataType)=>data.data.map(item=>{return{...item,page:data.page,dataType}})

const dataBuilderExport =(data,dataType)=>data.data.map(item=>{return{...item,page:data.page,dataType,phoneNumber:`'${item.phoneNumber}'`}})

const stateProps = state=>{
  return{
    progress:state.ProgressBar.progress,
    mode:state.SearchMode.mode,
    dataUser:state.Users.data,
    currentPage:state.Users.currentPage,
    limit:state.Users.limit,
    totalPages:state.Users.totalPages,
    lastCurrentPage:state.Users.lastCurrentPage,
    lastTotalPages:state.Users.lastTotalPages,
    search:state.SearchUser.search,
    exportingData:state.Users.export,
    status:state.Users.mode
  }
}

const dispatchProps = dispatch=>{
  return{
    changeProgress:(value)=>{dispatch(changeProgress(value))},
    searchMode: (value)=>{dispatch(searchMode(value))},
    showBar:(value)=>{dispatch(showBar(value))},
    insertDataUser:(value,type)=>{dispatch(insertData(value))},
    insertDataUserFlush:(value,type)=>{dispatch(insertDataFlush(value))},
    changePage:(value)=>{dispatch(changePage(value))},
    totalPage:(value)=>{dispatch(totalPage(value))},
    setlastCurrentPage:(value)=>{dispatch(lastCurrentPage(value))},
    setlastTotalPages:(value)=>{dispatch(lastTotalPages(value))},
    searchData:(value)=>{dispatch(search(value))},
    insertDataExport:(value)=>{dispatch(insertDataExport(value))},
    fetchStatus:(value)=>{dispatch(fetchStatus(value))},
  }
}

export default withRouter(connect(stateProps,dispatchProps)(MainUser))