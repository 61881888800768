export const insertData = payload => {
  return {
    type: "INSERT_DATA_VEHICLE",
    payload
  };
};

export const insertPage = payload => {
  return {
    type: "INSERT_PAGE_VEHICLE",
    payload
  };
};

export const insertLastPage = payload => {
  return {
    type: "INSERT_LAST_PAGE_VEHICLE",
    payload
  };
};

export const insertTotalPage = payload => {
  return {
    type: "INSERT_TOTAL_PAGE_VEHICLE",
    payload
  };
};

export const insertLastTotalPage = payload => {
  return {
    type: "LAST_TOTAL_PAGES_VEHICLE",
    payload
  };
};

export const insertLastCurrentPage = payload => {
  return {
    type: "LAST_CURRENT_PAGE_VEHICLE",
    payload
  };
};

export const insertDataFlush = payload => {
  return { 
    type: "INSERT_DATA_FLUSH_VEHICLE", 
    payload 
  }
};

export const insertDataExport = (payload)=> {
  return{type:'INSERT_DATA_EXPORT_VEHICLE', payload}
}

export const fetchStatus = payload =>{
  return {
    type:'FETCH_STATUS_VEHICLE',
    payload
  }
}
