export const DataConstructor = (obj,parameter)=>{
  try{
    parameter.forEach(item=>{
      obj=obj[item]
    })
    return obj
  }
  catch{
    return '-'
  }
}