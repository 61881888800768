import React from "react";
import { Modal ,Backdrop, Fade, } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {Button} from '@material-ui/core'
import Axios from "../../../../utils/axios"
import "../../../../css/addUser.css"

function DeleteDealerBattery(props) {
  const classes = useStyles();
  const deleteSubmit = async()=>{
    try {
      const response = await Axios(props.props,'POST','/system/crud/delete',true,{dbname:"batteryRemark",id:props.idStockBattery.stockBatteryId}).catch(e=>{throw e})
      alert(response.data.message)
      window.location.reload(true)
    } catch (error) {
      alert(error.message)
    }
  }

  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.isOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={props.isOpen}>
          <div className={classes.paper}>
            <div >
              <span className="titleModal">Delete Dealer</span>
            </div>
            <div>
                <p style={{display:'flex', justifyContent:'center', fontSize:'18px'}}>Are you sure ?</p>
            </div>
            <div className="buttonContainer" >
              <Button variant="contained" color="secondary" onClick={deleteSubmit}>
                Yes
              </Button>
              <Button variant="contained" color="primary" onClick={handleClose}>
                No
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: "100%"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));

export default DeleteDealerBattery