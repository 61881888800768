import React,{useState,useEffect} from 'react'
import {connect} from 'react-redux'
import { insertData, insertPage } from '../../../../action/batteryTable/lowbattery'
import {changeProgress,showBar} from '../../../../action/progressBar'
import Utils from '../../../../utils'
import '../../../../css/pageList.css' 
import {withRouter} from 'react-router-dom'
import bmsIdColumn from '../../../../component/bmsId'


const MainRental =(props)=>{
    const [dataDisplayed,setDataDisplayed]=useState([])
    const [loading,setLoading]=useState(true)
    
    useEffect(()=>{setFetch()},[])
    useEffect(()=>{setData()},[props.currentPage])
    
    const setFetch=async ()=>{
        if(props.currentPage!==1){
            await fetch()
            setLoading(false);
          }
    } 

    const fetch=async ()=>{
        try {
            const response = await Utils.Axios(props,'GET',`/cs/battery/low/all`,true).catch(e=>{throw e})
            props.insertData(dataBuilder(response.data))
            props.insertPage(1)
        } catch (error) {
            alert(error.message)
        }
    }


    const tableSpec=[
        {Title:'No',obj:['index'],value:'index',shown:true,type:'data'},
        {Title:'id',obj:['id'],value:'lowBatteryId',shown:false,type:'data'},
        {Title:'BMS ID',obj:['bmsId'],value:'BMSId',shown:false,type:'data'},
        {Title:'BMS ID',shown:true,type:'action',component:bmsIdColumn, data:props},
        {Title:'GPS ID',obj:['gpsId'],value:'gpsId',shown:true,type:'data'},
        {Title:'Battery Id',obj:['batteryId'],value:'id',shown:false,type:'data'},
        {Title:'Voltage',obj:['voltage'],value:'voltage',shown:true,type:'data'},
        {Title:'SOC',obj:['soc'],value:'soc',shown:true,type:'data'},
      ]

      const setData=()=>{
        if(props.currentPage===0)return
        const filter = props.data.map((item,index)=>{
          let finalData ={}
          tableSpec.forEach(element=>{
            if(element.type==='data'){
              const arr = Utils.DataConstructor(item, element.obj,index)
              Object.assign(finalData, {
                  [element.value]: arr
              })
            }
          })
          return finalData 
        })
        setDataDisplayed(filter)
      }

    return(
        <div>
            <div style={{flexDirection:'row',display:'flex',marginTop:'60px', alignItems:'center'}}>
                <Utils.Table dataDisplayed={dataDisplayed} tableSpec={tableSpec}/>
            </div>
    </div>)
}

const dataBuilder=(data)=> data.data.map((item,index)=>{return {...item,index:index+1}})

const stateProps=(state)=>{
    return{
        progress:state.ProgressBar.progress,
        data:state.LowBattery.data,
        currentPage:state.LowBattery.page,
    }
}

const dispatchProps=(dispatch)=>{
    return{
        changeProgress:(load)=>{dispatch(changeProgress(load))},
        showBar:(bool)=>{dispatch(showBar(bool))},
        insertData:(data)=>{dispatch(insertData(data))},
        insertPage:(page)=>{dispatch(insertPage(page))},
    }
}

export default withRouter(connect(stateProps,dispatchProps)(MainRental))