import React, {useState, useEffect} from 'react'
import {insertVehicleDealer as insertDataVehicleDealer,insertDataFlush,changePageVehicle,lastTotalPagesVehicle,lastCurrentPageVehicle,totalPageVehicle} from '../../../../action/dealerTable/vehicleDealer'
import { TableCell } from '@material-ui/core';
import {changeProgress,showBar} from '../../../../action/progressBar'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import { Delete, Edit } from '@material-ui/icons';
import SearchingField from '../../../../component/sortingfield'
import Sorting from '../../../../component/sorting'
import Utils from '../../../../utils'
import EditVehicleDealerModal from './editVehicleDealer'
import DeleteDealerModal from '../batteryDealer/modalDeleteDealer'
import ExportDataVehicleDealer from './exportData'
import AddVehicleDealerModal from './addVehicleDealer'
import { totalPageBattery } from '../../../../action/dealerTable/batteryDealer';

function TableVehicleDealer(props) {
    const [dataVehicleDealer, setdataVehicleDealer] = useState([])
    const [loading,setLoading]=useState(true)
    const [canSearch,setCanSearch]=useState(true)
    const [deleteDealerModalOpen,setDeleteDealerModalOpen]=useState(false)
    const [editVehicleDealerModalOpen, setEditVehicleDealerModalOpen]=useState(false)
    const [idDealerDeleted, setIdDealerDeleted]=useState({id:''})
    const [idDealerEdited, setIdDealerEdited]=useState({id:''})
    const [batteryOrVehicle, setDealerBatteryOrVehicle]=useState({status:''})
    const [addVehicleDealerModalOpen, setAddVehicleDealerModalOpen]=useState(false)
    const [dataVehicleDisplayedSearch,setdataVehicleDisplayedSearch]=useState([])
    const [searchMode,setSearchMode]=useState(false)
    const [sort, setSort] = useState({whereClause:'',searchValue:''})

    useEffect(()=>{setPage()},[])
    useEffect(()=>{setDisplayedData()},[props.currentPage,searchMode])

    const setPage=async (page)=>{
        if(!searchMode){
            if (typeof page !== "number") {
              if (!props.data[props.limit * parseInt(props.currentPage) - 1]) await fetch(1)
              setLoading(false);
            } 
            else {
              if (props.data.filter(item => item.page == page).length ==0) return await fetch(page)
              props.insertPage(page);
            }
          }
    } 

    const fetch=async (page)=>{
        try {
            const response = await Utils.Axios(props,'GET',`/cs/vehicle/alldealervehicle?page=${page}&limit=${props.limit}`,true).catch(e=>{throw e})
            props.insertData(dataBuilder(response.data,props.limit,'all'))
            props.insertPage(response.data.page)
            props.insertTotalPage(response.data.totalPages)
        } catch (error) {
            alert(error.message)
        }
    }

    const fetchSearch = async()=>{
        setCanSearch(false)
        try {
          const temp = props.data.filter(item=>item.dataType!=='search')
          props.insertDataFlush(temp)
          const response = await Utils.Axios(props, 'POST',`/cs/vehicle/searchbydealer`,true,{...sort}).catch(e=>{throw e})
          if(!searchMode)props.setLastCurrentPageVehicle(props.currentPage)
          if(!searchMode)props.setLastTotalPagesVehicle(props.totalPages)
          props.insertData(dataBuilderSearch(response.data,'search'))
          props.insertPage(response.data.page)
          props.insertTotalPage(response.data.totalPages)
          setSearchMode(true)
        }
        catch(e){alert(e)}
        finally{setCanSearch(true)}
    }
    const setDisplayedData=()=>{
        if(props.currentPage==0)return
        const filter = props.data.filter(item=>searchMode?item.page==props.currentPage&&item.dataType=='search':item.page==props.currentPage).map((item,index)=>{
          let finalData ={}
          tableSpec.forEach(element=>{
            if(element.type=='data'){
              const arr = Utils.DataConstructor(item, element.obj,index)
              Object.assign(finalData, {
                  [element.value]: arr
              })
            }
          })
          return finalData 
        })
        searchMode?setdataVehicleDisplayedSearch(filter):setdataVehicleDealer(filter)
      }
    const cancelSearch=(e)=>{
        e.preventDefault()
        const temp = props.data.filter(item=>item.dataType!=='search')
        props.insertDataFlush(temp)
        props.insertTotalPage(props.lastTotalPages)
        props.insertPage(1)
        setSearchMode(false)
    }

    const tableSpec=[
      {Title:'No',obj:['index'],value:'index',shown:true,type:'index'},
      {Title:'Id',obj:['id'],value:'dealerId',shown:false,type:'data'},
      {Title:'Dealer Name',obj:['user', 'name'],value:'delaerName',shown:true,type:'data'},
      {Title:'Dealer Phone Number',obj:['user', 'phoneNumber'],value:'dealerPhoneNumber',shown:true,type:'data'},
      {Title:'Customer Type',obj:['user', 'customerType', 'name'],value:'customerTypeId',shown:true,type:'data'},
      {Title:'Rangka Code',obj:['vehicle','rangka'],value:'rangka',shown:true,type:'data'},
      {Title:'Dinamo Code', obj:['vehicle','dinamo'], value:'dinamo',type:'data',shown:true},
      {shown:true,type:'action',component:EditDeleteButton, openModalDeleteDealer:setDeleteDealerModalOpen, setDeleteDealer:setIdDealerDeleted, 
      idDealer:idDealerDeleted, status:null, setBatteryOrVehicle:setDealerBatteryOrVehicle, openModalEditDealer:setEditVehicleDealerModalOpen
    , idDealerEdit:idDealerEdited, setEditDealer:setIdDealerEdited},
      ]

    const handleChangePage=(data)=>{setPage(parseInt(data.selected)+1)}

    const dataBuilder=(data,limit,dataType)=> data.data.map((item,index)=>{return {...item,page:data.page,dataType,index:((data.page-1)*limit)+index+1}})
    const dataBuilderSearch=(data,dataType)=> data.data.map((item,index)=>{return {...item,page:data.page,dataType,index:index+1}})
    
    return(
        <div>
            <div style={{flexDirection:'row',display:'flex'}}>
                <ExportDataVehicleDealer/>
                <button type="button" onClick={()=>{setAddVehicleDealerModalOpen(true)}} className='addUserBtn'>
                Add New Dealer
                </button>
                <Sorting sort={sort} setSort={setSort} sortBy={sortingItem}/>
                <div style={{flex:1}}>  
                <SearchingField  props={props} canSearch={canSearch} fetchSearch={fetchSearch} setSort={setSort} sort={sort}cancelSearch={cancelSearch} searchMode={searchMode}/>
                </div>
            </div>
            <div style={{flexDirection:'row',display:'flex',marginTop:'15px', alignItems:'center'}}>
            {searchMode?<Utils.Table dataDisplayed={dataVehicleDisplayedSearch} tableSpec={tableSpec} />:<Utils.Table dataDisplayed={dataVehicleDealer} tableSpec={tableSpec} />}
            </div>
            {!loading&&!searchMode&&<Utils.Pagination totalPages={props.totalPages}  props={props} handleChangePageWithNumber={handleChangePage} />}
            <EditVehicleDealerModal isOpen={editVehicleDealerModalOpen} props={props} setOpen={setEditVehicleDealerModalOpen} idDealerEdit={idDealerEdited} status={batteryOrVehicle}/>
            <DeleteDealerModal isOpen={deleteDealerModalOpen} props={props} setOpen={setDeleteDealerModalOpen} idDealer={idDealerDeleted} status={batteryOrVehicle}/>
            <AddVehicleDealerModal isOpen={addVehicleDealerModalOpen} props={props} setOpen={setAddVehicleDealerModalOpen} />
        </div>
    )    
}

const sortingItem = [
  {sortBy:'Name'},
  {sortBy:'phoneNumber'}
]


  const EditDeleteButton=({row,item})=>
  { 
    const handlePressedDelete=()=>{
    item.openModalDeleteDealer(true)
    item.setDeleteDealer(row)
    item.setBatteryOrVehicle(row.status)
    }
  
    const handlPressedeEdit=()=>{
      item.setEditDealer(row)
      item.openModalEditDealer(true)
      item.setBatteryOrVehicle(row.status)
    }
    return <TableCell  align='center'>
      <div className='tooltip'>
        <Edit  style={{ cursor:'pointer' ,marginRight:'5px'}} key={row.index} onClick={handlPressedeEdit}/>
        <span className='tooltiptextuser'>edit</span>
      </div>
      <div className='tooltip'>
        <Delete style={{ cursor:'pointer' ,marginRight:'5px'}} key={row.index} onClick={handlePressedDelete}/>
      <span className='tooltiptextuser'>Delete</span>
      </div>
    </TableCell>
  }
  const mapStateToProps=(state)=>{
    return{
        progress:state.ProgressBar.progress,
        data:state.VehicleDealer.data,
        currentPage:state.VehicleDealer.page,
        totalPages:state.VehicleDealer.totalPages,
        lastPage:state.VehicleDealer.lastPage,
        limit:state.VehicleDealer.limit,
        lastCurrentPage:state.VehicleDealer.currentPage,
        lastTotalPages:state.VehicleDealer.lastTotalPages,
    }
}

const mapDispatchToProps=(dispatch)=>{
    return{
        changeProgress:(load)=>{dispatch(changeProgress(load))},
        showBar:(bool)=>{dispatch(showBar(bool))},
        insertData:(data)=>{dispatch(insertDataVehicleDealer(data))},
        insertPage:(page)=>{dispatch(changePageVehicle(page))},
        insertTotalPage:(totalPage)=>{dispatch(totalPageVehicle(totalPage))},
        insertDataFlush:(value,type)=>{dispatch(insertDataFlush(value,type))},
        setLastCurrentPageVehicle:(value)=>{dispatch(lastCurrentPageVehicle(value))},
        setLastTotalPagesVehicle:(value)=>{dispatch(lastTotalPagesVehicle(value))},
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(TableVehicleDealer))