import React from "react";
import { Modal ,Backdrop, Fade, } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {Button} from '@material-ui/core'
import Axios from "../../../utils/axios"
import {connect} from 'react-redux'
import {changeProgress,showBar} from '../../../action/progressBar'
import {withRouter} from 'react-router-dom'
import "../../../css/addUser.css";

function ModalDeleteKtp(props) {
  const classes = useStyles();
  const deleteSubmit = async()=>{
    try {
      const response = await Axios(props, "GET", `/admin/deletephoto/${props.idEdit.userId}`,true).catch(e => {throw e})
      // window.location.reload(true)
      props.setOpen(false)
      alert('Success')
    } catch (error) {
      alert(error.message)
    }
  }

  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.isOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={props.isOpen}>
          <div className={classes.paper}>
            <div className="titleModal">
              <span>Delete KTP</span>
            </div>
            <div>
                <p style={{display:'flex', justifyContent:'center', fontSize:'18px'}}>Are you sure ?</p>
            </div>
            <div className="buttonContainer" >
              <Button variant="contained" color="secondary" onClick={deleteSubmit}>
                Yes
              </Button>
              <Button variant="contained" color="primary" onClick={handleClose}>
                No
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: "100%"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));

const mapStateToProps = state=>{
  return{
    progress:state.ProgressBar.progress,
  }
}

const mapDispatchToProps = dispatch=>{
  return{
    changeProgress:(value)=>{dispatch(changeProgress(value))},
    showBar:(value)=>{dispatch(showBar(value))},
  }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(ModalDeleteKtp))