export const insertData = (payload) =>{
    return {
      type:'INSERT_DATA_PENDING_CUSTOMER',
      payload
    }
  }

export const fetchStatus = payload =>{
    return {
      type:'FETCH_STATUS',
      payload
    }
  }
  
 