import React from "react";
import { addCustomerType } from '../../../action/userTable/addUser'
import { Modal ,Backdrop, Fade, } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import { connect } from 'react-redux'
import "../../../css/addUser.css";

function AddUserModal(props) {
  const classes = useStyles();
  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.isOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={props.isOpen} style={{width:'850px'}}>
          <div className={classes.paper}>
            <div className="titleModal">
                <span>{`Identity Card ${props.userDetail.name}`} </span>
            </div>
            <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={12} style={{
            backgroundImage:`url(https://ecgobikeid.com/admin/photoid/${props.userDetail.id})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            }}/>
            </Grid>
            </div>
        </Fade>
      </Modal>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  root: {
    height: '90vh',
    Width:'100%'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  }
}));

const stateProps = state =>{
  return {
    customertype:state.AddUser.customerTypes
  }
}

const dispatchProps = dispatch => {
  return {
    addCustomerType:(value)=>{
      dispatch(addCustomerType(value))
    }
  }
}

export default connect(stateProps,dispatchProps)(AddUserModal)
