import React from 'react'
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
  } from "react-google-maps";
  import { withRouter } from "react-router-dom";
  
  function GoogleMapGps(props) {
    const data = { ...props.location.state };
    return (
      <div style={{ marginTop: "70px" }}>
         <MapWithAMarker
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCoApC-iSNhQlj-ci8738ZlOWmevybgA9E&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `600px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        props={props}
        />
      </div>
    );
  }

  const MapWithAMarker = withScriptjs(withGoogleMap(props =>{
    const data = { ...props.props.location.state };
    return(
    <GoogleMap
      defaultZoom={16}
      defaultCenter={{ lat: parseInt(data.latitude), lng: parseInt(data.longitude) }}
    >
      <Marker
        position={{ lat: parseInt(data.latitude), lng: parseInt(data.longitude) }}
      />
    </GoogleMap>
    )
  }));
  
  export default withRouter(GoogleMapGps);