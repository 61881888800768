import React ,{useState,useEffect}from 'react'
import { Box, AppBar, Tabs, Tab, Typography, TableCell } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import BmsTable from './component/bmsTable'
import GpsTable from  './component/gpsTable'
import Xa2Table from './component/xa2Table'
import RechargeTable from './component/rechargeTable'
import '../../css/pageList.css'

function DetailBattery (props){
    const [value, setValue] = React.useState(0);
    const data = { ...props.location.state };
    const classes = useStyles();
    const theme = useTheme();
    const handleChangeTab = (event, tabValue) => {
        setValue(tabValue);
      };
    

    return(
        <div className={classes.root}>
            <AppBar position="static" color="default" style={{marginTop:'70px'}}>
            <h2 style={{marginLeft:'27px'}}>{data.batteryNo?(`battery number: ${data.batteryNo}`):(`BMS ID : ${data.BMSId}`)}</h2>
            <Tabs
                value={value}
                onChange={handleChangeTab}
                indicatorColor="primary"
                style={{color:'black'}}
                variant="fullWidth"
                aria-label="full width tabs example"
            >
                <Tab label="BMS" {...a11yProps(0)} style={{fontFamily:'Open'}}/>
                <Tab label="GPS" {...a11yProps(1)} style={{fontFamily:'Open'}}/>
                <Tab label="XA2" {...a11yProps(2)} style={{fontFamily:'Open'}}/>
                <Tab label="Recharge" {...a11yProps(3)} style={{fontFamily:'Open'}}/>
            </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} dir={theme.direction}>
                <BmsTable />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
                <GpsTable/>
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <Xa2Table/>
            </TabPanel>
            <TabPanel value={value} index={3} dir={theme.direction}>
              <RechargeTable/>
            </TabPanel>
        </div>
    )
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography component="div" role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`}
        {...other}
        >
        <Box p={2}>{children}</Box>
      </Typography>
    );
  }
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  
  const useStyles = makeStyles(theme => ({
    root: {
      backgroundColor: theme.palette.background.paper,
      width: '100%',
    },
  }));

  export default withRouter(DetailBattery)