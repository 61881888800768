import React, {useState,useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Utils from '../../utils'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {showBar, changeProgress} from '../../action/progressBar'
import {insertData,fetchStatus} from '../../action/pendingCustomer'

function PendingCustomer(props) {
const classes = useStyles();
const [dataDisplayed,setDataDisplayed]= useState([])

useEffect (()=>{setStatus()},[])
useEffect (()=>{setData()},[props.data])

const handleShowDetail = (card,index) =>{
  props.history.push({pathname:'/pendingcustomerdetail', state:{...card,index}})
}
const fetch = async() => {
    try {
      const response = await Utils.Axios(props,'GET',`/internal/user/pendingcustomer`,true).catch(e=>{throw e})
      props.insertData(response.data.data)
    } 
    catch (error) {alert(error.message)}
    }

const setStatus =  async() => {
  if (props.status!=true) await fetch()
}

const setData= () =>{
  setDataDisplayed(props.data)
  props.fetchStatus(true)
}
    return (
        <React.Fragment>
        <CssBaseline />
        <main>
            <Container className={classes.cardGrid} maxWidth="md">
            <Grid container spacing={4}>
                {dataDisplayed.map((card,index) => {
                  return(
                <Grid item key={card.id} xs={12} sm={6} md={4}>
                    <Card className={classes.card}>
                    <CardMedia
                        className={classes.cardMedia}
                        image={`https://source.unsplash.com/user/erondu?count=${index}`}
                        title={card.name?card.name:'-'}
                    />
                    <CardContent className={classes.cardContent}>
                        <Typography gutterBottom variant="h5" component="h2">
                        {card.name?card.name:'-'}
                        </Typography>
                        <Typography style={{fontSize:'12px'}} component="h4">
                        {`Phone Number : ${card.phoneNumber}`}
                        </Typography>
                    </CardContent>
                    <CardActions className={classes.cardContent}>
                        <Button onClick={()=> handleShowDetail(card,index)} size="small" color="primary">
                        More Detail
                        </Button>
                    </CardActions>
                    </Card>
                </Grid>
                )})}
            </Grid>
            </Container>
        </main>

        </React.Fragment>
    );
}

const useStyles = makeStyles(theme => ({
    icon: {
      marginRight: theme.spacing(2),
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      borderBottom: '3px solid black' 
    },
    cardMedia: {
      paddingTop: '56.25%', // 16:9
    },
    cardContent: {
      flexGrow: 1,
      backgroundColor: '#FFE4E1'
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
  }));
  

  const mapStateToProps = state=>{
    return{
      progress:state.ProgressBar.progress,
      data:state.PendingCustomer.data,
      status:state.PendingCustomer.mode
    }
}

const mapDispatchToProps = dispatch=>{
    return{
      changeProgress:(value)=>{dispatch(changeProgress(value))},
      showBar:(value)=>{dispatch(showBar(value))},
      insertData:(value)=>{dispatch(insertData(value))},
      fetchStatus:(value)=>{dispatch(fetchStatus(value))},
    }
}
  
export default  withRouter(connect(mapStateToProps,mapDispatchToProps)(PendingCustomer));