import React, { useState, useEffect } from "react";
import BatteryModal from './modalBatteryDealer'
import AxiosAsli from 'axios'
import Axios from "../../../../utils/axios";

function EditBatteryDealerModal(props) {
  const [batteryDealerDetail, setBatteryDealerDetail] = useState({id:'',userId:"",batteryId:""});
  const [userSuggestions,setUserSuggestions]=useState([]);
  const [placeholderPhoneNumber]=useState({name:'Phone Number'});
  const [placeholderBmsNumber]=useState({name:'Bms Number'});
  const [batterySuggestions, setBatterySuggestions]= useState([]);
  const [searchValuePhoneNumber,setSearchValuePhoneNumber]= useState('');
  const [searchValueBmsNumber,setSearchValueBmsNumber]= useState('');

  useEffect(()=>{if(searchValuePhoneNumber!=='')findUser()},[searchValuePhoneNumber])
  useEffect(()=>{if(searchValueBmsNumber!=='')findBattery()},[searchValueBmsNumber])
  useEffect(()=>{setBatteryDealerDetail({...batteryDealerDetail,id:props.idDealerEdit.dealerId})},[props.isOpen])

  let findUserTimeout =null

  const findUser = async () => {
    if(findUserTimeout)clearTimeout(findUserTimeout)
    if(searchValuePhoneNumber.split("").length>3){
      findUserTimeout= setTimeout(async ()=>{
        try {
          const response = await AxiosAsli.get(`${process.env.REACT_APP_BASEURL}/cs/user/search?filterBy=phoneNumber&search=${searchValuePhoneNumber}&page=${1}&limit=5`,{headers:{token:localStorage.token}}).catch(e => {throw e});
          setUserSuggestions(response.data.data)
        } catch (e) {}
      },1000)
    }
  };

  let findBatteryTimeout =null

  const findBattery =async () => {
    if(findBatteryTimeout)clearTimeout(findBatteryTimeout)
    if(searchValueBmsNumber.split("").length>3){
      findBatteryTimeout= setTimeout(async ()=>{
        try {
          const response = await AxiosAsli.get(`${process.env.REACT_APP_BASEURL}/cs/battery/downshift?filterBy=bmsId&search=${searchValueBmsNumber}&page=${1}&limit=5`,{headers:{token:localStorage.token}}).catch(e => {throw e});
          setBatterySuggestions(response.data.data)
        } catch (e) {}
      },1000)
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      const response = await Axios(props.props,"PUT","/cs/battery/updatebatterydealer",true,batteryDealerDetail).catch(e => {throw e});
      alert(response.data.message);
      window.location.reload(true);
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <div>
      <BatteryModal 
      batteryDealerDetail={batteryDealerDetail}
      setSearchValuePhoneNumber={setSearchValuePhoneNumber}
      searchValuePhoneNumber={searchValuePhoneNumber}
      userSuggestions={userSuggestions}
      placeholderPhoneNumber={placeholderPhoneNumber}
      setBatteryDealerDetail={setBatteryDealerDetail}
      setSearchValueBmsNumber={setSearchValueBmsNumber}
      searchValueBmsNumber={searchValueBmsNumber}
      batterySuggestions={batterySuggestions}
      placeholderBmsNumber={placeholderBmsNumber}
      handleSubmit={handleSubmit}
      isOpen={props.isOpen}
      setOpen={props.setOpen}
      idDealer={props.idDealerEdit}
      />
    </div>
  );
}

export default EditBatteryDealerModal;