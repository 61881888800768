import React from "react";
import InputField from './downshiftVehicleDealer'
import { makeStyles } from "@material-ui/core/styles";
import { Button, Modal, Backdrop, Fade } from "@material-ui/core";
import "../../../../css/addUser.css";

function VehicleModal (props){
    const handleClose = () => {props.setOpen(false);};
    const classes = useStyles();
    const classesDownshift = useStylesDownShift();

    return(
        <div>
            <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={props.isOpen}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{timeout: 500}}
            >
            <Fade in={props.isOpen}>
                <div className={classes.paper}>
                    <div className="titleModal">
                    <span>{props.idDealer!=null?('Editing Dealer Data'):('Add New Vehicle Dealer')}</span>
                    </div>
                    <div >
                        <InputField  
                        find={'phoneNumber'} 
                        stateKey={'userId'} 
                        classesDownshift={classesDownshift} 
                        vehicleDealerDetail={props.vehicleDealer} 
                        setSearchValue={props.setSearchPhoneNumber} 
                        searchValue={props.searchPhoneNumber} 
                        suggestions={props.user}  
                        placeholder={props.placeholderPhone} 
                        setVehicleDealerDetail={props.setVehicleDealer}
                        findParameter={'id'}
                        phoneNumberValue={props.dataDealerPhoneNumber}
                        />
                        <InputField  
                        find={'rangka'} 
                        stateKey={'vehicleId'} 
                        classesDownshift={classesDownshift} 
                        vehicleDealerDetail={props.vehicleDealer} 
                        setSearchValue={props.setSearchKodeRangka} 
                        searchValue={props.searchKodeRangka} 
                        suggestions={props.vehicle}  
                        placeholder={props.placeholderRangka} 
                        setVehicleDealerDetail={props.setVehicleDealer}
                        findParameter={'id'}
                        />
                    </div>
                    <div className="buttonContainer">
                        <Button variant="contained"color="primary"onClick={props.handleSubmit}>
                            Submit
                        </Button>
                    </div>
                </div>
            </Fade>
            </Modal>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: "100%"
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3)
    }
  }));
  
  const useStylesDownShift = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      height: 250,
    },
    container: {
      flexGrow: 1,
      position: 'relative',
      marginTop:theme.spacing(3),
    },
    paper: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing(1),
    },
    chip: {
      margin: theme.spacing(0.5, 0.25),
    },
    inputRoot: {
      flexWrap: 'wrap',
    },
    inputInput: {
      width: 'auto',
      flexGrow: 1,
    },
    divider: {
      height: theme.spacing(2),
    },
  }));

export default VehicleModal