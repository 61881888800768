import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios'
import Table from '@material-ui/core/Table';
import Modal from '@material-ui/core/Modal';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import BeatLoader from "react-spinners/BeatLoader";
import ReactPaginate from 'react-paginate';
import { Button } from '@material-ui/core';

export default function Abnormal() {

    const [sp, setSp] = useState();
    const [pageCount, setpageCount] = useState()
    const [currentPage , setCurrentPage] = useState(1)


    function getData (currentPage=1) {
        axios.get(`${process.env.REACT_APP_BASEURL}/newAdmin/abnormalBatt/process`, {
            headers : {
                'token' : localStorage.getItem('token')
            }})
        .then((res)=>
            {
            axios.get(`${process.env.REACT_APP_BASEURL}/newAdmin/abnormalBatt/getAbnormal/?currentPage=${currentPage}&orderBy=id&dir=desc&perPage=20`, {
                headers : {
                    'token' : localStorage.getItem('token')
                }})
                .then((res)=>{
                    setSp(res.data.data.rows)
                    setpageCount(res.data.data.count)
              
                }) 
        } )
    }

    const  handlePageClick = async (data)=>{

        let currentPage = data.selected + 1

        const getDataPerPage =  await getData(currentPage)

        setSp(getDataPerPage)
    }

    useEffect(()=>{
        getData()
    },[])

  return (
    <div style={{padding:'5rem 0 0 0 '}}>
    <Paper>
        <Table >
       
            <TableRow >
                <TableCell style={{fontWeight:"bold"}}>Batt ID</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Batt No</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Signal</TableCell>
                <TableCell style={{fontWeight:"bold"}}>A0</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Freq A0</TableCell>
                <TableCell style={{fontWeight:"bold"}}>GPS</TableCell> 
                <TableCell style={{fontWeight:"bold"}}>Charge Time</TableCell> 
                <TableCell style={{fontWeight:"bold"}}>Usage Time</TableCell> 
                <TableCell style={{fontWeight:"bold"}}>Rental Status</TableCell> 
                <TableCell style={{fontWeight:"bold"}}>Block Status</TableCell> 
                <TableCell style={{fontWeight:"bold"}}>Voltage Level</TableCell> 
                <TableCell style={{fontWeight:"bold"}}>Abnormal Voltage</TableCell> 
                <TableCell style={{fontWeight:"bold"}}>Temp Level</TableCell> 
                <TableCell style={{fontWeight:"bold"}}>Abnormal Temp</TableCell> 
            </TableRow>
        
            <TableBody>
                {sp?.map((spoint)=>{
                    return (
                        <>
                            <TableRow>
                                <TableCell >{spoint.batteryId}</TableCell>
                                <TableCell >{spoint.battgen2?.batteryNo}</TableCell>
                                <TableCell >{spoint.noSignal == false ? '✔' : 'X'}</TableCell>
                                <TableCell >{spoint.noA0 == false ? '✔' : 'X'}</TableCell>
                                <TableCell >{spoint.frequencyErrA0 == false ? '✔' : 'X'}</TableCell>
                                <TableCell >{spoint.noGps == false ? '✔' : 'X'}</TableCell>
                                <TableCell >{spoint.chargingTime}</TableCell>    
                                <TableCell >{spoint.usageTime}</TableCell>   
                                <TableCell >{spoint.rentalStatus}</TableCell>   
                                <TableCell >{spoint.blockStatus}</TableCell>   
                                <TableCell >{spoint.voltageLevel}</TableCell>   
                                <TableCell >{spoint.abnormalVoltage}</TableCell>   
                                <TableCell >{spoint.temperatureLevel}</TableCell> 
                                <TableCell >{spoint.abnormalTemperature}</TableCell> 
                            </TableRow>
                        </>
                    )
                })}
            </TableBody>
        </Table>
        </Paper>

          <ReactPaginate
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
          containerClassName={'pagination'}
          pageClassName={'pageContainer'}
          pageLinkClassName={'changePageButton'}
          activeClassName={'active'}
        />
        </div>
  )
}
