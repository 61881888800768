import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Modal, Fade, Backdrop,FormControl, InputLabel,Select, MenuItem } from "@material-ui/core";
import "../../../css/addUser.css";
import Axios from "../../../utils/axios";

function BatteryModal (props){
    const handleClose = () => {props.setOpen(false);};
    const classes = useStyles();

    const handleChange = (event) =>{
      event.preventDefault()
      props.setUpdatedData({...props.updatedData,[event.target.name]:event.target.value})
    }

    const handleSubmit = async event => {
        event.preventDefault();
        try {
          const response = await Axios(props.loadingProps,"PUT","/cs/battery/updatebatterystatus",true,props.updatedData).catch(e => {throw e});
          alert(response.data.message);
          window.location.reload(true);
        } catch (error) {
          alert(error.message);
        }
      };
      
    return(
        <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={props.isOpen}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{timeout: 500}}
        >
          <Fade in={props.isOpen}>
            <div className={classes.paper}>
              <div >
                <span className="titleModal">Edit Battery Status</span>
              </div>
              <div >
              <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name='status'
                  value={props.updatedData.status}
                  onChange={handleChange}
                >
                  {status.map(item => 
                    <MenuItem value={item.status} key={item.status}>
                    {item.status}
                    </MenuItem>
                  )}
                </Select>
        </FormControl>
              </div>
              <div className="buttonContainer">
                <Button variant="contained"color="primary"onClick={handleSubmit}>
                  Submit
                </Button>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    )
}

const status =[
  {status:'ready'},
  {status:'lent'}
]
const useStyles = makeStyles(theme => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: "100%"
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width:500
    }
  }));
  

  
export default BatteryModal;