const defaultState = {
 data:[],
 limit:20,
 currentPage:0,
 totalPages:1,
 lastCurrentPage:0,
 lastTotalPages:0,
 export:[],
 mode:false,
}

export default (state=defaultState , action) => {
  switch(action.type){
    case 'insertData' : {
      return {...state, data:[...state.data,...action.payload]}
    }
    case 'insertDataFlush':{
      return {...state, data:action.payload}
    }
    case 'changePage':{
      return{...state,currentPage:action.payload}
    }
    case 'lastCurrentPage':{
      return{...state,lastCurrentPage:action.payload}
    }
    case 'lastTotalPages':{
      return{...state,lastTotalPages:action.payload}
    }
    case 'totalPage':{
      return{...state, totalPages:action.payload}
    }
    case 'INSERT_DATA_EXPORT':{
      return{...state, export:[...state.export,...action.payload]}
    }
    case 'FETCH_STATUS_USER' : {
      return {...state, mode:action.payload}
    }
    default: return state
  }
}
