export const insertData = payload => {
  // if(type&&type==='flush') return {type:'insertDataFlush',payload}
  return {
    type: "insertData",
    payload
  };
};

export const insertDataFlush = payload => {
  return { type: "insertDataFlush", payload };
};

export const changePage = payload => {
  return { type: "changePage", payload };
};

export const lastTotalPages = payload => {
  return { type: "lastTotalPages", payload };
};

export const lastCurrentPage = payload => {
  return { type: "lastCurrentPage", payload };
};

export const totalPage = payload => {
  return { type: "totalPage", payload };
};

export const insertDataExport = payload => {
  return { type: "INSERT_DATA_EXPORT", payload };
};

export const fetchStatus = payload => {
  return {
    type: "FETCH_STATUS_USER",
    payload
  };
};
