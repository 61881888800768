import React, { useState, useEffect } from "react";
import TripModal from './tripmodal'
import AxiosAsli from 'axios'
import Axios from "../../../utils/axios";

function EditTripModal(props) {
  const [tripIdDetail, setTripIdDetail] = useState({id:'',userId:""});
  const [userSuggestions,setUserSuggestions]=useState([]);
  const [placeholderPhoneNumber]=useState({name:'Phone Number'});
  const [searchValuePhoneNumber,setSearchValuePhoneNumber]= useState('');

  useEffect(()=>{if(searchValuePhoneNumber!=='')findUser()},[searchValuePhoneNumber])
  useEffect(()=>{setTripIdDetail({...tripIdDetail,id:props.idEdit.tripId})},[props.isOpen])
  let findUserTimeout =null

  const findUser = async () => {
    if(findUserTimeout)clearTimeout(findUserTimeout)
    if(searchValuePhoneNumber.split("").length>3){
      findUserTimeout= setTimeout(async ()=>{
        try {
          const response = await AxiosAsli.get(`${process.env.REACT_APP_BASEURL}/cs/user/search?filterBy=phoneNumber&search=${searchValuePhoneNumber}&page=${1}&limit=5`,{headers:{token:localStorage.token}}).catch(e => {throw e});
          setUserSuggestions(response.data.data)
        } catch (e) {}
      },1000)
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      const response = await Axios(props.props,"PUT","/cs/trip/updateuser",true,tripIdDetail).catch(e => {throw e});
      alert(response.data.message);
      window.location.reload(true);
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <div>
      <TripModal 
      tripIdDetail={tripIdDetail}
      setSearchValuePhoneNumber={setSearchValuePhoneNumber}
      searchValuePhoneNumber={searchValuePhoneNumber}
      userSuggestions={userSuggestions}
      placeholderPhoneNumber={placeholderPhoneNumber}
      setTripIdDetail={setTripIdDetail}
      handleSubmit={handleSubmit}
      isOpen={props.isOpen}
      setOpen={props.setOpen}
      idDealer={props.idDealerEdit}
      />
    </div>
  );
}

export default EditTripModal;