import Paginate from 'react-paginate'
import '../../css/pageList.css'
import React from 'react'

export default ({props,handleChangePageWithNumber,totalPages})=>{
return( 
    <div className='pageContainer'>
        <Paginate 
        pageCount={totalPages?totalPages:props.totalPages} 
        activeClassName={'active'} 
        containerClassName={'pagination'} 
        onPageChange={handleChangePageWithNumber}
        subContainerClassName={'pages pagination'} 
        marginPagesDisplayed={2} 
        pageRangeDisplayed={5} 
        previousClassName={props.currentPage<=1?'changePageButtonDisabled':'changePageButtonActive'}
        nextClassName={props.currentPage >=props.totalPages?'changePageButtonDisabled':'changePageButtonActive'}
        />
    </div>
)
}
