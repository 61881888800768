import React, {useState, useEffect} from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell, { tableCellClasses } from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import Modal from '@material-ui/core/Modal';
import axios from 'axios';

const Voucher = () => {
    
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [newVoucher, setNewVoucher] = useState();
    const [voucher, setvoucher] = useState();

    function getData(){
        axios.get(`${process.env.REACT_APP_BASEURL}/admin/voucherads`, {
            headers : {
                'token' : localStorage.getItem('token')
            }
        }).then((response)=>
          {
              setvoucher(response.data.data)
          }
        ) 
    }

    useEffect(() => {
             getData()
      }, [])

      useEffect(() => {
        console.log(newVoucher)
    }, [newVoucher])  

    function handleDelete(ad,e){
        e.preventDefault()
        axios.delete(`${process.env.REACT_APP_BASEURL}/admin/voucherads/delete/${ad.id}`, {
            headers : {
                'token' : localStorage.getItem('token')
            }
        })
        getData()
    }

    function handleSubmit(e){
        e.preventDefault()
        axios.post(`${process.env.REACT_APP_BASEURL}/admin/voucherads/create`, newVoucher, {
            headers : {
                'token' : localStorage.getItem('token')
            }
        }).then((res)=> {
            alert('berhasil menambahkan voucher')
            getData();
        })
        
    }

    function handleChange(e) {
        let data = {...newVoucher}
        data[e.target.name]= e.target.value; 
        setNewVoucher(data);
      }

  return (
    <div style={{paddingTop:'5rem'}}>
         <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{textAlign:"center"}}>
        <span style={{fontWeight:"bolder", fontSize:"1.5rem"}}>Add New Voucher</span>
            <div className='containerAddUser'   >
            <TextField style={{width:"100%"}} id="outlined-basic" label="Name" variant="outlined" name='name' onChange={handleChange}/>
            </div>
            <div className='containerAddUser'>
            <TextField style={{width:"100%"}} id="outlined-basic" label="Duration" variant="outlined" name="duration" type='number' onChange={handleChange}/>
            </div>
            <div className='containerAddUser'>
            <TextField style={{width:"100%"}} id="outlined-basic" label="Potongan Harga" variant="outlined" name='potongan_harga' type='number' onChange={handleChange}/>
            </div>
            <div className='containerAddUser'>
            <FormControl fullWidth>
                    <InputLabel>Discount Type</InputLabel>
                        <Select onChange={handleChange} name='tipe_potongan'>
                            <MenuItem value='amount'>Amount</MenuItem>
                            <MenuItem value='percentage'>Percentage</MenuItem>
                        </Select>
                </FormControl>
            </div>
            <div className='containerAddUser'>
            <TextField style={{width:"100%"}} id="outlined-basic" label="Syarat & Ketentuan" variant="outlined" name='snk' onChange={handleChange}/>
            </div>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
                Submit
              </Button>
        </Box>
      </Modal>
        <button type="button"  className='addUserBtn'  onClick={handleOpen}>Add New Voucher</button>
            <Paper>
        <Table >
       
            <TableRow >
                <TableCell style={{fontWeight:"bold"}} className='tid'>No</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Name</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Duration</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Potongan Harga</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Tipe Potongan</TableCell>
                <TableCell style={{fontWeight:"bold"}}>S & K</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Action</TableCell>
            </TableRow>
        
           
            <TableBody>
                {voucher?.map((ad)=>{
                    return (
                        <>
                            <TableRow>
                                <TableCell >{ad.id}</TableCell>
                                <TableCell >{ad.name}</TableCell>
                                <TableCell >{ad.durations}</TableCell>
                                <TableCell >{ad.potongan_harga}</TableCell>
                                <TableCell >{ad.tipe_potongan}</TableCell>
                                <TableCell >{ad.snk}</TableCell>
                                <TableCell ><button>Edit</button> <button onClick={(e)=>handleDelete(ad,e)}>Delete</button></TableCell>
                            </TableRow>
                        </>
                    )
                })}
            </TableBody>
           
        </Table>
        </Paper>
    </div>
  )
}

export default Voucher