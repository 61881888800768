import React, { useEffect, useState } from 'react'
import {connect} from 'react-redux'
import { createTheme } from '@material-ui/core/styles'
import {withRouter} from 'react-router-dom'
import axios from 'axios'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell, { tableCellClasses } from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/Table';
import TableHead from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import { TextField } from "@material-ui/core";
import Box from '@material-ui/core/Box';

const AdminService = () => {

    const [admsp, setAdmSp] = useState()
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    useEffect(() => {
      axios.get(`${process.env.REACT_APP_BASEURL}/admin/service_staff_by_seviceid/?servicePointId=2`, 
      {
        headers : {'token' : localStorage.getItem("token")}}).then((response)=>
        {
            setAdmSp(response.data.data)
        }
      )      
    }, [])

  return (
    <div style={{paddingTop:'5rem'}}>

        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
            <Box sx={style} style={{textAlign:"center"}}>
            <span style={{fontWeight:"bolder", fontSize:"1.5rem"}}>Add New ads</span>
                <div className='containerAddUser'   >
                <TextField style={{width:"100%"}} id="outlined-basic" label="Name" variant="outlined" name='name' />
                </div>
                <div className='containerAddUser'>
                <TextField style={{width:"100%"}} id="outlined-basic" label="Phone" variant="outlined" name="phoneNumber" />
                </div>
                <div className='containerAddUser'>
                <TextField style={{width:"100%"}} id="outlined-basic" label="Address" variant="outlined" name='address' />
                </div>
                <div className='containerAddUser'>
                <TextField style={{width:"100%"}} id="outlined-basic" label="email" variant="outlined" name='email' />
                </div>
                <Button variant="contained" color="primary" >
                    Submit
                </Button>
            </Box>
        </Modal>

        {/* <button type="button"  className='addUserBtn' onClick={handleOpen}>Add New Admin/Staff</button> */}
        <Paper>
        <Table >
       
            <TableRow >
                <TableCell style={{fontWeight:"bold"}} className='tid'>No</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Name</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Role</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Telp</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Action</TableCell>
            </TableRow>
        
            <TableBody>
                {admsp?.map((spoint)=>{
                    return (
                        <>
                            <TableRow>
                                <TableCell >{spoint.id}</TableCell>
                                <TableCell >{spoint.name}</TableCell>
                                <TableCell >{spoint.role}</TableCell>
                                <TableCell >{spoint.phone}</TableCell>
                                <TableCell ><button>Edit</button> <button>Delete</button></TableCell>
                            </TableRow>
                        </>
                    )
                })}
            </TableBody>
        </Table>
        </Paper>
    </div>
  )
}

export default AdminService