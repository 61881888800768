import React , {useState, useEffect}from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {showBar, changeProgress} from '../../../../action/progressBar'
import {insertDataExport,fetchStatusBattery} from '../../../../action/dealerTable/batteryDealer'
import { ExportReactCSV } from '../../../../component/export'
import Utils from '../../../../utils'

function ExportDataBatteryDealer (props) {
    const [dataExportBatteryDealer, setDataExportBatteryDealer]= useState([])

    const tableSpec=[
      {Title:'No',obj:['index'],value:'index',shown:true,type:'index'},
      {Title:'Id',obj:['id'],value:'id',shown:false,type:'data'},
      {Title:'Dealer Name',obj:['user', 'name'],value:'name',shown:true,type:'data'},
      {Title:'Phone Number',obj:['user', 'phoneNumber'],value:'phoneNumber',shown:true,type:'data'},
      {Title:'Customer Type',obj:['user', 'customerType', 'name'],value:'customerTypeId',shown:true,type:'data'},
      {Title:'Bms Id',obj:['battgen2', 'x01','bmsId'],value:'batteryNo',shown:true,type:'data'},
      {Title:'Battery Status', obj:['battgen2', 'status'], value:'status',type:'data',shown:true},
    ]

    useEffect (()=>{setStatus()},[])
    useEffect (()=>{setExportData()},[props.exportingData])

    const setStatus =  async() => {
      if (props.status!=true) await fetchExport()
    }

    const fetchExport = async() => {
    try {
      const response = await Utils.Axios(props,'POST', `/system/crud/read`, true, {dbname:"dealerBattery",
      include:[
        {dbname:'battgen2',include:[{dbname:'x01'}]},
        {dbname:'user',include:[{dbname:'customerType'}]},
      ]
    })
      props.insertDataExport(dataBuilderSearch(response.data))
      props.fetchStatus(true)
    }
    catch(e){alert(e)}
  }

  const setExportData=()=>{
    const filter = props.exportingData.map((item,index)=>{
      let finalData ={}
      tableSpec.forEach(element=>{
        if(element.type==='data'){
          const arr = Utils.DataConstructor(item, element.obj,index)
          Object.assign(finalData, {
              [element.value]: arr
          })
        }
      })
      return finalData
      
    })
    setDataExportBatteryDealer(filter)
  }
  return (
      <ExportReactCSV csvData={dataExportBatteryDealer} fileName='BatteryDealerTable.csv' />
  )
    
}

const dataBuilderSearch=(data,dataType)=> data.data.map((item,index)=>{return {...item,page:data.page,dataType,index:index+1}})

const stateProps=(state)=>{
  return{
      progress:state.ProgressBar.progress,
      exportingData:state.BatteryDealer.export,
      status:state.BatteryDealer.mode
  }
}

const dispatchProps=(dispatch)=>{
  return{
      changeProgress:(load)=>{dispatch(changeProgress(load))},
      showBar:(bool)=>{dispatch(showBar(bool))},
      insertDataExport:(value)=>{dispatch(insertDataExport(value))},
      fetchStatus:(value)=>{dispatch(fetchStatusBattery(value))},
  }
}

export default withRouter(connect(stateProps,dispatchProps)(ExportDataBatteryDealer))