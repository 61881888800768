import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios'
import Table from '@material-ui/core/Table';
import Modal from '@material-ui/core/Modal';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import BeatLoader from "react-spinners/BeatLoader";
import ReactPaginate from 'react-paginate';
import './table.css'

const Pyramid = () => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      }
      const style2 = {
        position: 'absolute',
        top: '100%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      }
    const formatter = new Intl.NumberFormat('id', {
        style:'currency',
        currency:'IDR',
        maximumFractionDigits: 0
      })
    const [pyramid, setPyramid] = useState()
    const [detail, setDetail] = useState([])
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [search, setSearch] = useState('')
    const [pageCount, setpageCount] = useState()
   
    
    
    function getData (currentPage) {
        // e.preventDefault()
        axios.get(`${process.env.REACT_APP_BASEURL}/pyramid/all-user`, {
            headers : {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }})
        .then((res)=>
            {
            setpageCount(res.data.data.page)
            setPyramid(res.data.data.rows)}
        )
    }

    function getDetails (e, ad) {
         e.preventDefault()
        axios.get(`${process.env.REACT_APP_BASEURL}/pyramid/details?customerId=${ad.id}`, {
            headers : {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }})
        .then((res)=>
            {
            setDetail(res.data)
            console.log(res.data.pyramid)            
        }
        )
        setOpen(!open)
    }


    function getSearchData (currentPage) {
        
        axios.get(`${process.env.REACT_APP_BASEURL}/admin/order/all?perPage=10&currentPage=1&orderBy=id&order=desc&payment_type=&status=&payment_status=&search=${search}&no_antrian=&discount=&station=&sales=&shipping_method=`, {
            headers : {
                'token' : localStorage.getItem('token')
            }})
        .then((res)=>
            {
            setpageCount(res.data.data.page)
            console.log(res.data.data)
            setPyramid(res.data.data.rows)}
        )
        
    }


    const  handlePageClick = async (data)=>{

        let currentPage = data.selected + 1

        const getDataPerPage =  await getData(currentPage)

        setPyramid(getDataPerPage)
    }

    useEffect(()=>{
        axios.get(`http://192.168.88.140:3000/pyramid/all-user`, {
            headers : {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }})
        .then((res)=>
            {
                setpageCount(res.data.data.page)
                setPyramid(res.data.data)
            }
        )
    },[])

    
  return (
    <div style={{paddingTop:'5rem'}}>
    <Modal 
      open={open}
      onClose={()=>setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{overflow:"auto", top:"0"}}
    >
      <Box sx={style} style={{textAlign:"center"}}>
            
            <Table>
              <TableRow >
                  <TableCell style={{fontWeight:"bold"}} className='tid'>ID</TableCell>
                  <TableCell style={{fontWeight:"bold"}}>Saldo</TableCell>
                  <TableCell style={{fontWeight:"bold"}}>Referral Code</TableCell>
                  <TableCell style={{fontWeight:"bold"}}>Pyramid</TableCell>
              </TableRow>

                <TableBody>
                    <TableCell>{detail.user?.id}</TableCell>
                    <TableCell>{detail.user?.saldo}</TableCell>
                    <TableCell>{detail.user?.referralCode}</TableCell>
                    <TableCell><button onClick={()=>{setOpen2(!open2); setOpen(!open)}}>Show</button></TableCell>              
                </TableBody>          
              
            </Table>
            
            
      </Box>
    </Modal>
    
    <Modal 
      open={open2}
      onClose={()=>setOpen2(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{overflow:"auto", top:"0"}}
    >
      <Box sx={style2} style={{textAlign:"center", display:"flex", flexDirection:"column", alignItems:"center"}}>
      <div className='details-pyramid'>
           {
            detail.pyramid?.map((pyramid, i)=>{
                return(
                    <>
                    <h2>Level : {pyramid.levelId}</h2>
                    <h3>Total Amount : {formatter.format(pyramid.amount)}</h3>
                  <table key={i} >
                   
                    <tr>
                        <th>Name</th>
                        <th>Address</th>
                        <th>Amount</th>
                    </tr>
                       {
                        pyramid?.child?.map((child,i)=>{
                            console.log(child.user.name)
                            return(
                                <tr key={i} >
                                    <td >{child.user.name}</td>
                                    <td >{child.user.address1}</td>
                                    <td >{formatter.format(child.user.saldo)}</td>
                                </tr>
                            )
                        })
                       }
                  </table>
                  </>
                )
            })
           } 
       </div>   
      </Box>
    </Modal>

      {/* <input onChange={(e)=>setSearch(e.target.value)} placeholder="Search" style={{height:"2.5rem"}}></input>
      <button onClick={getSearchData} style={{height:"2.5rem"}}>Search</button>

      
      &nbsp;
      &nbsp;

      <select onChange={(e)=>setPayment_status(e.target.value) } style={{height:"2.5rem"}}>
        <option value=''>Filter By</option>
        <option value='PAID'>Payment Status - PAID</option>
        <option value='UNPAID'>Payment Status - UNPAID</option>
        <option value='PARTIAL'>Payment Status - PARTIAL</option>
      </select>
      <button onClick={(e)=>getData(1)} style={{height:"2.5rem"}}>Filter</button>
     */}
    <br/>
    <br/>

    <Paper>
        <Table >
        
            <TableRow >

                <TableCell style={{fontWeight:"bold"}} className='tid'>Nama</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Address</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Email</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Phone Number</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Referral Code</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Saldo</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Action</TableCell>
            </TableRow>
        
            
            <TableBody>
                {pyramid?.map((ad)=>{
                    return (
                        <>
                            <TableRow key={ad.id}>
                                <TableCell >{ad.name}</TableCell>
                                <TableCell >{ad.address}</TableCell>
                                <TableCell >{ad?.email}</TableCell>
                                <TableCell >{ad.phoneNumber}</TableCell>
                                <TableCell >{ad.referralCode}</TableCell>
                                <TableCell >{formatter.format( ad.saldo)}</TableCell>
                                <TableCell ><button onClick={(e)=>getDetails(e,ad)}>Details</button></TableCell>
                                
                            </TableRow>
                        </>
                    )
                })}
            </TableBody>
            
        </Table>
    </Paper>  
    
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
        containerClassName={'pagination'}
        pageClassName={'pageContainer'}
        pageLinkClassName={'changePageButton'}
        activeClassName={'active'}
      />
  </div>
  )
}

export default Pyramid