const defaultState = {
  data: [],
  page: 0,
  totalPages: 0,
  limit: 20,
  lastPage: 0,
  lastCurrentPage: 0,
  lastTotalPages: 0,
  export:[],
  mode:false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case "INSERT_DATA_PENDING_SUBSCRIPTION":
      return { ...state, data: [...state.data, ...action.payload] };
    case "INSERT_PAGE_PENDING_SUBSCRIPTION":
      return { ...state, page: action.payload };
    case "INSERT_TOTAL_PAGE_PENDING_SUBSCRIPTION":
      return { ...state, totalPages: action.payload };
    case "INSERT_LAST_PAGE_PENDING_SUBSCRIPTION":
      return { ...state, lastPage: action.payload };
    case "LAST_TOTAL_PAGES_PENDING_SUBSCRIPTION": {
      return { ...state, lastTotalPages: action.payload };
    }
    case "INSERT_DATA_FLUSH_PENDING_SUBSCRIPTION": {
      return { ...state, data: action.payload };
    }
    case "LAST_CURRENT_PAGE_PENDING_SUBSCRIPTION": {
      return { ...state, lastCurrentPage: action.payload };
    }
    case 'INSERT_DATA_EXPORT_PENDING_SUBSCRIPTION':{
      return{...state, export:[...state.export,...action.payload]}
    }
    case 'FETCH_STATUS_PENDING_SUBSCRIPTION' : {
      return {...state, mode:action.payload}
    }  
    default:
      return state;
  }
};
