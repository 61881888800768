let defaultState = [
    { name: "Login", path: "/login", isLoading: false },
    { name: "Test", path: "/test", isLoading: false },
    { name: "User", path: "/user", isLoading :false},
  ]

export default function navigatorDrawer(state = defaultState, action) {
  switch (action.type) {
    case "circularProgress":
      return state.map((list, index) => {
        if (index === action.payload.index) {
          return {...list, isLoading: action.payload.loading}
        }
        return list
      });
    default:
      return state;
  }
}
