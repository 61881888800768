import React,{useState,useEffect} from 'react'
import {changeProgress,showBar} from '../../action/progressBar'
import { insertData } from '../../action/rentalTable/detail'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import utils from '../../utils'
import dayjs from 'dayjs'
import {Card,CardContent,Button} from '@material-ui/core'
import DateTime from './components/dateTime'

const DetailRental = (props)=>{
    const[dataDisplayedTable,setDataDisplayedTable]=useState([])
    const[dataDisplayedAverage,setDataDisplayedAverage]=useState({})
    const [loading,setLoading]=useState(true)
    const [dateTime,setDateTime] = useState({endDate:dayjs().format("YYYY-MM-DD"), startDate:dayjs().format("YYYY-MM-DD")}) 
    
    useEffect(()=>{setDateTime({...dateTime})},[])


    const handleSubmitDate = async () => {
        setLoading(true)
        try {
          const tripId = props.location.pathname.split('/')[2]
          const response = await utils.Axios(props,'POST',`/cs/trip/detailtrip/${tripId}`,true,{...dateTime}).catch(e=>{throw e})
          const formattedData = await formatData(response.data.data)
          setDataDisplayedTable(formattedData.dailytrip)
          setDataDisplayedAverage(formattedData)
        } catch (error) {
          setLoading(false);
          alert('riwayat perjalanan tidak ada')
        }
      }

    const tableSpec=[
        // {Title:'id',obj:['id'],value:'id',shown:false,type:'data'},
        // {Title:'Data Type',obj:['dataType'],value:'dataType',shown:false,type:'data'},
        {Title:'Date',obj:['createdAt'],value:'createdAt',shown:true,type:'data'},
        {Title:'Usage',obj:['usedAh'],value:'usedAh',shown:true,type:'data'},
        {Title:'Distance',obj:['dayDistance'],value:'dayDistance',shown:true,type:'data'},
        {Title:'Average Speed',obj:['averageSpeed'],value:'averageSpeed',shown:true,type:'data'},
        {Title:'Max Speed',obj:['maxSpeed'],value:'maxSpeed',shown:true,type:'data'},
        {Title:'Min Speed',obj:['minSpeed'],value:'minSpeed',shown:true,type:'data'},
        {Title:'Usage Duration',obj:['usageDuration'],value:'usageDuration',shown:true,type:'data'},
        {Title:'Charge Duration',obj:['chargeDuration'],value:'chargeDuration',shown:true,type:'data'},
        // {shown:true,type:'action',component:actionColumn}
      ]
    
    const dashboardSpec=[
        {Title:'Average Usage Ah',obj:['averageAh']},
        {Title:'Total Usage Ah',obj:['totalAh']},
        {Title:'Average Distance',obj:['averageDistance']},
        {Title:'Average Speed',obj:['averageSpeed']},
        {Title:'Total Distance',obj:['totalDistance']},
        {Title:'Total Usage/Distance', obj:['totalAverageUsageDistance']},
        {Title:'Average Usage Duration', obj:['totalAverageUsageDuration']},
        {Title:'Total Usage Duration', obj:['totalUsageDuration']},
        {Title:'Average Charge Duration', obj:['totalAverageChargeDuration']},
        {Title:'Total Charge Duration', obj:['totalChargeDuration']},
    ]

    return(<div style={{marginTop:'10%'}}>
        <div style={{display:'flex', alignItems:'center'}}>
        <DateTime dateTime={dateTime} setDateTime={setDateTime} />
        <Button style={{marginLeft:'15px'}} variant="contained"color="primary" onClick={handleSubmitDate}>Submit</Button>
        </div>
        <TotalDashboard data={dataDisplayedAverage} dashboardSpec = {dashboardSpec} />
        <utils.Table dataDisplayed={dataDisplayedTable} tableSpec={tableSpec} />
    </div>)
}

const TotalDashboard=(props)=>{
return(
<div style={{display:'flex',justifyContent:'flex-start',marginBottom:20}}>
    {props.dashboardSpec.map(item=>
        <Card style={{marginRight:20}} key={item.Title}>
            <CardContent>{item.Title} : {utils.DataConstructor(props.data,item.obj)}</CardContent>
        </Card>
    )}
</div>
)}  

const formatData=(data)=>{
    const sorted = data.dailytrip.sort((a,b)=>new Date(b.createdAt)-new Date(a.createdAt))
    return {...data,dailytrip:sorted.map(item=>{return {...item,createdAt:dayjs(item.createdAt).format('DD MMMM YYYY')}})}
}

const stateProps = state =>{
    return{
        progress:state.ProgressBar.progress,
        data:state.DetailRental.data
    }
}

const dispatchProps=dispatch=>{
    return{
        changeProgress:(load)=>{dispatch(changeProgress(load))},
        showBar:(bool)=>{dispatch(showBar(bool))},
        insertData:(data)=>{dispatch(insertData(data))}
    }
}

export default withRouter(connect(stateProps,dispatchProps)(DetailRental))