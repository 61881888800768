import React from "react";
import {
  GoogleMap,
  Polyline,
  withGoogleMap,
  withScriptjs
} from "react-google-maps";

function GoogleMapGps(props) {
  return (
    <div style={{ marginTop: "50px" }}>
      <MyMapComponent
        mapElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `600px` }} />}
        loadingElement={<div style={{ height: `100%` }} />}
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCoApC-iSNhQlj-ci8738ZlOWmevybgA9E&v=3.exp&libraries=geometry,drawing,places"
        props={props}
      />
    </div>
  );
}

const MyMapComponent = withScriptjs(
  withGoogleMap(props => {
    React.useEffect(()=>{animateCamera(props.props.mapCenter.center)},[props.props.mapCenter])
    const mapref= React.useRef()
    const animateCamera=(coordinate)=>{mapref.current.panTo(coordinate)}
    return (
      <GoogleMap
        center={props.props.mapCenter.center}
        ref={mapref}
        zoom={props.props.mapCenter.zoom}
      >
        <Polyline
          geodesic={true}
          options={{
            path: props.props.mapGps,
            strokeColor: "#00ffff",
            strokeOpacity: 1,
            strokeWeight: 6,
            icons: [
              {
                offset: "0",
                repeat: "10px"
              }
            ]
          }}
        />
      </GoogleMap>
    );
  })
);

export default GoogleMapGps;
