import React, {useState, useEffect} from 'react'
import {insertData,insertPage,insertDataFlush,insertLastTotalPage,fetchStatus,insertDataExport,insertLastCurrentPage,insertTotalPage} from '../../../../action/batteryTable/stockBattery'
import {changeProgress,showBar} from '../../../../action/progressBar'
import { TableCell } from '@material-ui/core';
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import { Delete, Edit } from '@material-ui/icons';
import SearchingField from '../../../../component/sortingfield'
import Sorting from '../../../../component/sortingDouble'
import Utils from '../../../../utils'
import EditStockBatteryModal from '../component stock/editStockBattery'
import DeleteDealerModal from '../component stock/modalDeleteStock'
import { ExportReactCSV } from '../../../../component/export'
import AddStockBatteryModal from '../component stock/addStockBattery'
import bmsIdColumn from '../../../../component/bmsId'

function TableBatteryDealer(props) {
    const [dataStockBattery, setdataStockBattery] = useState([])
    const [loading,setLoading]=useState(true)
    const [canSearch,setCanSearch]=useState(true)
    const [dataExportStockBattery, setDataExportStockBattery]= useState([])
    const [deleteModalOpen,setDeleteModalOpen]=useState(false)
    const [editStockBatteryModalOpen, setEditStockBatteryModalOpen]=useState(false)
    const [idDeleted, setIdDeleted]=useState({id:''})
    const [idStockBatteryEdited, setIdStockBatteryEdited]=useState({id:''})
    const [addStockBatteryModalOpen, setAddStockBatteryModalOpen]=useState(false)
    const [dataBatteryDisplayedSearch,setdataBatteryDisplayedSearch]=useState([])
    const [searchMode,setSearchMode]=useState(false)
    const [sort, setSort] = useState({})

    useEffect(()=>{setPage()},[])
    useEffect(()=>{setDisplayedData()},[props.currentPage,searchMode])
    useEffect (()=>{setStatus()},[])
    useEffect (()=>{setExportData()},[props.exportingData])

    const setPage=async (page)=>{
        if(!searchMode){
            if (typeof page !== "number") {
              if (!props.data[props.limit * parseInt(props.currentPage) - 1]) await fetch(1)
              setLoading(false);
            } 
            else {
              if (props.data.filter(item => item.page == page).length ==0) return await fetch(page)
              props.insertPage(page);
            }
          }
    } 

    const fetch=async (page)=>{
        try {
            const response = await Utils.Axios(props,'GET',`/cs/battery/allbatteryremark?page=${page}&limit=${props.limit}`,true).catch(e=>{throw e})
            props.insertData(dataBuilder(response.data,props.limit,'all'))
            props.insertPage(response.data.page)
            props.insertTotalPage(response.data.totalPages)
        } catch (error) {
            alert(error.message)
        }
    }

    const fetchSearch = async()=>{
        setCanSearch(false)
        try {
          const temp = props.data.filter(item=>item.dataType!=='search')
          props.insertDataFlush(temp)
          const response = await Utils.Axios(props, 'POST',`/cs/battery/searchbatteryremark`,true,{...sort}).catch(e=>{throw e})
          if(!searchMode)props.setLastCurrentPageBattery(props.currentPage)
          if(!searchMode)props.setLastTotalPagesBattery(props.totalPages)
          props.insertData(dataBuilderSearch(response.data,'search'))
          props.insertPage(response.data.page)
          props.insertTotalPage(response.data.totalPages)
          setSearchMode(true)
        }
        catch(e){alert(e)}
        finally{setCanSearch(true)}
    }

    const setDisplayedData=()=>{
        if(props.currentPage==0)return
        const filter = props.data.filter(item=>searchMode?item.page==props.currentPage&&item.dataType=='search':item.page==props.currentPage).map((item,index)=>{
          let finalData ={}
          tableSpec.forEach(element=>{
            if(element.type=='data'){
              const arr = Utils.DataConstructor(item, element.obj,index)
              Object.assign(finalData, {
                  [element.value]: arr
              })
            }
          })
          return finalData 
        })
        searchMode?setdataBatteryDisplayedSearch(filter):setdataStockBattery(filter)
      }

      
    const setStatus =  async() => {
        if (props.status!=true) await fetchExport()
      }

    const fetchExport = async() => {
        try {
          const response = await Utils.Axios(props,'POST', `/system/crud/read`, true, {dbname:"batteryRemark",
          include:[
            {dbname:'battgen2',include:[{dbname:'x01'}]}
          ]
        })
          props.insertDataExport(dataBuilderSearch(response.data))
          props.fetchStatus(true)
        }
        catch(e){alert(e)}
      }
    
      const setExportData=()=>{
        const filter = props.exportingData.map((item,index)=>{
          let finalData ={}
          tableSpec.forEach(element=>{
            if(element.type==='data'){
              const arr = Utils.DataConstructor(item, element.obj,index)
              Object.assign(finalData, {
                  [element.value]: arr
              })
            }
          })
          return finalData
          
        })
        setDataExportStockBattery(filter)
      }
    const cancelSearch=(e)=>{
        e.preventDefault()
        const temp = props.data.filter(item=>item.dataType!=='search')
        props.insertDataFlush(temp)
        props.insertTotalPage(props.lastTotalPages)
        props.insertPage(1)
        setSearchMode(false)
    }

    const tableSpec=[
        {Title:'No',obj:['index'],value:'index',shown:true,type:'index'},
        {Title:'Id',obj:['id'],value:'stockBatteryId',shown:false,type:'data'},
        {Title:'Battery Id',obj:['batteryId'],value:'id',shown:false,type:'data'},
        {Title:'Bms Id',obj:['battgen2', 'x01','bmsId'],value:'BMSId',shown:false,type:'data'},
        {Title:'BMS ID',shown:true,type:'action',component:bmsIdColumn, data:props},
        {Title:'GPS ID',obj:['battgen2', 'batteryNo'],value:'GPSId',shown:true,type:'data'},
        {Title:'Remarks',obj:['remarks'],value:'remarks',shown:true,type:'data'},
        {Title:'Last Signal', obj:['battgen2', 'lastSeen'], value:'lastSignal',type:'data',shown:true},
        {shown:true,type:'action',component:EditDeleteButton,openModalDeleteDealer:setDeleteModalOpen,setDeleteDealer:setIdDeleted,idStockBattery:idDeleted,
        idStockBatteryEdit:idStockBatteryEdited, setEditDealer:setIdStockBatteryEdited, openModalEdit:setEditStockBatteryModalOpen}
      ]

    const handleChangePage=(data)=>{setPage(parseInt(data.selected)+1)}

    const dataBuilder=(data,limit,dataType)=> data.data.map((item,index)=>{return {...item,page:data.page,dataType,index:((data.page-1)*limit)+index+1}})
    const dataBuilderSearch=(data,dataType)=> data.data.map((item,index)=>{return {...item,page:data.page,dataType,index:index+1}})

    return(
        <div>
            <div style={{flexDirection:'row',display:'flex',marginTop:'55px'}}>
                <ExportReactCSV csvData={dataExportStockBattery} fileName='RentalTable.csv'/>
                <button type="button" onClick={()=>{setAddStockBatteryModalOpen(true)}} className='addUserBtn'>
                Add New Stock
                </button>
                <Sorting sort={sort} setSort={setSort} sortBy={sortingItem}/>
                <div style={{flex:1}}>  
                <SearchingField  props={props} canSearch={canSearch} fetchSearch={fetchSearch} setSort={setSort} sort={sort}cancelSearch={cancelSearch} searchMode={searchMode}/>
                </div>
            </div>
            <div style={{flexDirection:'row',display:'flex', alignItems:'center'}}>
            {searchMode?<Utils.Table dataDisplayed={dataBatteryDisplayedSearch} tableSpec={tableSpec} />:<Utils.Table dataDisplayed={dataStockBattery} tableSpec={tableSpec} />}
            </div>
            {!loading&&!searchMode&&<Utils.Pagination totalPages={props.totalPages}  props={props} handleChangePageWithNumber={handleChangePage} />}
            <EditStockBatteryModal isOpen={editStockBatteryModalOpen} props={props} setOpen={setEditStockBatteryModalOpen} idStockBatteryEdit={idStockBatteryEdited}/>
            <DeleteDealerModal isOpen={deleteModalOpen} props={props} setOpen={setDeleteModalOpen} idStockBattery={idDeleted} />
            <AddStockBatteryModal isOpen={addStockBatteryModalOpen} props={props} setOpen={setAddStockBatteryModalOpen} />
        </div>
    )    
}

const sortingItem = [
  {sortBy:'Remarks',type:'whereClauseRemark'},
  {sortBy:'BMSId',type:'whereClauseBattery'},
]


  const EditDeleteButton=({row,item})=>
  { 
    const handlePressedDelete=()=>{
    item.openModalDeleteDealer(true)
    item.setDeleteDealer(row)
    }
  
    const handlPressedeEdit=()=>{
      item.setEditDealer(row)
      item.openModalEdit(true)
    }

    return <TableCell  align='center'>
      <div className='tooltip'>
        <Edit  style={{ cursor:'pointer' ,marginRight:'5px'}} key={row.index} onClick={handlPressedeEdit}/>
        <span className='tooltiptextuser'>edit</span>
      </div>
      <div className='tooltip'>
        <Delete style={{ cursor:'pointer' ,marginRight:'5px'}} key={row.index} onClick={handlePressedDelete}/>
      <span className='tooltiptextuser'>Delete</span>
      </div>
    </TableCell>
  }
  const mapStateToProps=(state)=>{
    return{
        progress:state.ProgressBar.progress,
        data:state.StockBattery.data,
        currentPage:state.StockBattery.page,
        totalPages:state.StockBattery.totalPages,
        lastPage:state.StockBattery.lastPage,
        limit:state.StockBattery.limit,
        lastCurrentPage:state.StockBattery.lastCurrentPage,
        lastTotalPages:state.StockBattery.lastTotalPages,
        exportingData:state.StockBattery.export,
    }
}

const mapDispatchToProps=(dispatch)=>{
    return{
        changeProgress:(load)=>{dispatch(changeProgress(load))},
        showBar:(bool)=>{dispatch(showBar(bool))},
        insertData:(data)=>{dispatch(insertData(data))},
        insertPage:(page)=>{dispatch(insertPage(page))},
        insertTotalPage:(totalPage)=>{dispatch(insertTotalPage(totalPage))},
        insertDataFlush:(value,type)=>{dispatch(insertDataFlush(value,type))},
        setLastCurrentPageBattery:(value)=>{dispatch(insertLastCurrentPage(value))},
        setLastTotalPagesBattery:(value)=>{dispatch(insertLastTotalPage(value))},
        insertDataExport:(value)=>{dispatch(insertDataExport(value))},
        fetchStatus:(value)=>{dispatch(fetchStatus(value))},
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(TableBatteryDealer))