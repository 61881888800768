export const insertData = (payload) =>{
  return {
    type:'INSERT_DATA_RENTAL',
    payload
  }
}

export const insertPage = (payload) =>{
  return {
    type:'INSERT_PAGE_RENTAL',
    payload
  }
}

export const insertLastPage = (payload) =>{
  return {
    type:'INSERT_LAST_PAGE_RENTAL',
    payload
  }
}

export const insertTotalPage = (payload) =>{
  return {
    type:'INSERT_TOTAL_PAGE_RENTAL',
    payload
  }
}

export const insertLastTotalPage = payload => {
  return {
    type: "LAST_TOTAL_PAGES_RENTAL",
    payload
  };
};

export const insertLastCurrentPage = payload => {
  return {
    type: "LAST_CURRENT_PAGE_RENTAL",
    payload
  };
};

export const insertDataFlush = payload => {
  return { 
    type: "INSERT_DATA_FLUSH_RENTAL", 
    payload 
  }
};
export const insertDataExport = (payload)=> {
  return{type:'INSERT_DATA_EXPORT_RENTAL', payload}
}

export const fetchStatus = payload =>{
  return {
    type:'FETCH_STATUS_RENTAL',
    payload
  }
}
