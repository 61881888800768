import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";

export default function SortingComponent(props) {
  const classes = useStyles();
  const handleChangeSort = event => {
    props.setSort({...props.sort,[event.target.name]:event.target.value});
  };
  return (
    <Fragment>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">Search By</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name='whereClause'
            value={props.sort.whereClause}
            onChange={handleChangeSort}
          >
            {props.sortBy.map(item => 
              <MenuItem value={item.sortBy} key={item.sortBy}>
              {item.sortBy}
              </MenuItem>
            )}
          </Select>
        </FormControl>
    </Fragment>
  );
}

const useStyles = makeStyles(theme=>({
    formControl: {
      margin: theme.spacing(1.8),
      minWidth: 120,
      maxWidth: 400,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));