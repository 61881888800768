import React, { useEffect, useState } from 'react'
import {connect} from 'react-redux'
import { createTheme } from '@material-ui/core/styles'
import {withRouter} from 'react-router-dom'
import axios from 'axios'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell, { tableCellClasses } from '@material-ui/core/TableCell';
import { TextField } from "@material-ui/core";
import TableContainer from '@material-ui/core/Table';
import TableHead from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';



const ServicePoint = () => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

    const [openAddSP, setOpenAddSP] = useState(false);
    const [openAddAdm,setOpenAddAdm] = useState(false)
    // const handleOpen = () => setOpen(true);

    // const handleClose = () => setOpen(false);
    const [sp, setSp] = useState();
    const [newSP, setNewSP] = useState();
    const [newAdmin, setNewAdmin] = useState();
    
      function getData(){
        axios.get(`${process.env.REACT_APP_BASEURL}/customer/service`).then((response)=>
        {
            setSp(response.data.data)
        }
      )  
      }

    useEffect(() => {
          getData();
    }, [])
    
    function handleChange(e){
        let data = {...newSP}
        let adm = {...newAdmin}
        data[e.target.name]= e.target.value; 
        adm[e.target.name]= e.target.value;
        setNewAdmin(adm);
        setNewSP(data);
    }

    function handleSubmit(e){
        e.preventDefault()

        axios.post(`${process.env.REACT_APP_BASEURL}/admin/service_point/create`, newSP, {
            headers : {
                'token' : localStorage.getItem('token')
            }
        }).then(alert('Service Point Baru Berhasil Ditambahkan'))
        getData();
    }

    function handleActive(spoint,e){
        axios.post(`${process.env.REACT_APP_BASEURL}/admin/service_point/lock?servicePointId=${spoint.id}`).then(
            alert('service point dinonaktifkan')
        )
    }

    function handleNonActive(spoint,e){
        axios.post(`${process.env.REACT_APP_BASEURL}/admin/service_point/unlock?servicePointId=${spoint.id}`).then(
            alert('service point diaktifkan')
        )
    }

    // function handleSubmitNewAdmin(spoint,e){
    //     let newAdmin = {
    //         name : 
    //     }
    // }

  return (
    <div style={{paddingTop:'5rem'}}>
        <Modal
        open={openAddSP}
        onClose={openAddSP}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{textAlign:"center"}}>
        <span style={{fontWeight:"bolder", fontSize:"1.5rem"}}>Add New Service Point</span>
            <div className='containerAddUser'   >
            <TextField style={{width:"100%"}} id="outlined-basic" label="Name" variant="outlined" name='name' onChange={handleChange}/>
            </div>
            <div className='containerAddUser'>
            <TextField style={{width:"100%"}} id="outlined-basic" label="Phone" variant="outlined" name="phone" onChange={handleChange}/>
            </div>
            <div className='containerAddUser'>
            <TextField style={{width:"100%"}} id="outlined-basic" label="Address" variant="outlined" name='address' onChange={handleChange}/>
            </div>
            <div className='containerAddUser'>
            <TextField style={{width:"100%"}} id="outlined-basic" label="Description" variant="outlined" name='description' onChange={handleChange}/>
            </div>
            <div className='containerAddUser'>
            <TextField  style={{width:"100%"}} id="outlined-basic" label="Longitude" variant="outlined" name='longitude' onChange={handleChange}/>
            </div>
            <div className='containerAddUser'>
            <TextField style={{width:"100%"}} id="outlined-basic" label="Latitude" variant="outlined" name='latitude' onChange={handleChange}/>
            </div>
            <div className='containerAddUser'>
            <TextField style={{width:"100%"}} id="outlined-basic" label="Open Time" variant="outlined" name='open_time' onChange={handleChange}/>
            </div>
            <div className='containerAddUser'>
            <TextField style={{width:"100%"}} id="outlined-basic" label="Close-Time" variant="outlined" name='close_time' onChange={handleChange}/>
            </div>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
                Submit
              </Button>
              <br/><br/>
                    <Button variant="contained" color="primary" onClick={()=>setOpenAddSP(!openAddSP)}>
                        Close
                    </Button>
        </Box>
        </Modal>

            <Modal
                open={openAddAdm}
                onClose={openAddAdm}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} style={{textAlign:"center"}}>
                <span style={{fontWeight:"bolder", fontSize:"1.5rem"}}>Add New Service Point</span>
                    <div className='containerAddUser'   >
                    <TextField style={{width:"100%"}} id="outlined-basic" label="Name" variant="outlined" name='name' onChange={handleChange}/>
                    </div>
                    <div className='containerAddUser'>
                    <TextField style={{width:"100%"}} id="outlined-basic" label="Phone" variant="outlined" name="phone" onChange={handleChange}/>
                    </div>
                    <div className='containerAddUser'>
                    <TextField style={{width:"100%"}} id="outlined-basic" label="Address" variant="outlined" name='address' onChange={handleChange}/>
                    </div>
                    <div className='containerAddUser'>
                    <TextField style={{width:"100%"}} id="outlined-basic" label="Email" variant="outlined" name='email' onChange={handleChange}/>
                    </div>
                    <div className='containerAddUser'>
                    <TextField  style={{width:"100%"}} id="outlined-basic" label="Password" variant="outlined" name='password' onChange={handleChange}/>
                    </div>
                    <div className='containerAddUser'>
                    <TextField style={{width:"100%"}} id="outlined-basic" label="role" variant="outlined" name='latitude' onChange={handleChange}/>
                    </div>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Submit
                    </Button><br/><br/>
                    <Button variant="contained" color="primary" onClick={()=>setOpenAddAdm(!openAddAdm)}>
                        Close
                    </Button>
                </Box>
            </Modal>
      

        <button type="button"  className='addUserBtn' onClick={()=>setOpenAddSP(!openAddSP)}>Add New Service Point</button>
        <Paper>
        <Table >
       
            <TableRow >
                <TableCell style={{fontWeight:"bold"}} className='tid'>No</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Name</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Address</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Telp</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Action</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Admin</TableCell>
            </TableRow>
        
            <TableBody>
                {sp?.map((spoint)=>{
                    return (
                        <>
                            <TableRow>
                                <TableCell >{spoint.id}</TableCell>
                                <TableCell >{spoint.name}</TableCell>
                                <TableCell >{spoint.address}</TableCell>
                                <TableCell >{spoint.telp}</TableCell>
                                <TableCell style={{width:"11rem"}}>
                                    <button onClick={()=>handleNonActive(spoint)}>Active</button>
                                    <button onClick={()=>handleActive(spoint)}>Non-Active</button>
                                </TableCell>
                                <TableCell style={{width:"11rem"}}>
                                    <button onClick={()=>setOpenAddAdm(!openAddAdm)}>Buat Admin</button>
                                </TableCell>
                            </TableRow>
                        </>
                    )
                })}
            </TableBody>
        </Table>
        </Paper>
    </div>
  )
  
}
const mapStateToProps = state=>{
    return{
      progress:state.ProgressBar.progress,
      data:state.PendingCustomer.data,
      status:state.PendingCustomer.mode
    }
}


export default withRouter(connect(mapStateToProps)(ServicePoint))