const defaultState = {
  data:[],
  limit:20,
  page:0,
  totalPages:0,
  lastCurrentPage:0,
  lastTotalPages:0,
  lastPage:0,
  export:[],
  mode:false,
}

export default (state=defaultState, action)=>{
  switch (action.type){
    case 'insertDataVehicleDealer' : {
      return {...state, data: [...state.data, ...action.payload]}
    }
    case 'changePageVehicle':{
      return{...state,page:action.payload}
    }
    case 'lastCurrentPageVehicle':{
      return{...state,lastCurrentPage:action.payload}
    }
    case 'lastTotalPagesVehicle':{
      return{...state,lastTotalPages:action.payload}
    }
    case 'totalPageVehicle':{
      return{...state, totalPages:action.payload}
    }
    case 'INSERT_DATA_EXPORT_VEHICLE_DEALER':{
      return{...state, export:[...state.export,...action.payload]}
    }
    case 'INSERT_DATA_FLUSH_VEHICLE_DEALER':{
      return {...state, data:action.payload}
    }
    case 'FETCH_STATUS_VEHICLE_DEALER' : {
      return {...state, mode:action.payload}
    }
    case "INSERT_LAST_PAGE_VEHICLE_DEALER":
      return { ...state, lastPage: action.payload };
    default : return state
  }
}

