import React, { useState, useEffect } from "react";
import AxiosAsli from 'axios'
import VehicleModal from './modalVehicleDealer'
import Axios from "../../../../utils/axios";
import "../../../../css/addUser.css";

function AddVehicleDealerModal(props) {
  const [vehicleDealerDetail, setVehicleDealerDetail] = useState({userId:"",vehicleId:""});
  const [userSuggestions,setUserSuggestions]=useState([]);
  const [placeholderPhoneNumber]=useState({name:'Phone Number'});
  const [placeholderRangkaCode]=useState({name:'Rangka Code'});
  const [vehicleSuggestions, setVehicleSuggestions]= useState([]);
  const [searchValuePhoneNumber,setSearchValuePhoneNumber]= useState('');
  const [searchValueKodeRangka,setSearchValueKodeRangka]= useState('');

  useEffect(()=>{if(searchValuePhoneNumber!=='')findUser()},[searchValuePhoneNumber])
  useEffect(()=>{if(searchValueKodeRangka!=='')findVehicle()},[searchValueKodeRangka])

  let findUserTimeout =null

  const findUser = async () => {
    if(findUserTimeout)clearTimeout(findUserTimeout)
    if(searchValuePhoneNumber.split("").length>3){
      findUserTimeout= setTimeout(async ()=>{
        try {
          const response = await AxiosAsli.get(`${process.env.REACT_APP_BASEURL}/cs/user/search?filterBy=phoneNumber&search=${searchValuePhoneNumber}&page=${1}&limit=5`,{headers:{token:localStorage.token}}).catch(e => {throw e});
          setUserSuggestions(response.data.data)
        } catch (e) {}
      },1000)
    }
  };

  let findVehicleTimeout =null
  const findVehicle =async () => {
    if(findVehicleTimeout)clearTimeout(findVehicleTimeout)
    if(searchValueKodeRangka.split("").length>3){
      findVehicleTimeout= setTimeout(async ()=>{
        try {
          const response = await AxiosAsli.get(`${process.env.REACT_APP_BASEURL}/cs/vehicle/search?filterBy=rangka&search=${searchValueKodeRangka}&page=${1}&limit=5`,{headers:{token:localStorage.token}}).catch(e => {throw e});
          setVehicleSuggestions(response.data.data)
        } catch (e) {}
      },1000)
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      const response = await Axios(props.props,"POST","/cs/vehicle/addvehicledealer",true,vehicleDealerDetail).catch(e => {throw e});
      alert(response.data.message);
      window.location.reload(true);
    } catch (error) {
      alert(error.message);
    }
    
  };

  return (
    <div>
      <VehicleModal
      vehicleDealer={vehicleDealerDetail}
      setSearchPhoneNumber={setSearchValuePhoneNumber}
      searchPhoneNumber={searchValuePhoneNumber}
      user={userSuggestions}
      placeholderPhone={placeholderPhoneNumber}
      setSearchKodeRangka={setSearchValueKodeRangka}
      searchKodeRangka={searchValueKodeRangka}
      vehicle={vehicleSuggestions}
      placeholderRangka={placeholderRangkaCode}
      setVehicleDealer={setVehicleDealerDetail}
      handleSubmit={handleSubmit}
      isOpen={props.isOpen}
      setOpen={props.setOpen}
      />
    </div>
  );
}


export default AddVehicleDealerModal;
