import React, {useState, useEffect} from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell, { tableCellClasses } from '@material-ui/core/TableCell';
import { MenuItem, Select, TextField, InputLabel, FormControl } from "@material-ui/core";
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import axios from 'axios';
import Switch from '@material-ui/core/Switch';


const Promo = () => {
    
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [newDealer, setNewDealer] = useState();
    const [openAdd, setOpenAdd] = useState(false)
    const [dealer, setDealer] = useState();
    const [regency, setRegency] = useState();
    const [regencyID, setRegencyID] = useState();
    const [regencyName, setRegencyName] = useState();
    const [province, setProvince] = useState();
    const [provinceID, setProvinceID] = useState();
    const [provinceName, setProvinceName] = useState();
    const [file1, setFile1] = useState()
    const [dealerID, setDealerID] = useState()
   
   
      function getDealerData(){
        axios.get(`${process.env.REACT_APP_BASEURL}/admin/get/dealers`, {
            headers : {
                'token' : localStorage.getItem('token')
            }
        }).then((response)=>
          {
              setDealer(response.data.data)
          }
        )      
      }
  
      useEffect(() => {
        getDealerData();
      },[])

      useEffect(()=>{
        axios.get(`${process.env.REACT_APP_BASEURL}/api/province`).then((res)=>{
          setProvince(res.data.data)
        })
      },[provinceID])

      useEffect(()=>{
        axios.get(`${process.env.REACT_APP_BASEURL}/api/regency/${provinceID}`).then((res)=>{
          setRegency(res.data.data)
        })
      },[provinceID])


      function handleChange(e) {
        let data = {...newDealer}
        if (e.target.type === 'text' || 'number'){
            data[e.target.name] = e.target.value;
          } else if 
            (e.target.type === 'file') {
              data[e.target.name] = e.target.files[0]
            }
        setNewDealer(data);
      }

      function handleSubmit(e){
        e.preventDefault()

        const dataUpload = new FormData();

        dataUpload.append('qrCode', file1);
        dataUpload.append('name', newDealer.name);
        dataUpload.append('email', newDealer.email);
        dataUpload.append('companyName', newDealer.companyName);
        dataUpload.append('phoneNumber', newDealer.phoneNumber);
        dataUpload.append('address', newDealer.address);
        dataUpload.append('provinsi', provinceName);
        dataUpload.append('city', regencyName);
        dataUpload.append('type', newDealer.type);
        dataUpload.append('maps_link', newDealer.maps_link);

        axios.post(`${process.env.REACT_APP_BASEURL}/admin/dealer/create`, dataUpload
        , {
            headers : {
                'token' : localStorage.getItem('token'),
                'content-type' : 'multipart/form-data'
            }
        }).then((res)=> {
            alert('Dealer baru telah berhasil dibuat');
            getDealerData();
        })
        }

        function handleNonActive(dealer,e){
            axios.post(`${process.env.REACT_APP_BASEURL}/admin/lock/dealer/${dealer.id}`).then((res)=>
            console.log(res)
            )
        }

        function handleActive(dealer,e){
            axios.post(`${process.env.REACT_APP_BASEURL}/admin/unlock/dealer/${dealer.id}`).then(getDealerData())
        }

        function handleProvince(e) {
            const value = e.target.value.split(",");
            setProvinceID(value[0])
            setProvinceName(value[1])
          }
  
            function handleRegency(e) {
              const value = e.target.value.split(",");
              setRegencyID(value[0])
              setRegencyName(value[2])
            
            }

                function handleSubmitAddAdmin(dealer, e){
                    axios.post(`${process.env.REACT_APP_BASEURL}/admin/dealer_admin/create/${dealerID}`, {
                        name:newDealer.name,
                        phoneNumber:newDealer.phoneNumber,
                        email:newDealer.email,
                        password:newDealer.password,
                        address:newDealer.address
                    },
                    {
                        headers : {
                            'token' : localStorage.getItem('token')
                        }
                    }).then((res)=> {
                        alert('Admin baru telah berhasil dibuat');
                        getDealerData();
                    })
                    }

                    function handleopenADD(dealer, e){
                       setOpenAdd(!openAdd)
                       setDealerID(dealer.id)
                        }
                

  return (
    <div style={{paddingTop:'5rem'}}>
         <button type="button"  className='addUserBtn'   onClick={handleOpen}>Add New Dealer</button>

        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
        <Box sx={style} style={{textAlign:"center"}}>
        <span style={{fontWeight:"bolder", fontSize:"1.5rem"}}>Add New Dealer</span>
            <div className='containerAddUser'   >
            <TextField style={{width:"100%"}} id="outlined-basic" label="Name" variant="outlined" name='name' onChange={handleChange}/>
            </div>
            <div className='containerAddUser'>
            <TextField style={{width:"100%"}} id="outlined-basic" label="Company Name" variant="outlined" name="companyName" onChange={handleChange}/>
            </div>
            <div className='containerAddUser'>
            <TextField style={{width:"100%"}} id="outlined-basic" label="Phone" variant="outlined" name='phoneNumber' onChange={handleChange}/>
            </div>
            <div className='containerAddUser'>
            <TextField style={{width:"100%"}} id="outlined-basic" label="Email" variant="outlined" name='email' onChange={handleChange}/>
            </div>
            <div className='containerAddUser'>
            <TextField style={{width:"100%"}} id="outlined-basic" label="Address" variant="outlined" name='address' onChange={handleChange}/>
            </div>
            <div className='containerAddUser'>
                <select id='province' required  onChange={handleProvince}>
                          <option>Provinsi</option>
                          {province?.map(province=>(
                              <option value={[province.id, province.name]}>{province.name}</option>
                          ))}
                </select>
            </div>
            <div className='containerAddUser'>
                <select id='regency' required onChange={handleRegency}>
                            <option>Kota/Kabupaten</option>
                            {regency?.map(regency=>(
                                <option value={[regency.id, , regency.name]}>{regency.name}</option>
                            ))}
                </select>
            </div>
            <div className='containerAddUser'>
            <FormControl fullWidth>
                    <InputLabel>Type</InputLabel>
                        <Select onChange={handleChange} name='type'>
                            <MenuItem value='official'>official</MenuItem>
                            <MenuItem value='nonofficial'>nonofficial</MenuItem>
                        </Select>
                </FormControl>
            </div>
            <div className='containerAddUser'>
            <TextField style={{width:"100%"}} id="outlined-basic" label="Maps Link" variant="outlined" name='maps_link' onChange={handleChange}/>
            </div>
            <div className='containerAddUser'>
            <label>QR &nbsp;</label>
            <input type='file' onChange={(e)=>setFile1(e.target.files[0])}/>
            </div>

            <Button variant="contained" color="primary" onClick={handleSubmit}>
                Submit
              </Button>
        </Box>
        </Modal>

        <Modal
            open={openAdd}
            onClose={()=>setOpenAdd(!openAdd)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
        <Box sx={style} style={{textAlign:"center"}}>
        <span style={{fontWeight:"bolder", fontSize:"1.5rem"}}>Add New Admin</span>
            <div className='containerAddUser'   >
            <TextField style={{width:"100%"}} id="outlined-basic" label="Name" variant="outlined" name='name' onChange={handleChange}/>
            </div>
            <div className='containerAddUser'>
            <TextField style={{width:"100%"}} id="outlined-basic" label="Password" variant="outlined" name="password" onChange={handleChange}/>
            </div>
            <div className='containerAddUser'>
            <TextField style={{width:"100%"}} id="outlined-basic" label="Phone" variant="outlined" name='phoneNumber' onChange={handleChange}/>
            </div>
            <div className='containerAddUser'>
            <TextField style={{width:"100%"}} id="outlined-basic" label="Email" variant="outlined" name='email' onChange={handleChange}/>
            </div>
            <div className='containerAddUser'>
            <TextField style={{width:"100%"}} id="outlined-basic" label="Address" variant="outlined" name='address' onChange={handleChange}/>
            </div>

            <Button variant="contained" color="primary" onClick={(e)=>handleSubmitAddAdmin(dealer, e)}>
                Submit
              </Button>
        </Box>
        </Modal>

        <Paper>
        <Table >

            <TableRow >
                <TableCell style={{fontWeight:"bold"}} className='tid'>No</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Name</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Address</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Company Name</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Email</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Kota</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Action</TableCell>
            </TableRow>
        
           
            <TableBody>
                {dealer?.map((dealer)=>{
                    return (
                        <>
                            <TableRow>
                                <TableCell >{dealer.id}</TableCell>
                                <TableCell >{dealer.name}</TableCell>
                                <TableCell >{dealer.address}</TableCell>
                                <TableCell >{dealer.companyName}</TableCell>
                                <TableCell >{dealer.email}</TableCell>
                                <TableCell >{dealer.city}</TableCell>
                                <TableCell style={{width:"11rem"}}>
                                    {dealer.status == 'active' ?
                                    < button onClick={(e)=>handleNonActive(dealer,e)}>Non Active</ button> :
                                    < button onClick={(e)=>handleActive(dealer,e)}>Active</ button>
                                    }
                                    < button onClick={(e)=>handleopenADD(dealer, e )}>Buat Admin</button>
                                </TableCell>
                            </TableRow>
                        </>
                    )
                })}
            </TableBody>
           
        </Table>
        </Paper> 
       
    </div>
  )
}

export default Promo