import React, {useState, useEffect} from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { TextField } from "@material-ui/core";
import Modal from '@material-ui/core/Modal';
import axios from 'axios';

const CreateProposal = () => {
    
    const formatter = new Intl.NumberFormat('id', {
      style:'currency',
      currency:'IDR',
      maximumFractionDigits: 0
    })
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };
    const [open, setOpen] = useState(false);
    //const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [openNew, setOpenNew] = useState(false);
    const [proposal, setproposal] = useState();
    const [oneproposal, setOneproposal] = useState();
    const [ecgo_subsidy, setecgo_subsidy] = useState(0);
    const [otr_price, setotr_price] = useState(0);
    const [name, setname] = useState('');
    const [description, setdescription] = useState('');
    const [ads_link, setads_link] = useState('');
    const [file1, setFile1] = useState()
    const [file2, setFile2] = useState()
    const [file3, setFile3] = useState()
    const [editKred, setEditKred] = useState(null)
    const [editPropId, setEditPropId] = useState(null)
    const [formdata, setFormdata] = useState({
      id:null,
      name:'',
      ecgo_subsidy:0,
      otr_price:0,
      description:'',
      ads_link:'',
      file1:null,
      file2:null,
      file3:null,
    })
    const [formkredit, setFormKredit] = useState({
      dp:0,
      bulan12:0,
      bulan18:0,
      bulan24:0,
      bulan36:0
    });


    function getData(){
        axios.get(`${process.env.REACT_APP_BASEURL}/admin/tradein/get-proposals`, {
            headers : {
                'token' : localStorage.getItem('token')
            }
        }).then((response)=>
          {
              setproposal(response.data.data)
          }
        ) 
    }

    function handleEdit (ad,e) {  
      setOpen(true)
      setEditPropId(null)
      setEditPropId(ad.id)
      axios.get(`${process.env.REACT_APP_BASEURL}/admin/tradein/get-proposal?proposalId=${ad.id}`,{
        headers : {
            'token' : localStorage.getItem('token')
        }
      }).then((response)=>
        {   
            let data = response.data.data
            console.log(data)
            setFormdata({
              id:data.id,
              name:data.name,
              description:data.description,
              otr_price:data.otr_price,
              ecgo_subsidy:data.ecgo_subsidy,
              ads_link:data.ads_link
            })
            setOneproposal(response.data.data)
        }
      ) 
    }

    function handleSubmitKredit(cred,e){
      e.preventDefault()
      axios.post(`${process.env.REACT_APP_BASEURL}/admin/tradein/credit-simulation/create`, {
        proposalId:formdata.id,
        dp:formkredit.dp,
        bulan12:formkredit.bulan12,
        bulan18:formkredit.bulan18,
        bulan24:formkredit.bulan24,
        bulan36:formkredit.bulan36
      }, {
        headers : {
            'token' : localStorage.getItem('token'),
        }
      }).then((res)=> {
          alert('berhasil menambahkan Kredit')
      })
    }

    function handleDelete(ad,e){
        e.preventDefault()
        axios.delete(`${process.env.REACT_APP_BASEURL}/admin/tradein/delete-proposal/${ad.id}`, {
            headers : {
                'token' : localStorage.getItem('token')
            }
        }).then(getData())
        
    }

    function handleActive(ad,e){
      e.preventDefault()
      axios.post(`${process.env.REACT_APP_BASEURL}/admin/tradein/undodelete-proposal/${ad.id}`,{}, {
          headers : {
              'token' : localStorage.getItem('token')
          }
      }).then(getData())
      
    }

    function handleSubmitNewProp(e){
        e.preventDefault()

        const dataUpload = new FormData();

        dataUpload.append('ads', file1);
        dataUpload.append('brosur_depan', file2);
        dataUpload.append('brosur_belakang', file3);
        dataUpload.append('name', name);
        dataUpload.append('ecgo_subsidy', ecgo_subsidy);
        dataUpload.append('description',description);
        dataUpload.append('otr_price',otr_price);
        dataUpload.append('ads_link',ads_link);

        axios.post(`${process.env.REACT_APP_BASEURL}/admin/tradein/proposal/create`, dataUpload, {
            headers : {
                'token' : localStorage.getItem('token'),
                'content-type' : 'multipart/form-data'
            }
        }).then((res)=> {
          console.log(res)
            alert('berhasil menambahkan proposal')
            getData();
        })
        
    }

    function handleChangeProp(e){
      let data = {...formdata};
      if (e.target.type === 'text' || 'number'){
        data[e.target.name] = e.target.value;
      } else if 
        (e.target.type === 'file') {
          data[e.target.name] = e.target.files[0]
        }
      setFormdata(data);
    }

    function handleChangeKred(e){
      let data = {...formkredit}
      data[e.target.name] = e.target.value;
      setFormKredit(data)
    }

    function handleDeletKred(cred,e){
      axios.delete(`${process.env.REACT_APP_BASEURL}/admin/tradein/credit-simulation/delete`, {
        headers:{
          'token' : localStorage.getItem('token')
      }
      ,
      data : {'creditId':cred.id}}
      ).then((res)=>
        handleEdit(editPropId))
    }

    function handleEditKred(cred,e){
      e.preventDefault()
      axios.put(`${process.env.REACT_APP_BASEURL}/admin/tradein/credit-simulation/update`, {
        creditId:cred.id,
        dp:formkredit.dp,
        bulan12:formkredit.bulan12,
        bulan18:formkredit.bulan18,
        bulan24:formkredit.bulan24,
        bulan36:formkredit.bulan36
      },{
        headers:{
          'token' : localStorage.getItem('token'),
      }
      }).then((res)=>
        {
        alert('berhasil mengedit kredit')
        handleEdit(editPropId)})
    }

    function handleEditProp(ad,e){
      //e.preventDefault()
      axios.put(`${process.env.REACT_APP_BASEURL}/admin/tradein/proposal/update`, {
        proposalId:editPropId,
        name:formdata.name,
        description:formdata.description,
        otr_price:formdata.otr_price,
        ecgo_subsidy:formdata.ecgo_subsidy,
        ads_link:formdata.ads_link
      },{
        headers:{
          'token' : localStorage.getItem('token'),
      }
      }).then((res)=>
        {
        alert('berhasil mengedit proposal')
        })
    }

    useEffect(() => {
      getData()
    }, [])

  return (
    <div style={{paddingTop:'5rem'}}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{overflow:"auto", top:"0"}}
      >
        <Box sx={style} style={{textAlign:"center"}}>
          <span style={{fontWeight:"bolder", fontSize:"1.5rem"}}>Add New Proposal</span>
              <div className='containerAddUser'   >
                <TextField style={{width:"100%"}} id="outlined-basic" label="Name" variant="outlined" name='name' value={formdata.name} onChange={handleChangeProp}/>
              </div>
              <div className='containerAddUser'>
                <TextField style={{width:"100%"}} id="outlined-basic" label="Description" variant="outlined" name="description" value={formdata.description} type='text' onChange={handleChangeProp}/>
              </div>
              <div className='containerAddUser'>
                <TextField style={{width:"100%"}} id="outlined-basic" label="Ads Link" variant="outlined" name='ads_link' value={formdata.ads_link} type='text' onChange={handleChangeProp}/>
              </div>
              <div className='containerAddUser'>
                <TextField style={{width:"100%"}} id="outlined-basic" label="Subsidi" variant="outlined" name='ecgo_subsidy' value={formdata.ecgo_subsidy} type='number' onChange={handleChangeProp}/>
              </div>
              <div className='containerAddUser'>
                <TextField style={{width:"100%"}} id="outlined-basic" label="OTR Price" variant="outlined" name='otr_price' value={formdata.otr_price} type='number' onChange={handleChangeProp}/>
              </div>

              <Table >
       
                <TableRow >
                    <TableCell style={{fontWeight:"bold"}} className='tid'>DP</TableCell>
                    <TableCell style={{fontWeight:"bold"}}>12 Bln</TableCell>
                    <TableCell style={{fontWeight:"bold"}}>18 Bln</TableCell>
                    <TableCell style={{fontWeight:"bold"}}>24 Bln</TableCell>
                    <TableCell style={{fontWeight:"bold"}}>36 Bln</TableCell>
                    <TableCell style={{fontWeight:"bold"}}>Edit</TableCell>
                </TableRow>

                <TableBody>
                      
                        {oneproposal?.creditsimulation != null &&  
                          oneproposal?.creditsimulation.map((cred)=> {
                            return (<>
                              {editKred === cred.id ? (
                                <TableRow>
                                  <TableCell ><input type='number' name='dp'  onChange={handleChangeKred} style={{width:'6rem'}}/></TableCell>
                                  <TableCell ><input type='number' name='bulan12' onChange={handleChangeKred} style={{width:'6rem'}}/></TableCell>
                                  <TableCell ><input type='number' name='bulan18' onChange={handleChangeKred} style={{width:'6rem'}}/></TableCell>
                                  <TableCell ><input type='number' name='bulan24' onChange={handleChangeKred} style={{width:'6rem'}}/></TableCell>
                                  <TableCell ><input type='number' name='bulan36' onChange={handleChangeKred} style={{width:'6rem'}}/></TableCell>
                                  <TableCell><Button variant="contained" color="primary" onClick={(e)=>handleEditKred(cred,e)}>Edit</Button>
                                              
                                  </TableCell>
                                </TableRow> 
                                )
                              :
                                ( <TableRow>
                                    <TableCell>{formatter.format(cred.dp)}</TableCell>
                                    <TableCell>{formatter.format(cred.bulan12)}</TableCell>
                                    <TableCell>{formatter.format(cred.bulan18)}</TableCell>
                                    <TableCell>{formatter.format(cred.bulan24)}</TableCell>
                                    <TableCell>{formatter.format(cred.bulan36)}</TableCell>
                                    <TableCell><button onClick={()=>setEditKred(cred.id)}>Edit</button>
                                                <button variant="contained" color="primary" onClick={(e)=>handleDeletKred(cred,e)}>Delete</button>
                                    </TableCell><br/><br/>
                                  </TableRow>
                                )
                              }
                              </>
                              )}
                        
                              
                            )
                          }
                        
                    <TableRow>
                        <TableCell ><input type='number' name='dp'  onChange={handleChangeKred} style={{width:'6rem'}}/></TableCell>
                        <TableCell ><input type='number' name='bulan12' onChange={handleChangeKred} style={{width:'6rem'}}/></TableCell>
                        <TableCell ><input type='number' name='bulan18' onChange={handleChangeKred} style={{width:'6rem'}}/></TableCell>
                        <TableCell ><input type='number' name='bulan24' onChange={handleChangeKred} style={{width:'6rem'}}/></TableCell>
                        <TableCell ><input type='number' name='bulan36' onChange={handleChangeKred} style={{width:'6rem'}}/></TableCell>
                        <TableCell><Button variant="contained" color="primary" onClick={(e)=>handleSubmitKredit(e)}>Add</Button></TableCell>
                    </TableRow>
                </TableBody>

              </Table>
              
              <Button variant="contained" color="primary" onClick={(e)=>handleEditProp(e)}>
                  Submit
              </Button>
        </Box>
      </Modal>

      <Modal
      open={openNew}
      onClose={()=>setOpenNew(!openNew)}
      aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{overflow:"auto", top:"0"}}
      >
        <Box sx={style} style={{textAlign:"center"}}>
          <span style={{fontWeight:"bolder", fontSize:"1.5rem"}}>Add New Proposal</span>
              <div className='containerAddUser'   >
                <TextField style={{width:"100%"}} id="outlined-basic" label="Name" variant="outlined" name='name' onChange={(e)=>setname(e.target.value)}/>
              </div>
              <div className='containerAddUser'>
                <TextField style={{width:"100%"}} id="outlined-basic" label="Description" variant="outlined" name="description" type='text' onChange={(e)=>setdescription(e.target.value)}/>
              </div>
              <div className='containerAddUser'>
                <TextField style={{width:"100%"}} id="outlined-basic" label="Ads Link" variant="outlined" name='ads_link' type='text' onChange={(e)=>setads_link(e.target.value)}/>
              </div>
              <div className='containerAddUser'>
                <TextField style={{width:"100%"}} id="outlined-basic" label="Subsidi" variant="outlined" name='ecgo_subsidy' type='number' onChange={(e)=>setecgo_subsidy(e.target.value)}/>
              </div>
              <div className='containerAddUser'>
                <TextField style={{width:"100%"}} id="outlined-basic" label="OTR Price" variant="outlined" name='otr_price' type='number' onChange={(e)=>setotr_price(e.target.value)}/>
              </div>
              <div className='containerAddUser'>
                <label>Brosur Depan</label> <br/>
                <input type="file" id="myFile" name="brosur_depan" onChange={(e)=>setFile1(e.target.files[0])}/>
              </div>
              <div className='containerAddUser'>
                <label>Brosur Belakang</label> <br/>
                <input type="file" id="myFile" name="brosur_belakang" onChange={(e)=>setFile2(e.target.files[0])}/>
              </div>
              <div className='containerAddUser'>
                <label>Ads File</label> <br/>
                <input type="file" id="myFile" name="ads" onChange={(e)=>setFile3(e.target.files[0])}/>
              </div>
              <Button variant="contained" color="primary" onClick={handleSubmitNewProp}>
                  Submit
              </Button>
        </Box>
      </Modal>


        <button type="button"  className='addUserBtn'  onClick={()=>setOpenNew(!openNew)}>Add New Proposal</button>
        <Paper>
        <Table >
       
            <TableRow >
                <TableCell style={{fontWeight:"bold"}} className='tid'>No</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Name</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Desc</TableCell>
                <TableCell style={{fontWeight:"bold"}}>OTR Price</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Subsidi</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Status</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Gambar</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Ad Link</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Action</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Edit</TableCell>
            </TableRow>
        
           
            <TableBody>
                {proposal?.map((ad)=>{
                    return (
                        <>
                            <TableRow>
                                <TableCell >{ad.id}</TableCell>
                                <TableCell >{ad.name}</TableCell>
                                <TableCell >{ad.description}</TableCell>
                                <TableCell >{formatter.format(ad.otr_price)}</TableCell>
                                <TableCell >{formatter.format(ad.ecgo_subsidy)}</TableCell>
                                <TableCell >{ad.status}</TableCell>
                                <TableCell >
                                  <form action={`https://ecgobikeid.com${ad.brosur_depan}`} method="get" target="_blank">
                                    <button type='submit'>Brosur Dpn</button>
                                   </form>
                                   <form action={`https://ecgobikeid.com${ad.brosur_belakang}`} method="get" target="_blank">
                                    <button type='submit'>Brosur Blkg</button>
                                   </form>
                                   <form action={`${ad.ads_link}`} method="get" target="_blank">
                                    <button type='submit'>Ads</button>
                                   </form>
                                </TableCell>
                                <TableCell>{ad.ads_link}</TableCell>
                                <TableCell >
                                  {ad.status === 'active' ? <button onClick={(e)=>handleDelete(ad,e)}>Deactivate</button> 
                                  : <button  onClick={(e)=>handleActive(ad,e)}>Activate</button>
                                  }
                                </TableCell>
                                <TableCell ><button onClick={(e)=>handleEdit(ad,e)}>Edit</button></TableCell>
                            </TableRow>
                        </>
                    )
                })}
            </TableBody>
           
        </Table>
        </Paper>
    </div>
  )
}

export default CreateProposal