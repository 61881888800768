const defaultState = {
    data: [],
    page: 0,
    export:[],
    mode:false,
  };
  
  export default (state = defaultState, action) => {
    switch (action.type) {
      case "INSERT_DATA_LOW_BATTERY":
        return { ...state, data: [...state.data, ...action.payload] };
      case "INSERT_DATA_FLUSH_LOW_BATTERY": {
        return { ...state, data: action.payload };
      }
      case "INSERT_PAGE_LOW_BATTERY": {
        return { ...state, page: action.payload };
      }
      case 'INSERT_DATA_EXPORT_LOW_BATTERY':{
        return{...state, export:[...state.export,...action.payload]}
      }
      case 'FETCH_STATUS_LOW_BATTERY' : {
        return {...state, mode:action.payload}
      }  
      default:
        return state;
    }
  };
  