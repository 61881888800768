let value = 0

const incrementLoading = (props) =>{
    const interval = setInterval(()=>{
        if(value>95){return clearInterval(interval)}
        props.changeProgress(value+0.3)
        value+=0.3
    },300)
}

export const startLoading = (props)=>{
    return new Promise((resolve)=>{
        props.changeProgress(0)
        value=0
        props.showBar(true)
        setTimeout(()=>{
            props.changeProgress(20)
            value = 20
            incrementLoading(props)
            resolve()
        },200)
    })
}

export const finishLoading= (props)=>{
    return new Promise((resolve,reject)=>{
        setTimeout(()=>{
            props.changeProgress(100)
            value =100
            setTimeout(()=>{
                props.showBar(false)
                props.changeProgress(0)
                return resolve()
            },500)
        },200)
    })
}