import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom'
import DayJs from 'dayjs'
import Utils from '../../utils'
import {connect} from 'react-redux'
import {changeProgress,showBar} from '../../action/progressBar'

const useStyles = makeStyles((theme,data) => ({
  root: {
    height: '100vh',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function DetailPendingCustomer(props) {
  const classes = useStyles();
  const data = { ...props.location.state };
  const handleAccept = async event => {
    event.preventDefault();
    try {
      const response = await Utils.Axios(props, "GET", `/admin/acceptuser/${data.id}`,true).catch(e => {throw e})
      alert(response.data.message)
      props.history.push('/pendingcustomer/main')
      window.location.reload(true)
    } catch (error) {
      alert(error.message);
    }
  };

  const handleDecline = async event => {
    event.preventDefault();
    try {
      const response = await Utils.Axios(props, "GET", `/admin/declineuser/${data.id}`,true).catch(e => {throw e})
      alert(response.data.message)
      props.history.push('/pendingcustomer/main')
      window.location.reload(true)
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <div style={{ marginTop:'55px'}}>
        <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} style={{
            backgroundImage:`url(https://ecgobikeid.com/admin/photoid/${data.id})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            }}/>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <div className={classes.paper}>
            <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Pending Customer
            </Typography>
            {fields.map((item,index)=>
              <Grid container>
              <Grid item xs style={{marginTop:'30px'}}>
                  <Typography component="h1" variant="h6">
                      {item.label}
                  </Typography>
              </Grid>
              <Grid item style={{marginTop:'30px'}}>
                  <Typography component="h1" variant="h6">
                      {data[item.name]?data[item.name]:'-'}
                  </Typography>
              </Grid>
              </Grid>  
            )}
                <Grid container>
                <Grid item xs style={{marginTop:'30px'}}>
                    <Typography component="h2" variant="h6">
                        Input Date   :
                    </Typography>
                </Grid>
                <Grid item style={{marginTop:'30px'}}>
                    <Typography component="h1" variant="h6">
                        {DayJs(data.createdAt?data.createdAt:'-').format('DD MMMM YYYY')}
                    </Typography>
                </Grid>
                </Grid>
                <Grid container style={{display:'flex',justifyContent:'center'}}>
                <Grid style={{marginTop:'60px',marginRight:'10px'}}>
                    <Button variant="contained" color="primary" style={{marginRight:'10px'}} onClick={handleAccept}>Accept</Button>
                    <Button variant="contained" color="secondary" style={{marginRight:'10px'}} onClick={handleDecline}>Decline</Button>
                </Grid>
                </Grid>
            </div>
        </Grid>
        </Grid>
    </div>
  );
}

const fields = [
    { name: "name", label: "Name :" },
    { name: "phoneNumber", label: "Phone Number :" },
    { name: "address1", label: "Address :" },
  ];

  const mapStateToProps = state=>{
    return{
      progress:state.ProgressBar.progress,
    }
}

const mapDispatchToProps = dispatch=>{
    return{
      changeProgress:(value)=>{dispatch(changeProgress(value))},
      showBar:(value)=>{dispatch(showBar(value))},
    }
}
  
export default  withRouter(connect(mapStateToProps,mapDispatchToProps)(DetailPendingCustomer));