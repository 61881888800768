const defaultState = {
  data: [],
  page: 0,
  totalPages: 0,
  limit: 20,
  lastPage: 0,
  lastCurrentPage:0,
  lastTotalPages:0,
  export:[],
  mode:false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case "INSERT_DATA_COMMAND":
      return { ...state, data: [...state.data, ...action.payload] };
    case "INSERT_PAGE_COMMAND":
      return { ...state, page: action.payload };
    case "INSERT_TOTAL_PAGE_COMMAND":
      return { ...state, totalPages: action.payload };
    case "INSERT_LAST_PAGE_COMMAND":
      return { ...state, lastPage: action.payload };
    case "LAST_TOTAL_PAGES_COMMAND": {
      return { ...state, lastTotalPages: action.payload };
    }
    case "INSERT_DATA_FLUSH_COMMAND": {
      return { ...state, data: action.payload };
    }
    case "LAST_CURRENT_PAGE_COMMAND": {
      return { ...state, lastCurrentPage: action.payload };
    }
    case 'INSERT_DATA_EXPORT_COMMAND':{
      return{...state, export:[...state.export,...action.payload]}
    }
    case 'FETCH_STATUS_COMMAND' : {
      return {...state, mode:action.payload}
    } 
    default:
      return state;
  }
};
