import React, {useState, useEffect} from 'react'
import {insertData,insertPage,insertLastPage,insertTotalPage, fetchStatus,insertLastCurrentPage, insertDataExport, insertLastTotalPage,insertDataFlush} from '../../action/vehicleTable'
import {changeProgress,showBar} from '../../action/progressBar'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import SearchingField from '../../component/sortingfield'
import Sorting from '../../component/sorting'
import Utils from '../../utils'
import { ExportReactCSV } from '../../component/export'

function VehicleTable(props) {
    const [vehicleDataDisplayed,setVehicleDataDisplayed]=useState([])
    const [loading,setLoading]=useState(true)
    const [canSearch,setCanSearch]=useState(true)
    const [dataVehicleDisplayedSearch,setdataVehicleDisplayedSearch]=useState([])
    const [searchMode,setSearchMode]=useState(false)
    const [sort, setSort] = useState({whereClause:'',searchValue:''})
    const [dataExportVehicle, setDataExportVehicle]= useState([])
    
    useEffect(()=>{setPage()},[])
    useEffect(()=>{setDisplayedData()},[props.currentPage,searchMode])
    useEffect (()=>{setStatus()},[])
    useEffect (()=>{setExportData()},[props.exportingData])

    const setPage=async (page)=>{
        if(!searchMode){
            if (typeof page !== "number") {
              if (!props.data[props.limit * parseInt(props.currentPage) - 1]) await fetch(1)
              setLoading(false);
            } 
            else {
              if (props.data.filter(item => item.page == page).length ==0) return await fetch(page)
              props.insertPage(page);
            }
          }
    } 

    const fetch=async (page)=>{
        try {
            const response = await Utils.Axios(props,'GET',`/cs/vehicle/all?page=${page}&limit=${props.limit}`,true).catch(e=>{throw e})
            props.insertData(dataBuilder(response.data,props.limit,'all'))
            props.insertPage(response.data.page)
            props.insertTotalPage(response.data.totalPages)
        } catch (error) {
            alert(error.message)
        }
    }

    const fetchSearch = async()=>{
        setCanSearch(false)
        try {
          const temp = props.data.filter(item=>item.dataType!=='search')
          props.insertDataFlush(temp)
          const response = await Utils.Axios(props, 'POST',`/cs/vehicle/searchbyuser`,true,{...sort}).catch(e=>{throw e})
          if(!searchMode)props.setLastCurrentPageVehicle(props.currentPage)
          if(!searchMode)props.setLastTotalPagesVehicle(props.totalPages)
          props.insertData(dataBuilderSearch(response.data,'search'))
          props.insertPage(response.data.page)
          props.insertTotalPage(response.data.totalPages)
          setSearchMode(true)
          
        }
        catch(e){alert(e)}
        finally{setCanSearch(true)}
    }
    
    const setDisplayedData=()=>{
        if(props.currentPage==0)return
        const filter = props.data.filter(item=>searchMode?item.page==props.currentPage&&item.dataType=='search':item.page==props.currentPage).map((item,index)=>{
          let finalData ={}
          tableSpec.forEach(element=>{
            if(element.type=='data'){
              const arr = Utils.DataConstructor(item, element.obj,index)
              Object.assign(finalData, {
                  [element.value]: arr
              })
            }
          })
          return finalData 
        })
        searchMode?setdataVehicleDisplayedSearch(filter):setVehicleDataDisplayed(filter)
      }

    const cancelSearch=(e)=>{
        e.preventDefault()
        setVehicleDataDisplayed([])
        const temp = props.data.filter(item=>item.dataType!=='search')
        props.insertDataFlush(temp)
        props.insertTotalPage(props.lastTotalPages)
        props.insertPage(1)
        setSearchMode(false)
    }

    const setStatus =  async() => {
      if (props.status!=true) await fetchExport()
    }

    const fetchExport = async() => {
      try {
        const response = await Utils.Axios(props,'POST', `/system/crud/read`, true, {dbname:"vehicle",
        include:[
          {dbname:'vehicleType',include:[{dbname:'vehicleColor'}],include:[{dbname:'vehicleModel'}]},
          {dbname:'vehicleOwnership',include:[{dbname:'user'}]}
        ]
      })
        props.insertDataExport(dataBuilderSearch(response.data))
        props.fetchStatus(true)
      }
      catch(e){alert(e)}
    }
  
    const setExportData=()=>{
      const filter = props.exportingData.map((item,index)=>{
        let finalData ={}
        tableSpec.forEach(element=>{
          if(element.type==='data'){
            const arr = Utils.DataConstructor(item, element.obj,index)
            Object.assign(finalData, {
                [element.value]: arr
            })
          }
        })
        return finalData
        
      })
      setDataExportVehicle(filter)
    }

    const handleChangePage=(data)=>{setPage(parseInt(data.selected)+1)}

    const dataBuilder=(data,limit,dataType)=> data.data.map((item,index)=>{return {...item,page:data.page,dataType,index:((data.page-1)*limit)+index+1}})
    const dataBuilderSearch=(data,dataType)=> data.data.map((item,index)=>{return {...item,page:data.page,dataType,index:index+1}})

    return(
        <div>
            <div style={{flexDirection:'row',display:'flex', marginTop:'55px'}}>
              <ExportReactCSV csvData={dataExportVehicle} fileName='VehicleTable.csv' />
                <Sorting sort={sort} setSort={setSort} sortBy={sortingItem}/>
                <div style={{flex:1}}>  
                <SearchingField  props={props} canSearch={canSearch} fetchSearch={fetchSearch} setSort={setSort} sort={sort}cancelSearch={cancelSearch} searchMode={searchMode}/>
                </div>
            </div>
            <div style={{flexDirection:'row',display:'flex',marginTop:'15px', alignItems:'center'}}>
            {searchMode?<Utils.Table dataDisplayed={dataVehicleDisplayedSearch} tableSpec={tableSpec} />:<Utils.Table dataDisplayed={vehicleDataDisplayed} tableSpec={tableSpec} />}
            </div>
            {!loading&&!searchMode&&<Utils.Pagination totalPages={props.totalPages}  props={props} handleChangePageWithNumber={handleChangePage} />}
        </div>
    )    
}

const sortingItem = [
  {sortBy:'Name'},
  {sortBy:'phoneNumber'}
]

const tableSpec=[
    {Title:'No',obj:['index'],value:'index',shown:true,type:'data'},
    {Title:'id',obj:['id'],value:'id',shown:false,type:'data'},
    {Title:'Data Type',obj:['dataType'],value:'dataType',shown:false,type:'data'},
    {Title:'Rangka Code',obj:['rangka'],value:'rangka',shown:true,type:'data'},
    {Title:'Dinamo Code',obj:['dinamo'],value:'dinamo',shown:true,type:'data'},
    {Title:'Vehicle Model',obj:['vehicleType','vehicleModel','name'],value:'vehicleModel',shown:true,type:'data'},
    {Title:'Vehicle Color',obj:['vehicleType', 'vehicleColor','name'],value:'color',shown:true,type:'data'},
    {Title:'Controller Number', obj:['controllerNumber'],value:'controllerNumber',shown:true,type:'data'},
    {Title:'No Polisi',obj:['nopol'],value:'nopol',shown:true,type:'data'},
    {Title:'Garansi',obj:['garansi'],value:'garansi',shown:true,type:'data'},
    {Title:'User Name',obj:['vehicleOwnership','user','name'],value:'name',shown:true,type:'data'},
    {Title:'Phone Number',obj:['vehicleOwnership','user','phoneNumber'],value:'phoneNumber',shown:false,type:'data'},
    {Title:'Email',obj:['vehicleOwnership','user','email'],value:'email',shown:false,type:'data'},
  ]

  const mapStateToProps=(state)=>{
    return{
        progress:state.ProgressBar.progress,
        data:state.Vehicle.data,
        currentPage:state.Vehicle.page,
        totalPages:state.Vehicle.totalPages,
        lastPage:state.Vehicle.lastPage,
        limit:state.Vehicle.limit,
        lastCurrentPage:state.Vehicle.lastCurrentPage,
        lastTotalPages:state.Vehicle.lastTotalPages,
        exportingData:state.Vehicle.export,
        status:state.Vehicle.mode,
    }
}

const mapDispatchToProps=(dispatch)=>{
    return{
        changeProgress:(load)=>{dispatch(changeProgress(load))},
        showBar:(bool)=>{dispatch(showBar(bool))},
        insertData:(data)=>{dispatch(insertData(data))},
        insertPage:(page)=>{dispatch(insertPage(page))},
        insertLastPage:(page)=>{dispatch(insertLastPage(page))},
        insertTotalPage:(totalPage)=>{dispatch(insertTotalPage(totalPage))},
        insertDataFlush:(value,type)=>{dispatch(insertDataFlush(value,type))},
        setLastCurrentPageVehicle:(value)=>{dispatch(insertLastCurrentPage(value))},
        setLastTotalPagesVehicle:(value)=>{dispatch(insertLastTotalPage(value))},
        insertDataExport:(value)=>{dispatch(insertDataExport(value))},
        fetchStatus:(value)=>{dispatch(fetchStatus(value))},
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(VehicleTable))