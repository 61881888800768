const defaultState = {
    mode:false
  }
  
  export default (state=defaultState,action) => {
    switch(action.type){
      case 'SEARCH_MODE' : {
        return {...state, mode:action.payload}
      }
      default: return state
    }
  }