import React from "react";
import { LinearProgress } from "@material-ui/core";
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles'
import {indigo} from '@material-ui/core/colors'
import './css/progressBar.css'
import {useSelector} from 'react-redux'
const theme = createMuiTheme({
  palette :{
    primary: indigo
  },
})


export default function ProgressBar() {
  const stateProps = useSelector(state=>{return {progress:state.ProgressBar.progress,showBar:state.ProgressBar.showBar}})
  return (
    <div className={'progressBar'}>
      {stateProps.showBar&&<MuiThemeProvider theme={theme}>
        <LinearProgress variant="determinate" value={stateProps.progress}  />
      </MuiThemeProvider>}
    </div>
  );
}
