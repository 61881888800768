import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios'
import Table from '@material-ui/core/Table';
import Modal from '@material-ui/core/Modal';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import BeatLoader from "react-spinners/BeatLoader";
import ReactPaginate from 'react-paginate';

const Comment = () => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      }
    const formatter = new Intl.NumberFormat('id', {
        style:'currency',
        currency:'IDR',
        maximumFractionDigits: 0
      })
    const [withdraw, setWithdraw] = useState()
    const [detail, setDetail] = useState([])
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    
    const [pageCount, setpageCount] = useState()
    const permissions = localStorage.getItem('permissions')
    
    function getData (currentPage) {
        
            axios.get(`${process.env.REACT_APP_BASEURL}/admin/evmoto-comment?perPage=10&currentPage=${currentPage}&orderBy=id&dir=desc`, {
                headers : {
                    'token' : localStorage.getItem('token')
                }})
            .then((res)=>
                {
                    setpageCount(res.data.data.page)
                    setWithdraw(res.data.data)
                }
            )
         
    }

    // function getDetail (e,ad) {
    //     setOpen2(!open)
    //         axios.get(`${process.env.REACT_APP_BASEURL}/withdraw/detail?withdrawId=${ad.id}`, {
    //             headers : {
    //                 Authorization: `Bearer ${localStorage.getItem('token')}`
    //             }})
    //         .then((res)=>
    //             {   
    //                 setpageCount(res.data.data.page)
    //                 setDetail(res.data.data)
    //             }
    //         )
        
    // }
    
    // function handleProcess(e, ad){
    //     e.preventDefault()
    //     axios.post(`${process.env.REACT_APP_BASEURL}/withdraw/processing`,{
    //         withdrawId: ad.id
    //     }, {
    //         headers : {
    //             Authorization: `Bearer ${localStorage.getItem('token')}`
    //         }})
    //     .then((res)=>
    //         {
    //         getData()    
    //         }
    //     )
    // }

    // function handleComplete(e, ad){
    //     e.preventDefault()
    //     axios.post(`${process.env.REACT_APP_BASEURL}/withdraw/completed`,{
    //         withdrawId: ad.id
    //     }, {
    //         headers : {
    //             Authorization: `Bearer ${localStorage.getItem('token')}`
    //         }})
    //     .then((res)=>
    //         {
    //         getData()    
    //         }
    //     )
    // }

    // function handleReject(e, ad){
    //     e.preventDefault()
    //     axios.post(`${process.env.REACT_APP_BASEURL}/withdraw/rejected`,{
    //         withdrawId: ad.id
    //     }, {
    //         headers : {
    //             Authorization: `Bearer ${localStorage.getItem('token')}`
    //         }})
    //     .then((res)=>
    //         {
    //         getData()    
    //         }
    //     )
    // }

    // function handleApprove(e, ad){
    //     e.preventDefault()
    //     axios.post(`${process.env.REACT_APP_BASEURL}/withdraw/approving`,{
    //         withdrawId: ad.id
    //     }, {
    //         headers : {
    //             Authorization: `Bearer ${localStorage.getItem('token')}`
    //         }})
    //     .then((res)=>
    //         {
    //         getData()    
    //         }
    //     )
    // }

    const  handlePageClick = async (data)=>{

        let currentPage = data.selected + 1

        const getDataPerPage =  await getData(currentPage)

        setWithdraw(getDataPerPage)
    }

    // const handleModal=(ad, action)=>{
    //     setOpen(!open)
    //     console.log(action)
    //     return(
    //         <Modal 
    //         open={true}
    //         onClose={()=>setSelected(null)}
    //         aria-labelledby="modal-modal-title"
    //         aria-describedby="modal-modal-description"
    //         style={{overflow:"auto", top:"0"}}
    //         >
    //             <Box sx={style} style={{textAlign:"center"}}>
    //                 <h2>Are you sure you want to proceed the action?</h2>
    //                     {
    //                         action === 1 ? (
    //                             <>
    //                             <button onClick={(e)=>handleApprove(e,ad)}>Approve</button>
    //                             <button onClick={(e)=>handleReject(e,ad)}>Reject</button>
    //                             </>
    //                         ) : action === 2 ? (
    //                             <button onClick={(e)=>handleProcess(e,ad)}>Process</button>
    //                         ) : action === 3 && (
    //                             <button onClick={(e)=>handleComplete(e,ad)}>Complete</button>
    //                         )
    //                     } &nbsp;
    //                     <button onClick={()=>setSelected(null)}>Close</button>
    //             </Box>
    //         </Modal>
    //     )
    // }

    const [selected, setSelected] = useState()

    useEffect(()=>{
        
            axios.get(`${process.env.REACT_APP_BASEURL}/admin/evmoto-comment?perPage=10&currentPage=1&orderBy=id&dir=desc`, {
                headers : {
                    'token': `${localStorage.getItem('token')}`
                }})
            .then((res)=>
                {   
                    setpageCount(res.data.data.page)
                    setWithdraw(res.data.data)
                }
            )
            
    },[])

    
  return (
    <div style={{paddingTop:'5rem'}}>
    
    <Paper>
        <Table >
        
            <TableRow >
                <TableCell style={{fontWeight:"bold"}} className='tid'>ID</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Name</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Tanggal</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Rating</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Phone</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Comments</TableCell>
            </TableRow>
        
            {
                 
            <TableBody>
                {withdraw?.map((ad)=>{
                    return (
                        <>
                            <TableRow key={ad.id}>
                                <TableCell >{ad.id}</TableCell>
                                <TableCell >{ad.name}</TableCell>
                                <TableCell >{ad.createdAt}</TableCell>
                                <TableCell >{ad.rate} </TableCell>
                                <TableCell >{ad.phoneNumber}</TableCell>
                                <TableCell >{ad.notes}</TableCell>
                            </TableRow>
                        </>
                    )
                })}
            </TableBody>
            }

        </Table>
    </Paper>  
    
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
        containerClassName={'pagination'}
        pageClassName={'pageContainer'}
        pageLinkClassName={'changePageButton'}
        activeClassName={'active'}
      />
  </div>
  )
}

export default Comment