import React,{useState,useEffect} from 'react'
import { insertData, insertPage, insertLastPage, insertTotalPage, insertLastCurrentPage, insertDataExport, insertLastTotalPage,insertDataFlush} from '../../../action/batteryDetailTable/gpsTable'
import {changeProgress,showBar} from '../../../action/progressBar'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import Utils from '../../../utils'
import dayjs from 'dayjs'
import { TableCell} from '@material-ui/core'
import { LocationOn } from '@material-ui/icons';

function GpsTable(props) {
    const data = props.location.pathname.split('/')[3]
    const [dataDisplayed,setDataDisplayed]=useState([])
    const [dataGpsDisplayedSearch,setdataGpsDisplayedSearch]=useState([])
    const [searchMode,setSearchMode]=useState(false)
    const [loading,setLoading]=useState(true)
    const [batteryId] = useState({whereClause:data}) 


    useEffect(()=>{setPage()},[])
    useEffect(()=>{setDisplayedData()},[props.currentPage,searchMode,props.data])

    const setPage=async (page)=>{
        if(!searchMode){
            if (typeof page !== "number") {
              if (props.data.filter(item => item.page == 1&&item.batteryId==data).length ==0) await fetch(1)
              setLoading(false);
            } 
            else {
              if (props.data.filter(item => item.page == page&&item.batteryId==data).length ==0) return await fetch(page)
              props.insertPage(page);
            }
          }
    }
    const fetch=async (page)=>{
        try {
            const response = await Utils.Axios(props,'POST',`/internal/newbattery/Gps?page=${page}&limit=${props.limit}&order=DESC`,true,{...batteryId}).catch(e=>{throw e})
            props.insertData(dataBuilder(response.data,props.limit,'all'))
            props.insertPage(response.data.page)
            props.insertTotalPage(response.data.totalPages)
        } catch (error) {
            alert(error.message)
        }
    }

    const setDisplayedData=()=>{
        if(props.currentPage==0)return
        const filter = props.data.filter(item=>searchMode?item.page==props.currentPage&&item.dataType=='search':item.page==props.currentPage&&item.batteryId==data).map((item,index)=>{
          let finalData ={}
          tableSpec.forEach(element=>{
            if(element.type=='data'){
              const arr = Utils.DataConstructor(item, element.obj,index)
              Object.assign(finalData, {
                  [element.value]: arr
              })
            }
          })
          return finalData 
        })
        searchMode?setdataGpsDisplayedSearch(filter):setDataDisplayed(filter)
      }

      const actionColumn = ({row,item})=>{
        const navigate =(e)=>{
            e.preventDefault()
            props.insertLastPage(props.currentPage)
            props.history.push({pathname:`/battery/mapmarker/${row.id}`,state:{...row}})
        }

        
        return(<TableCell align='center'>
        <div className='tooltip'>
          <LocationOn style={{ cursor:'pointer' ,marginRight:'5px'}} onClick={navigate} />
          <span className='tooltiptextuser'>Location</span>
        </div>
        </TableCell>)
    }



    const tableSpec=[
      {Title:'No',obj:['index'],value:'index',shown:true,type:'data'},
      {Title:'id',obj:['id'],value:'id',shown:false,type:'data'},
      {Title:'Data Type',obj:['dataType'],value:'dataType',shown:false,type:'data'},
      {Title:'Longitude',obj:['longitude'],value:'longitude',shown:true,type:'data'},
      {Title:'Latitude',obj:['latitude'],value:'latitude',shown:true,type:'data'},
      {Title:'BatteryId',obj:['batteryId'],value:'batteryId',shown:false,type:'data'},
      {Title:'SNR',obj:['snr'],value:'snr',shown:true,type:'data'},
      {Title:'Speed',obj:['speed'],value:'speed',shown:true,type:'data'},
      {Title:'Course',obj:['course'],value:'course',shown:true,type:'data'},
      {Title:'Signal Strength',obj:['signalStrength'],value:'signalstrength',shown:true,type:'data'},
      {Title:'Time Stamp',obj:['createdAt'],value:'createdAt',shown:true,type:'data'},
      {shown:true,type:'action',component:actionColumn}
    ]

    const handleChangePage=(data)=>{setPage(parseInt(data.selected)+1)}
    const dataBuilder=(data,limit,dataType)=> data.data.map((item,index)=>{return {...item,page:data.page,dataType,createdAt:dayjs(item.createdAt).format('DD MMMM YYYY HH:mm'),index:((data.page-1)*limit)+index+1}})

    return(
        <div>
        <div style={{flexDirection:'row',display:'flex',marginTop:'15px', alignItems:'center'}}>
            {searchMode?<Utils.Table dataDisplayed={dataGpsDisplayedSearch} tableSpec={tableSpec} />:<Utils.Table dataDisplayed={dataDisplayed} tableSpec={tableSpec} />}
            </div>
            {!loading&&!searchMode&&<Utils.Pagination totalPages={props.totalPages}  props={props} handleChangePageWithNumber={handleChangePage} />}
        </div>
    )
}


const mapStateToProps=(state)=>{
    return{
        progress:state.ProgressBar.progress,
        data:state.Gps.data,
        currentPage:state.Gps.page,
        totalPages:state.Gps.totalPages,
        lastPage:state.Gps.lastPage,
        limit:state.Gps.limit,
        lastCurrentPage:state.Gps.lastCurrentPage,
        lastTotalPages:state.Gps.lastTotalPages,
        exportingData:state.Gps.export,
    }
}

const mapDispatchToProps=(dispatch)=>{
    return{
        changeProgress:(load)=>{dispatch(changeProgress(load))},
        showBar:(bool)=>{dispatch(showBar(bool))},
        insertData:(data)=>{dispatch(insertData(data))},
        insertPage:(page)=>{dispatch(insertPage(page))},
        insertLastPage:(page)=>{dispatch(insertLastPage(page))},
        insertTotalPage:(totalPage)=>{dispatch(insertTotalPage(totalPage))},
        insertDataFlush:(value,type)=>{dispatch(insertDataFlush(value,type))},
        setLastCurrentPageGps:(value)=>{dispatch(insertLastCurrentPage(value))},
        setLastTotalPagesGps:(value)=>{dispatch(insertLastTotalPage(value))},
        insertDataExport:(value)=>{dispatch(insertDataExport(value))},
    }
}
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(GpsTable))