export const insertDataBattery = (payload) =>{
    // if(type&&type==='flush') return {type:'insertDataFlush',payload}
    return {
      type:'INSERT_DATA_BATTERY',
      payload
    }
  }
  
  export const insertDataFlushBattery=(payload)=>{
    return {type:'INSERT_DATA_FLUSH_BATTERY',payload}
  }
  
  export const changePageBattery = payload=>{
    return {type:'CHANGE_PAGE_BATTERY',payload}
  }
  
  export const lastTotalPageBattery=payload=>{
    return {type:'LAST_TOTAL_PAGES_BATTERY',payload}
  }
  
  export const lastCurrentPageBattery=payload=>{
    return {type:'LAST_CURRENT_PAGE_BATTERY',payload}
  }
  
  export const totalPageBattery = payload =>{
    return {type:'TOTAL_PAGE_BATTERY',payload}
  }
  export const insertDataExport = (payload)=> {
    return{type:'INSERT_DATA_EXPORT_BATTERY', payload}
  }
  export const fetchStatus = payload =>{
    return {
      type:'FETCH_STATUS_BATTERY',
      payload
    }
  }