import React, { useEffect } from "react";
import Downshift from "downshift";
import Paper from "@material-ui/core/Paper";
import "../../../../css/addUser.css";
import { TextField, MenuItem } from "@material-ui/core";

export default ({findParameter,classesDownshift,stateKey,find,searchValue,setBatteryDealerDetail,batterydealerdetail,setSearchValue,suggestions, placeholder})=>
    <Downshift id="downshift-simple">
        {({
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        highlightedIndex,
        inputValue,
        isOpen,
        selectedItem,
        }) => {
        const { onBlur, onFocus, ...inputProps } = getInputProps({
            placeholder: `insert ${placeholder.name}`
        });

        return (
            <div className={classesDownshift.container}>
                <RenderInput 
                fullWidth={true} 
                classesDownshift={classesDownshift} 
                label={`${placeholder.name}`}
                InputLabelProps= {getLabelProps({ shrink: true })}
                InputProps= { {onBlur, onFocus} }
                inputProps={inputProps}
                searchvalue ={searchValue}
                setsearchvalue ={setSearchValue}
                selecteditem={selectedItem}
                suggestions={suggestions}
                setbatterydealerdetail={setBatteryDealerDetail}
                batterydealerdetail={batterydealerdetail}
                statekey={stateKey}
                find={find}
                findparameter={findParameter}
                />

                <div {...getMenuProps()}>
                    {isOpen ? (
                    <Paper className={classesDownshift.paper} square>
                        {
                        suggestions&&getSuggestions(inputValue,suggestions).map((suggestion, index) =>
                        <RenderSuggestion 
                        suggestion={suggestion}
                        index={index} 
                        itemProps={placeholder.name==='Phone Number'?(getItemProps({ item: suggestion.phoneNumber })):(getItemProps({ item: suggestion.bmsId }))} 
                        highlightedIndex={highlightedIndex}
                        selecteditem={selectedItem}
                        getItemProps={getItemProps}
                        />
                        )}
                    </Paper>
                    ) : null}
                </div>
            </div>
        );
        }}
    </Downshift>

function getSuggestions(value,suggestions, { showEmpty = false } = {}) {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
  
    return inputLength === 0 && !showEmpty
      ? []
      : suggestions
  }
  
  function RenderInput(inputProps) {
    useEffect(()=>{if(inputProps.selecteditem)setSelecteditem()},[inputProps.selecteditem])
  
    const setSelecteditem=async()=>{
      const find = inputProps.suggestions.find(item=>item[inputProps.find] === inputProps.selecteditem)
      inputProps.setbatterydealerdetail({...inputProps.batterydealerdetail,[inputProps.statekey]:find[inputProps.findparameter]})
    }
  
    const { InputProps, classesDownshift, ref, ...other } = inputProps;
    const setInputValue=(e)=>{inputProps.setsearchvalue(e.target.value)}
  
    return (
      <TextField
        InputProps={{
          inputRef: ref,
          classes: {
            root: classesDownshift.inputRoot,
            input: classesDownshift.inputInput
          },
          ...InputProps
        }}
        {...other}
        onChange={setInputValue}
      />
    );
  }
  
  const RenderSuggestion=(suggestionProps)=> {
    const {
      suggestion,
      index,
      itemProps,
      highlightedIndex,
      selecteditem,
    } = suggestionProps;
  
    const isHighlighted = highlightedIndex === index;
    const isSelected = (selecteditem || "").indexOf(suggestion.label) > -1;
    
    return (
      <MenuItem
        {...itemProps}
        key={suggestion.id}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400
        }}
        >
      {suggestion.phoneNumber?(`${suggestion.phoneNumber} ${suggestion.name}`):(`${suggestion.bmsId}`)}
      </MenuItem>
    );
  }