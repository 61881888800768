import React from 'react'
import axios from 'axios'
import Table from '@material-ui/core/Table';
import Modal from '@material-ui/core/Modal';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import ReactPaginate from 'react-paginate';
import { Box } from '@material-ui/core';
import { MultiSelect } from "react-multi-select-component";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';



export default function Paket() {
    const [withdraw, setWithdraw] = useState()
    const [pageCount, setpageCount] = useState()
    const [paket, setPaket] = useState()
    const [isOpen, setIsOpen] = useState(false)
    const [isOpen2, setIsOpen2] = useState(false)
    const [name, setName] = useState()
    const [status, setStatus] = useState()
    const [midDP, setMinDP] = useState()
    const [editid, seteditid] = useState()
    const [min_point, setMinP] = useState()
    const [max_point, setMaxP] = useState()
    const [min_pointa, setMinPa] = useState()
    const [max_pointa, setMaxPa] = useState()
    const [min_pointb, setMinPb] = useState()
    const [max_pointb, setMaxPb] = useState()
    const [params, setParams] = useState([])
    const [params1, setParams1] = useState([{}])
    const [edit1, setEdit1] = useState([{}])
    const [edit, setedit] = useState()
    const animatedComponents = makeAnimated();
    
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const formatter = new Intl.NumberFormat('id', {
    style:'currency',
    currency:'IDR',
    maximumFractionDigits: 0
    })
    
    const getData =() =>{
    
    axios.get(`https://ecgobikeid.com/newAdmin/appraisal/package/?currentPage=1&orderBy=id&dir=desc&perPage=all`, {
        headers : {
            'token' : localStorage.getItem('token')
        }})
    .then((res)=>
        {
            setpageCount(res.data.data.count)
            setWithdraw(res.data.data.rows)
        }
    )
    }
         
    function handleCreatePaket(){
       
        axios.get(`https://ecgobikeid.com/newAdmin/appraisal/package/create`, {
            headers : {
                'token' : localStorage.getItem('token')
            }})
        .then((res)=>
            {
              
                setPaket(res.data)
                let hehe =  res.data.params.map((items)=>{
                    return{value: items.id, label:`${items.code} - ${items.name}`}
                })
               setParams1(hehe)
            }
        )
    
    }

    const [checkedState, setCheckedState] = useState(
        new Array(paket?.params?.length).fill(false)
    );

    const handleOnChange = (idx) => {
        const updatedCheckedState = checkedState.map((item, index) =>
          index === idx ? !item : item
        )
        setCheckedState(updatedCheckedState);
        let hehe =  new Array;
        const totalPrice = updatedCheckedState.reduce(
            (sum, currentState, index) => {
              if (currentState === true) {
               hehe.push(paket?.params[index].id)
               
              }
            setParams(hehe)  
            },
          );
    }

    function handleSubmit(){
      let hehe = params.map((items)=>{
        return(
            items.value
        )
      })
        axios.post(`https://ecgobikeid.com/newAdmin/appraisal/package/`,{
            name:name,
            status:status,
            min_dp:midDP,
            min_point:min_point,
            max_point:max_point,
            min_point_a:min_pointa,
            max_point_a:max_pointa,
            min_point_b:min_pointb,
            max_point_b:max_pointb,
            req_params:hehe
        },{
            headers : {
                'token' : localStorage.getItem('token')
            }})
       .then(() =>{
        setIsOpen(!isOpen)
        setParams([])
        getData()
       }
       )
    
    }
    
    function handleSubmitEdit(){
            setIsOpen(false)
            axios.put(`https://ecgobikeid.com/newAdmin/appraisal/package/${editid}`,{
                name:name,
                status:status,
                min_dp:midDP,
                min_point:min_point,
                max_point:max_point,
                min_point_a:min_pointa,
                max_point_a:max_pointa,
                min_point_b:min_pointb,
                max_point_b:max_pointb,
                req_params:params
            },{
                headers : {
                    'token' : localStorage.getItem('token')
                }})
            .then((res)=>{
                alert('Paket berhasil di edit')
                setParams([])
                getData()
            }
            )  
    }

    const handleEdit =(ad)=>{
       setedit()
  
    handleCreatePaket()
    setIsOpen(false)
    seteditid(ad.id)
        axios.get(`https://ecgobikeid.com/newAdmin/appraisal/package/${ad.id}/edit`, {
            headers : {
                'token' : localStorage.getItem('token')
            }})
        .then((res)=>
            {
                setedit(res.data)
                let hehe = res.data.data.rule.map((items)=>{
                    return{value: items.id, label:`${items.code} - ${items.name}`}
                })
                setEdit1(hehe)
                setIsOpen2(true)
            }
        )
    }
    
    function handleSubmitEdit(){
        setIsOpen(false)
        axios.put(`https://ecgobikeid.com/newAdmin/appraisal/package/${editid}`,{
            name:name,
            status:status,
            min_dp:midDP,
            min_point:min_point,
            max_point:max_point,
            min_point_a:min_pointa,
            max_point_a:max_pointa,
            min_point_b:min_pointb,
            max_point_b:max_pointb,
            req_params:params
        },{
            headers : {
                'token' : localStorage.getItem('token')
            }})
        .then((res)=>{
            alert('Paket berhasil di edit')
            setParams([])
            getData()
        }
        )  
    }

    function handleActive(ad){
        axios.post(`https://ecgobikeid.com/newAdmin/appraisal/package/${ad.id}/active`,{},{
            headers : {
                'token' : localStorage.getItem('token')
            }})
            .then((res)=>(
                getData()
            ))
        
    }

    function handleDeactive(ad){
        axios.post(`https://ecgobikeid.com/newAdmin/appraisal/package/${ad.id}/inactive`,{},{
            headers : {
                'token' : localStorage.getItem('token')
            }})
            .then((res)=>(
                getData()
            ))
        
    }

    function handleDelete(ad){
        axios.post(`https://ecgobikeid.com/newAdmin/appraisal/package/${ad.id}/delete`,{},{
            headers : {
                'token' : localStorage.getItem('token')
            }})
            .then((res)=>(
                getData()
            ))
        
    }

    useEffect(()=>{
        getData()
        handleCreatePaket()
    },[])

    useEffect(()=>{
        if(edit){
            setName(edit?.data.name)
            setMinDP(edit?.data.min_dp)
            setMinP(edit?.data.min_point)
            setMaxP(edit?.data.max_point)
            setMinPa(edit?.data.min_point_a)
            setMaxPa(edit?.data.max_point_a)
            setMinPb(edit?.data.min_point_b)
            setMaxPb(edit?.data.max_point_b)
            setStatus(edit?.data.status)
            setParams(edit.data?.rule.map((item)=>item.id))
        }
    },[edit])

    useEffect(()=>{
       setCheckedState( new Array(paket?.params?.length).fill(false))
    },[paket,edit])

  return (
    <div style={{padding:'5rem 0 0 0 '}}>
    <Modal
        open={isOpen}
        onClose={()=>setIsOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{overflow:"auto", top:"0"}}
      >
        <Box sx={style} style={{display:'flex', gap:'2rem', flexDirection:'column'}}>
        <div style={{display:'flex', flexDirection:'column'}}>
            <label>Name :</label>
            <input type='text' onChange={(e)=>setName(e.target.value)}/>
        </div>
        <div style={{display:'flex', flexDirection:'column'}}>
            <label>Minimum DP :</label>
            <input type='text' onChange={(e)=>setMinDP(e.target.value)}/>
        </div>
        <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap', gap:'2rem'}}>
            <div style={{display:'flex', flexDirection:'column'}}>
                <label>Minimum Point :</label>
                <input type='text' onChange={(e)=>setMinP(e.target.value)}/>
                <label>Maximum Point :</label>
                <input type='text' onChange={(e)=>setMaxP(e.target.value)}/>
            </div>
            
            <div style={{display:'flex', flexDirection:'column'}}>
                <label>Minimum Point A:</label>
                <input type='text' onChange={(e)=>setMinPa(e.target.value)}/>
                <label>Maximum Point A:</label>
                <input type='text' onChange={(e)=>setMaxPa(e.target.value)}/>
            </div>
           
            <div style={{display:'flex', flexDirection:'column'}}>
                <label>Minimum Point B:</label>
                <input type='text' onChange={(e)=>setMinPb(e.target.value)}/>
                <label>Maximum Point B:</label>
                <input type='text' onChange={(e)=>setMaxPb(e.target.value)}/>
            </div>
          
        </div>
       
           
       
        <select onChange={(e)=>setStatus(e.target.value)}>
            <option selected disabled>Status</option>
            {
                paket?.status?.map((item)=>{
                    return(
                        <option value={item}>{item}</option>
                    )
                })
            }
        </select>
        <a>Rules</a>
       
       
        <MultiSelect
             value={params}
             onChange={setParams}
             labelledBy="Select"
             options={params1}
            />
    
        
        <button type="button"  className='addUserBtn' onClick={handleSubmit}>Buat Paket</button>
        </Box>
    </Modal>

      <Modal
        open={isOpen2}
        onClose={()=>{setIsOpen2(false);}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{overflow:"auto", top:"0"}}
      >
        <Box sx={style} style={{display:'flex', gap:'2rem', flexDirection:'column'}}>
        <div style={{display:'flex', flexDirection:'column'}}>
            <label>Name :</label>
            <input type='text' defaultValue={edit?.data.name} onChange={(e)=>setName(e.target.value)}/>
        </div>
        <div style={{display:'flex', flexDirection:'column'}}>
            <label>Minimum DP :</label>
            <input type='text'  defaultValue={edit?.data.min_dp} onChange={(e)=>setMinDP(e.target.value)}/>
        </div>
        <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap', gap:'2rem'}}>
            <div style={{display:'flex', flexDirection:'column'}}>
                <label>Minimum Point :</label>
                <input defaultValue={min_point} type='text' onChange={(e)=>setMinP(e.target.value)}/>
                <label>Maximum Point :</label>
                <input defaultValue={max_point} type='text' onChange={(e)=>setMaxP(e.target.value)}/>
            </div>
            
            <div style={{display:'flex', flexDirection:'column'}}>
                <label>Minimum Point A:</label>
                <input  defaultValue={min_pointa}  type='text' onChange={(e)=>setMinPa(e.target.value)}/>
                <label>Maximum Point A:</label>
                <input defaultValue={max_pointa} type='text' onChange={(e)=>setMaxPa(e.target.value)}/>
            </div>
           
            <div style={{display:'flex', flexDirection:'column'}}>
                <label>Minimum Point B:</label>
                <input  defaultValue={min_pointb}  type='text' onChange={(e)=>setMinPb(e.target.value)}/>
                <label>Maximum Point B:</label>
                <input defaultValue={max_pointb} type='text' onChange={(e)=>setMaxPb(e.target.value)}/>
            </div>
        </div>
        <select onChange={(e)=>setStatus(e.target.value)}>
            <option defaultValue={edit?.data.status}>{edit?.data.status}</option>
            {
                paket?.status?.map((item)=>{
                    return(
                        <option value={item}>{item}</option>
                    )
                })
            }
        </select>
        <a>Rules</a>
        {edit?.data?.rule?.map((items,idx)=>(
            <a>{idx+1}. {items?.code}</a>
        ))}
        
        <MultiSelect
             value={params}
             onChange={setParams}
             labelledBy="Select"
             options={params1}
            />
      <button type="button"  className='addUserBtn' onClick={()=>setParams(null)}>Hapus Rules</button>
        <button type="button"  className='addUserBtn' onClick={handleSubmitEdit}>Edit Paket</button>
        </Box>
      </Modal>
       
       <button type="button"  className='addUserBtn' onClick={()=>{ setIsOpen(true)}}>Buat Paket</button>
   <Paper>
       <Table >
       
           <TableRow >
               <TableCell style={{fontWeight:"bold"}} className='tid'>No</TableCell>
               <TableCell style={{fontWeight:"bold"}}>Date</TableCell>
               <TableCell style={{fontWeight:"bold"}}>Name</TableCell>
               <TableCell style={{fontWeight:"bold"}}>Min DP</TableCell>
               <TableCell style={{fontWeight:"bold"}}>Min Point</TableCell>
               <TableCell style={{fontWeight:"bold"}}>Max Point</TableCell>
               <TableCell style={{fontWeight:"bold"}}>Min Point A</TableCell>
               <TableCell style={{fontWeight:"bold"}}>Max Point A</TableCell>
               <TableCell style={{fontWeight:"bold"}}>Min Point B</TableCell>
               <TableCell style={{fontWeight:"bold"}}>Max Point B</TableCell>
               <TableCell style={{fontWeight:"bold"}}>Status</TableCell>
               <TableCell style={{fontWeight:"bold"}}>Action</TableCell>
           </TableRow>
       
           {        
           <TableBody>
               {withdraw?.map((ad,idx)=>{
                   return (
                       <>
                           <TableRow key={ad.id}>
                               <TableCell >{idx+1}</TableCell>
                               <TableCell >{ad.createdAt.slice(0,10)}</TableCell>
                               <TableCell >{ad.name}</TableCell>
                               <TableCell >{formatter.format(ad.min_dp)}</TableCell>
                               <TableCell >{ad.min_point}</TableCell>
                               <TableCell >{ad.max_point}</TableCell>
                               <TableCell >{ad.min_point_a}</TableCell>
                               <TableCell >{ad.max_point_a}</TableCell>
                               <TableCell >{ad.min_point_b}</TableCell>
                               <TableCell >{ad.max_point_b}</TableCell>
                               <TableCell >{ad.status}</TableCell>
                               <TableCell >{ad.status == 'active' ?  <button type="button" style={{background:'#f25050', borderRadius:'3px', border:'1px solid'}} onClick={()=>handleDeactive(ad)}>Deactive</button> 
                                            :
                                            <button type="button" style={{background:'#61c73c', borderRadius:'3px', border:'1px solid'}} onClick={()=>handleActive(ad)}>Activate</button>
                                            } &nbsp;
                                            <button  onClick={()=>handleEdit(ad)}>Edit</button>
                                            &nbsp;
                                            <button  onClick={()=>handleDelete(ad)}>Delete</button>
                               </TableCell>
                           </TableRow>
                       </>
                   )
               })}
           </TableBody>
           }
       </Table>
   </Paper>  
   
     <ReactPaginate
       breakLabel="..."
       nextLabel="next >"
       onPageChange=''
       pageRangeDisplayed={5}
       pageCount={pageCount}
       previousLabel="< previous"
       renderOnZeroPageCount={null}
       containerClassName={'pagination'}
       pageClassName={'pageContainer'}
       pageLinkClassName={'changePageButton'}
       activeClassName={'active'}
     />
 </div>
  )
}
