import React, { useState } from "react";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {changeProgress,showBar} from '../action/progressBar'
import CircularProgress from "@material-ui/core/CircularProgress";
import Axios from "../utils/axios";
import axios from "axios";
const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    "&:disabled": {
      backgroundColor: "grey"
    }
  }
}));

function Login(props) {
  const classes = useStyles();
  const [loading,setLoading]=useState(false)
  let [submitValue, setsubmitValue] = useState({
    email: "",
    password: "",
  });

  const LoginStaff =async (event)=>{
    event.preventDefault()
    setLoading(true)
    try {
      const response = await Axios(props,'POST','/staff/login',false,{...submitValue}).catch(e=>{throw e})
      setLoading(false);
      localStorage.setItem('token',response.data.token)
      localStorage.setItem('permissions',response.data.permissions)
      props.history.push("/");
    } catch (error) {
      setLoading(false);
      alert(error.message)
    }
  } 

  function handleChange(event) {
    setsubmitValue({ ...submitValue, [event.target.name]: event.target.value });
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <MotorcycleIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={submitValue.email}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handleChange}
            value={submitValue.password}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={LoginStaff}
            disabled={loading }
          >
            {loading?<CircularProgress size={24} color={'secondary.main'}/>:<div>Sign In</div>}
          </Button>
        </form>
      </div>
    </Container>
  );
}

const stateProps = state=>{
  return{
    progress:state.ProgressBar.progress,
  }
}

const dispatchProps = dispatch=>{
  return{
    changeProgress:(value)=>{
      dispatch(changeProgress(value))
    },
    showBar:(value)=>{
      dispatch(showBar(value))
    }
  }
}

export default withRouter(connect(stateProps,dispatchProps)(Login))