import React,{useState} from "react";
import { PinDrop, FlashOnRounded, Info ,Edit} from "@material-ui/icons/";
import { TableCell } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import "../../../css/pageList.css";
import { yellow } from "@material-ui/core/colors";
import EditBatteryModal from './editbatteryModal'


function BatteryGPS(props) {
  const [updateData, setUpdateData]=useState({batteryId:'',status:''})
  const [editBatteryModalOpen, setEditBatteryModalOpen]=useState(false)
  
  const handlePressedGps = () => {
    props.item.setId(props.row.id);
    props.history.push({pathname:`/battery/map/${props.row.id}`, state:{ ...props.row }});
  };

  const handlePressedDetail = () => {
    props.item.setId(props.row.id)
    props.history.push({pathname:`/battery/detail/${props.row.id}`, state:{...props.row}})
  }

  const addCommand = () => {
    props.item.setDataCommand({...props.item.dataCommand,batteryId:props.row.id});
    props.item.setOpenModal(true);
  };

  
  const handlePressedEdit = () => {
    setUpdateData({...updateData,batteryId:props.row.id})
    setEditBatteryModalOpen(true)
  }

  return (
    <div>
    <TableCell align="center">
      <div className='tooltip'>
        <Edit style={{ cursor:'pointer',marginRight:'5px' }} key={props.row.index} onClick={handlePressedEdit}/>
        <span className='tooltiptext'>Edit</span>
      </div>
      <div className='tooltip'>
        <PinDrop style={{ cursor:'pointer' ,marginRight:'5px'}} key={props.row.id} onClick={handlePressedGps}/>
        <span className='tooltiptext'>Gps Battery</span>
      </div>
      <div className='tooltip'>
        <FlashOnRounded key={props.row.index} onClick={addCommand} style={{ color: yellow[800] ,cursor:'pointer',marginRight:'5px'}} />
        <span className='tooltiptext'>Command</span>
      </div>
      <div className='tooltip'>
        <Info style={{ cursor:'pointer',marginRight:'5px' }} key={props.row.index} onClick={handlePressedDetail}/>
        <span className='tooltiptext'>Detail</span>
      </div>
    </TableCell>
    <EditBatteryModal loadingProps={props.item.props}updatedData={updateData} isOpen={editBatteryModalOpen} setUpdatedData={setUpdateData} setOpen={setEditBatteryModalOpen}/>
    </div>
  );
}

export default withRouter(BatteryGPS);
