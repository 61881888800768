import React,{useState,useEffect} from 'react'
import { TableCell, Link} from '@material-ui/core'
import { Info, Edit, Cancel,GetAppRounded,DeleteForever } from '@material-ui/icons';
import {connect} from 'react-redux'
import { insertData, insertPage, insertLastPage, insertTotalPage, fetchStatus, insertLastCurrentPage, insertDataExport, insertLastTotalPage,insertDataFlush} from '../../action/rentalTable/main'
import {changeProgress,showBar} from '../../action/progressBar'
import SearchingField from '../../component/sortingfield'
import Sorting from '../../component/sortingDouble'
import Utils from '../../utils'
import '../../css/pageList.css' 
import {withRouter} from 'react-router-dom'
import EditTripModal from './components/edittrip'
import DeleteModalKtp from './components/modalDeleteKtp'
import dayjs from 'dayjs';
import { ExportReactCSV } from '../../component/export'
import bmsIdColumn from '../../component/bmsId'


const MainRental =(props)=>{
    const [dataDisplayed,setDataDisplayed]=useState([])
    const [loading,setLoading]=useState(true)
    const [idEdited, setIdEdited]=useState({id:''})
    const [editTripModalOpen, setEditTripModalOpen]=useState(false)
    const [canSearch,setCanSearch]=useState(true)
    const [dataRentalDisplayedSearch,setdataRentalDisplayedSearch]=useState([])
    const [searchMode,setSearchMode]=useState(false)
    const [sort, setSort] = useState({})
    const [dataExportRental, setDataExportRental]= useState([])
    const [openModalDeleteKtp,setOpenModalDeleteKtp]=useState(false)
    
    useEffect(()=>{setPage()},[])
    useEffect(()=>{setData()},[props.currentPage,searchMode])
    useEffect (()=>{setStatus()},[])
    useEffect (()=>{setExportData()},[props.exportingData])
    
    const setPage=async (page)=>{
        if(!searchMode){
            if (typeof page !== "number") {
              if (!props.data[props.limit * parseInt(props.currentPage) - 1]) await fetch(1)
              setLoading(false);
            } 
            else {
              if (props.data.filter(item => item.page == page).length ==0) return await fetch(page)
              props.insertPage(page);
            }
          }
    } 


    const fetch=async (page)=>{
        try {
            const response = await Utils.Axios(props,'GET',`/cs/trip/allNew?page=${page}&limit=${props.limit}&order=DESC`,true).catch(e=>{throw e})
            props.insertData(dataBuilder(response.data,props.limit))
            props.insertPage(response.data.page)
            props.insertTotalPage(response.data.totalPages)
        } catch (error) {
            alert(error.message)
        }
    }

    const fetchSearch = async()=>{
        setCanSearch(false)
        try {
          const temp = props.data.filter(item=>item.dataType!=='search')
          props.insertDataFlush(temp)
          const response = await Utils.Axios(props, 'POST',`/cs/trip/searchbyuser`,true,{...sort}).catch(e=>{throw e})
          if(!searchMode)props.setLastCurrentPageRental(props.currentPage)
          if(!searchMode)props.setLastTotalPagesRental(props.totalPages)
          props.insertData(dataBuilderSearch(response.data,'search'))
          props.insertPage(response.data.page)
          props.insertTotalPage(response.data.totalPages)
          setSearchMode(true)
        }
        catch(e){alert(e)}
        finally{setCanSearch(true)}
    }

    const cancelSearch=(e)=>{
        e.preventDefault()
        setDataDisplayed([])
        const temp = props.data.filter(item=>item.dataType!=='search')
        props.insertDataFlush(temp)
        props.insertTotalPage(props.lastTotalPages)
        props.insertPage(1)
        setSearchMode(false)
        setSort({...props.sort,whereClauseUser:'',searchValue:''})
    }

    const setStatus =  async() => {
      if (props.status!=true) await fetchExport()
    }

    const fetchExport = async() => {
        try {
          const response = await Utils.Axios(props,'POST', `/system/crud/read`, true, {dbname:"trip",
          include:[
            {dbname:'user'},
            {dbname:'battgen2',include:[{dbname:'x01'}]}
          ]
        })
          props.insertDataExport(dataBuilderSearch(response.data))
          props.fetchStatus(true)
        }
        catch(e){alert(e)}
      }
    
    const setExportData=()=>{
        const filter = props.exportingData.map((item,index)=>{
            let finalData ={}
            tableSpec.forEach(element=>{
            if(element.type==='data'){
                const arr = Utils.DataConstructor(item, element.obj,index)
                Object.assign(finalData, {
                    [element.value]: arr
                })
            }
            })
            return finalData
        })
        setDataExportRental(filter)
    }

    const navigate =(row)=>{
        // e.preventDefault()
        props.insertLastPage(props.currentPage)
        props.history.push({pathname:`/rentaldetail/${row.tripId}`, 
        state:{tripId:row.tripId}})
    }

    const handleEdit = (row,item) => {
        item.setEdit(row)
        item.openModalEdit(true)
    }

    const handleDeleteKtp = (row,item) => {
        item.setEdit(row)
        item.setOpenModalDelete(true)
    }

    const handleUnbind = async (row)=>{
        try {
            await Utils.Axios(props,'GET',`/cs/trip/unbind?tripId=${row.tripId}`,true)
            window.location.reload(false)
        } catch (error) {
            alert(error.message??'Something wrong happened')
        }
    }

    

    const actionColumn = ({row,item,props})=>{
        return(
        <TableCell align='center'>
            <div className='tooltip'>
                <Info style={{ cursor:'pointer' ,marginRight:'5px'}} onClick={navigate.bind(null,row)} />
                <span className='tooltiptextuser'>Detail</span>
            </div>
            <div className='tooltip'>
                <Link style={{ cursor:'pointer' ,marginRight:'5px'}} href={`https://ecgobikeid.com/admin/photoid/${row.userId}`} >
                    <GetAppRounded style={{ cursor:'pointer'}} />
                    <span className='tooltiptextuser'>Ktp</span>
                </Link>
            </div>
            <div className='tooltip'>
                <DeleteForever style={{ cursor:'pointer' ,marginRight:'5px' }} onClick={handleDeleteKtp.bind(null,row,item)}/>
                <span className='tooltiptext'>Delete Ktp</span>
            </div>
            {row.status == 'active'&&<div className='tooltip'>
                <Cancel style={{ cursor:'pointer' ,marginRight:'5px' }} onClick={handleUnbind.bind(null,row)}/>
                <span className='tooltiptextuser'>unbind</span>
            </div>}
        </TableCell>
        )
    }

    const tableSpec=[
        {Title:'No',obj:['index'],value:'index',shown:true,type:'data'},
        {Title:'id',obj:['id'],value:'tripId',shown:false,type:'data'},
        {Title:'userId',obj:['userId'],value:'userId',shown:false,type:'data'},
        {Title:'Data Type',obj:['dataType'],value:'dataType',shown:false,type:'data'},
        {Title:'Name',obj:['user','name'],value:'name',shown:true,type:'data'},
        {Title:'Phone Number',obj:['user','phoneNumber'],value:'phoneNumber',shown:true,type:'data'},
        {Title:'BMS ID',obj:['battgen2','x01','bmsId'],value:'BMSId',shown:false,type:'data'},
        {Title:'Battery Id',obj:['batteryId'],value:'id',shown:false,type:'data'},
        {Title:'BMS ID',shown:true,type:'action',component:bmsIdColumn, data:props},
        {Title:'GPS ID',obj:['battgen2','batteryNo'],value:'gpsId',shown:true,type:'data'},
        {Title:'Total Distance',obj:['totalDistance'],value:'totalDistance',shown:true,type:'data'},
        {Title:'Status',obj:['status'],value:'status',shown:true,type:'data'},
        {Title:'Bind Date',obj:['createdAt'],value:'createdAt',shown:true,type:'data'},
        {shown:true,type:'action',component:actionColumn, idEdit:idEdited, setEdit:setIdEdited,openModalEdit:setEditTripModalOpen,setOpenModalDelete:setOpenModalDeleteKtp}
      ]

      const setData=()=>{
        if(props.currentPage==0)return
        const filter = props.data.filter(item=>searchMode?item.page==props.currentPage&&item.dataType=='search':item.page==props.currentPage).map((item,index)=>{
          let finalData ={}
          tableSpec.forEach(element=>{
            if(element.type=='data'){
              const arr = Utils.DataConstructor(item, element.obj,index)
              Object.assign(finalData, {
                  [element.value]: arr
              })
            }
          })
          return finalData 
        })
        searchMode?setdataRentalDisplayedSearch(filter):setDataDisplayed(filter)
      }

    const handleChangePage=(data)=>{setPage(parseInt(data.selected)+1)}
    const sortingItem = [
        {sortBy:'Name',type:'whereClauseUser'},
        {sortBy:'phoneNumber',type:'whereClauseUser'},
        {sortBy:'bmsId',type:'whereClauseBattery'}
      ]
    
    const typeSort = true

    return(
        <div>
            <div style={{flexDirection:'row',display:'flex', marginTop:'55px'}}>
                <ExportReactCSV csvData={dataExportRental} fileName='RentalTable.csv' />
                <Sorting sort={sort} setSort={setSort} sortBy={sortingItem} />
                <div style={{flex:1}}>  
                <SearchingField  props={props} canSearch={canSearch} fetchSearch={fetchSearch} sort={sort} setSort={setSort} cancelSearch={cancelSearch} searchMode={searchMode} typeSort={typeSort}/>
                </div>
            </div>
            <div style={{flexDirection:'row',display:'flex',marginTop:'15px', alignItems:'center'}}>
            {searchMode?<Utils.Table dataDisplayed={dataRentalDisplayedSearch} tableSpec={tableSpec} />:<Utils.Table dataDisplayed={dataDisplayed} tableSpec={tableSpec} />}
            </div>
        {!loading&&<Utils.Pagination props={props} handleChangePageWithNumber={handleChangePage}/>}
        <EditTripModal isOpen={editTripModalOpen} props={props} setOpen={setEditTripModalOpen} idEdit={idEdited} />
        <DeleteModalKtp isOpen={openModalDeleteKtp} props={props} setOpen={setOpenModalDeleteKtp} idEdit={idEdited} />
    </div>)
}

const dataBuilder=(data,limit,dataType)=> data.data.map((item,index)=>{return {...item,createdAt:dayjs(item.createdAt).format('DD MMMM YYYY'),dataType,page:data.page,index:((data.page-1)*limit)+index+1}})
const dataBuilderSearch=(data,dataType)=> data.data.map((item,index)=>{return {...item,page:data.page,createdAt:dayjs(item.createdAt).format('DD MMMM YYYY'),dataType,index:index+1}})

const stateProps=(state)=>{
    return{
        progress:state.ProgressBar.progress,
        data:state.MainRental.data,
        currentPage:state.MainRental.page,
        totalPages:state.MainRental.totalPages,
        lastPage:state.MainRental.lastPage,
        limit:state.MainRental.limit,
        lastCurrentPage:state.MainRental.lastCurrentPage,
        lastTotalPages:state.MainRental.lastTotalPages,
        exportingData:state.MainRental.export,
        status:state.MainRental.mode,
    }
}

const dispatchProps=(dispatch)=>{
    return{
        changeProgress:(load)=>{dispatch(changeProgress(load))},
        showBar:(bool)=>{dispatch(showBar(bool))},
        insertData:(data)=>{dispatch(insertData(data))},
        insertPage:(page)=>{dispatch(insertPage(page))},
        insertLastPage:(page)=>{dispatch(insertLastPage(page))},
        insertTotalPage:(totalPage)=>{dispatch(insertTotalPage(totalPage))},
        insertDataFlush:(value,type)=>{dispatch(insertDataFlush(value,type))},
        setLastCurrentPageRental:(value)=>{dispatch(insertLastCurrentPage(value))},
        setLastTotalPagesRental:(value)=>{dispatch(insertLastTotalPage(value))},
        insertDataExport:(value)=>{dispatch(insertDataExport(value))},
        fetchStatus:(value)=>{dispatch(fetchStatus(value))},
    }
}

export default withRouter(connect(stateProps,dispatchProps)(MainRental))