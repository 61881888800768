import React, {useEffect, useState} from 'react'
import {changeProgress,showBar} from '../../action/progressBar'
import {insertData,insertPage,insertLastPage,insertTotalPage,fetchStatus,insertLastCurrentPage, insertDataExport, insertLastTotalPage,insertDataFlush} from '../../action/subscriptionFine'
import Utils from '../../utils'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import dayjs from 'dayjs'
import bmsIdColumn from '../../component/bmsId'

function DendaTable (props) {
    const [dataDisplayed,setDataDisplayed]=useState([])
    const [loading,setLoading]=useState(true)


    const tableSpec=[
        {Title:'No',obj:['index'],value:'index',shown:true,type:'data'},
        {Title:'id',obj:['id'],value:'pulsaId',shown:false   ,type:'data'},
        {Title:'Name',obj:['subscription','trip','user','name'],value:'name',shown:true,type:'data'},
        {Title:'Phone Number',obj:['subscription','trip','user','phoneNumber'],value:'phoneNumber',shown:true,type:'data'},
        {Title:'Battery Id',obj:['subscription','trip','batteryId'],value:'id',shown:false,type:'data'},
        {Title:'BMS ID',obj:['subscription','trip','battgen2','x01','bmsId'],value:'BMSId',shown:false,type:'data'},
        {Title:'BMS ID',shown:true,type:'action',component:bmsIdColumn, data:props},
        {Title:'Amount',obj:['amount'],value:'amount',shown:true,type:'data'},
        {Title:'Billing Date',obj:['billingFor'],value:'billingFor',shown:true,type:'data'},
        {Title:'Status',obj:['status'],value:'status',shown:true,type:'data'},
    ]

    useEffect(()=>{setPage()},[])
    useEffect(()=>{setData()},[props.currentPage])
    
    const setPage=async (page)=>{
            if (typeof page !== "number") {
              if (!props.data[props.limit * parseInt(props.currentPage) - 1]) await fetch(1)
              setLoading(false);
            } 
            else {
              if (props.data.filter(item => item.page == page).length ==0) return await fetch(page)
              props.insertPage(page);
            }
    } 

    const fetch=async (page)=>{
        try {
            const response = await Utils.Axios(props,'GET',`/cs/subscriptionfine/all?page=${page}&limit=${props.limit}&order=DESC`,true).catch(e=>{throw e})
            props.insertData(dataBuilder(response.data,props.limit))
            props.insertPage(response.data.page)
            props.insertTotalPage(response.data.totalPages)
        } catch (error) {
            alert(error.message)
        }
    }

    const setData=()=>{
        if(props.currentPage==0)return
        const filter = props.data.filter(item=>item.page==props.currentPage).map((item,index)=>{
          let finalData ={}
          tableSpec.forEach(element=>{
            if(element.type=='data'){
              const arr = Utils.DataConstructor(item, element.obj,index)
              Object.assign(finalData, {
                  [element.value]: arr
              })
            }
          })
          return finalData 
        })
        setDataDisplayed(filter)
    }


    const handleChangePage=(data)=>{setPage(parseInt(data.selected)+1)}

    return (
        <div>
            <div style={{flexDirection:'row',display:'flex', marginTop:'55px'}}>
            </div>
            <div style={{flexDirection:'row',display:'flex',marginTop:'15px', alignItems:'center'}}>
                <Utils.Table dataDisplayed={dataDisplayed} tableSpec={tableSpec} />
            </div>
            {!loading&&<Utils.Pagination totalPages={props.totalPages}  props={props} handleChangePageWithNumber={handleChangePage} />}
        </div>
    )
}



const dataBuilder=(data,limit)=> data.data.map((item,index)=>{
    return {...item,page:data.page,index:((data.page-1)*limit)+index+1, billingFor:item.subscription?dayjs(item.subscription.billingFor).format("MMMM YYYY"):'-'}
})


const mapStateToProps=(state)=>{
    return{
        progress:state.ProgressBar.progress,
        data:state.Denda.data,
        currentPage:state.Denda.page,
        totalPages:state.Denda.totalPages,
        lastPage:state.Denda.lastPage,
        limit:state.Denda.limit,
        lastCurrentPage:state.Denda.lastCurrentPage,
        lastTotalPages:state.Denda.lastTotalPages,
        exportingData:state.Denda.export,
        status:state.Denda.mode,
    }
}

const mapDispatchToProps=(dispatch)=>{
    return{
        changeProgress:(load)=>{dispatch(changeProgress(load))},
        showBar:(bool)=>{dispatch(showBar(bool))},
        insertData:(data)=>{dispatch(insertData(data))},
        insertPage:(page)=>{dispatch(insertPage(page))},
        insertLastPage:(page)=>{dispatch(insertLastPage(page))},
        insertTotalPage:(totalPage)=>{dispatch(insertTotalPage(totalPage))},
        insertDataFlush:(value,type)=>{dispatch(insertDataFlush(value,type))},
        setLastCurrentPagePulsa:(value)=>{dispatch(insertLastCurrentPage(value))},
        setLastTotalPagesPulsa:(value)=>{dispatch(insertLastTotalPage(value))},
        insertDataExport:(value)=>{dispatch(insertDataExport(value))},
        fetchStatus:(value)=>{dispatch(fetchStatus(value))},
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DendaTable))