import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios'
import Table from '@material-ui/core/Table';
import Modal from '@material-ui/core/Modal';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import BeatLoader from "react-spinners/BeatLoader";
import ReactPaginate from 'react-paginate';
import { Button } from '@material-ui/core';
import * as XLSX  from 'xlsx';
import { useQuery } from '@tanstack/react-query';

const Order = () => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      }
      const style2 = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        alignItems: 'center',
        width: '40%',
        boxShadow: 24,
        p: 4,
      }
    const formatter = new Intl.NumberFormat('id', {
        style:'currency',
        currency:'IDR',
        maximumFractionDigits: 0
      })
      const [loading, SetLoading] = useState(false)
    const [orders, setOrders] = useState()
    const [lenght, setLength] = useState()
    const [oneOrders, setOneOrders] = useState()
    const [isOpen, setIsOpen] = useState(false);
    const [excelData, setExcelData] = useState([]);
    const [openPayments, setOpenPayments] = useState(false);
    const [openOrders, setOpenOrders] = useState(false);
    const [openBuyers, setOpenBuyers] = useState(false);
    const [search, setSearch] = useState('')
    const [otr, setOTR] = useState('0')
    const [motor, setMotor] = useState()
    const [vehicleNew, setVehicleNew] = useState([])
    const [paketNew, setpaketNew] = useState()
    const [pageCount, setpageCount] = useState()
    const [payment_status, setPayment_status] = useState('')
    const [perPage, setPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    
    const { isLoading, refetch } = useQuery(
        {
            queryKey: ['projects', currentPage,     perPage],
            queryFn: async () => {await getData(currentPage,  perPage)},
            keepPreviousData : true,
            refetchOnWindowFocus: false
    });
    
    function getData ( currentPage) {
        setIsOpen(true)
        // e.preventDefault()
        SetLoading(true)
        axios.get(`${process.env.REACT_APP_BASEURL}/admin/order/all?perPage=${perPage}&currentPage=${currentPage}&orderBy=id&order=desc&payment_type=&status=&payment_status=${payment_status}&search=${search}&discount=&station=&sales=&shipping_method=`, {
            headers : {
                'token' : localStorage.getItem('token')
            }})
        .then((res)=>
            {
                SetLoading(false)
                setIsOpen(false)
            setpageCount(res.data.data.page)
            setOrders(res.data.data.rows)
           
            }
        )
    }
    function getSearchData (currentPage) {
        
        axios.get(`${process.env.REACT_APP_BASEURL}/admin/order/all?perPage=10&currentPage=1&orderBy=id&order=desc&payment_type=&status=&payment_status=&search=${search}&no_antrian=&discount=&station=&sales=&shipping_method=`, {
            headers : {
                'token' : localStorage.getItem('token')
            }})
        .then((res)=>
            {
            setpageCount(res.data.data.page)
            console.log(res.data.data)
            setOrders(res.data.data.rows)}
        )
    }
    function getDetail(e, ad){
        e.preventDefault()
        axios.get(`${process.env.REACT_APP_BASEURL}/admin/order/${ad.id}`, {
            headers : {
                'token' : localStorage.getItem('token')
            }})
        .then((res)=>
            {
                
            setOneOrders(res.data.data)}
        )
        setOpenPayments(true);
    }

    function getDetail1(e, ad){
        e.preventDefault()
        getMotor();
        axios.get(`${process.env.REACT_APP_BASEURL}/admin/order/${ad.id}`, {
            headers : {
                'token' : localStorage.getItem('token')
            }})
        .then((res)=>
            {
                
            setOneOrders(res.data.data)}
        )
        setOpenOrders(true);
    }

    function getDetail2(e, ad){
        e.preventDefault()
        getMotor();
        axios.get(`${process.env.REACT_APP_BASEURL}/admin/order/${ad.id}`, {
            headers : {
                'token' : localStorage.getItem('token')
            }})
        .then((res)=>
            {
                
            setOneOrders(res.data.data)}
        )
        setOpenBuyers(true);
    }

    function handleChangeMotor(e, order){
        e.preventDefault()
        axios.post(`${process.env.REACT_APP_BASEURL}/admin/order/unit/change`,{
            order_saleId: order.order_saleId,
            order_detailId: order.id,
            newvehicleTypeId:vehicleNew.id,
            newvehiclepackageId: parseInt(paketNew)
        }, {
            headers : {
                'token' : localStorage.getItem('token')
            }})
        .then((res)=>
            {
            getData()    
            }
        )
        setOpenOrders(true);
    }

    function handlePelunasan(e, payments){
        e.preventDefault()
        axios.post(`${process.env.REACT_APP_BASEURL}/admin/order/act/pelunasan`,{
            order_saleId: oneOrders.order_payments[0]?.order_saleId,
            payment_to: oneOrders.order_payments[0]?.payment_to,
            registration: otr
        }, {
            headers : {
                'token' : localStorage.getItem('token')
            }})
        .then((res)=>
            {
            getData(1)    
            }
        )
      
    }

    function getMotor(){
        axios.get(`${process.env.REACT_APP_BASEURL}/sale/get/all/products?`).then((res)=>{
            setMotor(res.data.data)
          })
    }
    const  handlePageClick = async (data)=>{
        setCurrentPage(data.selected+1  )
    }

    function handleApprove(e, ad){
        e.preventDefault()
        axios.post(`${process.env.REACT_APP_BASEURL}/sale/order/approve`,{
            order_saleId: ad.order_customer?.order_saleId,
    
        }, {
            headers : {
                'token' : localStorage.getItem('token')
            }})
        .then((res)=>
            {
                alert(res.data.message)
            getData(1)    
            }
        )
      
    }

    function handleReject(e, ad){
        e.preventDefault()
        axios.post(`${process.env.REACT_APP_BASEURL}/sale/order/reject`,{
            order_saleId: ad.order_customer?.order_saleId,
    
        }, {
            headers : {
                'token' : localStorage.getItem('token')
            }})
        .then((res)=>
            {
                alert(res.data.message)
            getData(1)    
            }
        )
      
    }

    function handleExcelInit(e){
     
    }

   async function handleExcel(){
        SetLoading(true)
        var aw = new Array();

        for(let i=0;i< orders?.length;i++){

           await axios.get(`${process.env.REACT_APP_BASEURL}/admin/order/${orders[i]?.id}`, {
                headers : {
                    'token' : localStorage.getItem('token')
                }})
            .then((res)=>
                {
                    aw.push(res.data.data.order_customer)
                    setExcelData(aw)
                })
        }
       
        var data=[]
           
            aw.map((element,idx) => {
                
                data.push({
                    'NO':idx+1,
                    'tanggal':element.createdAt.slice(0,10),
                    'nama':element.name,
                    'HP':element.phoneNumber,
                    'NIK':element.ktp,
                    'nama ayah':element.mother_name,
                    'no_antrian':element.no_antrian,
                    'cust_type':element.customer_type,
                    'status':element.payment_status,
                    'alamat':`${element.order_addresses[0]?.address}, KEC : ${element.order_addresses[0]?.kecamatan}, PROV: ${element.order_addresses[0]?.provinsi}` ,
                })
            })
            
            var wb = XLSX.utils.book_new()
            let ws = XLSX.utils.json_to_sheet(data)
            ws['!cols'] = [];
            ws['!cols'] = [
                {wch:5},
                {wch: 11},
                {wch: 20},
                {wch: 20},
                {wch: 17},
                {wch: 10},
                {wch: 20},
                {wch: 10},
                {wch: 100},
                {wch: 80},
                {wch: 20}, 
                {wch: 15},
                {wch: 25},// "characters"
            ];

            XLSX.utils.book_append_sheet(wb,ws, 'mySheet1');
            XLSX.writeFile(wb, 'myExcel.xlsx') 
              
            SetLoading(false)
}

    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_BASEURL}/admin/order/all?perPage=10&currentPage=1&orderBy=id&order=desc&payment_type=&status=&payment_status=&search=&discount=&station=&sales=&shipping_method=`, {
            headers : {
                'token' : localStorage.getItem('token')
            }})
        .then((res)=>
            {
                setpageCount(res.data.data.page)
                setOrders(res.data.data.rows)}
        )
    },[])

    
  return (
    <div style={{paddingTop:'5rem'}}>
        <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{overflow:"auto", top:"0"}}
      >
        <Box sx={style2} style={{display:'flex', gap:'2rem', flexDirection:'column'}}>
        <BeatLoader  color='white' loading={loading} size={50}/>
        </Box>
    </Modal>

    <Modal e
      open={openPayments}
      onClose={()=>setOpenPayments(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{overflow:"auto", top:"0"}}
    >
      <Box sx={style} style={{textAlign:"center"}}>
            <button style={{marginBottom:'1rem'}} onClick={handlePelunasan}>KIRIM INVOICE PELUNASAN</button> &nbsp;
            <select value={otr} onChange={(e)=>setOTR(e.target.value)}>
                <option value='0'>OFF THE ROAD</option>
                <option value='1'>ON THE ROAD</option>
            </select>
            <Table style={{borderTop:"2px solid black"}}>
              <TableRow >
                  <TableCell style={{fontWeight:"bold"}} className='tid'>Payment ID</TableCell>
                  <TableCell style={{fontWeight:"bold"}}>Payment Type</TableCell>
                  <TableCell style={{fontWeight:"bold"}}>To</TableCell>
                  <TableCell style={{fontWeight:"bold"}}>Amount</TableCell>
                  <TableCell style={{fontWeight:"bold"}}>Payment Status</TableCell>
                  <TableCell style={{fontWeight:"bold"}}>Bukti</TableCell>
                  <TableCell style={{fontWeight:"bold"}}>Invoice</TableCell>
              </TableRow>

              
                    {oneOrders?.order_payments?.map((payments)=>{
                        return(
                            <>
                            <TableBody>
                            <TableCell>{payments.id}</TableCell>
                            <TableCell>{payments.payment_type}</TableCell>
                            <TableCell>{payments.payment_to}</TableCell>
                            <TableCell>{formatter.format(payments.amount)}</TableCell>
                            <TableCell>{payments.payment_status}</TableCell>
                            <TableCell>{ payments.attachment == null ? <><p>Belum Upload</p></>
                                :
                                <button onClick={()=>window.open(`https://ecgobikeid.com/${payments?.attachment}`)}>Bukti</button>}
                            </TableCell>
                            <TableCell >{ payments.invoice_link == null ? <><p>Belum Upload</p></>
                                :
                                <button onClick={()=>window.open(`${payments.invoice_link}`)}>Invoice</button>}
                            </TableCell>    
                            </TableBody>

                            </>
                        )
                    })}
                        
              
            </Table>
            
            
      </Box>
    </Modal>
    
    <Modal
      open={openOrders}
      onClose={()=>setOpenOrders(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{overflow:"auto", top:"0"}}
    >
      <Box sx={style} style={{textAlign:"center"}}>

            <Table >
              <TableRow >
                  <TableCell style={{fontWeight:"bold"}} className='tid'>Qty</TableCell>
                  <TableCell style={{fontWeight:"bold"}}>Item</TableCell>
                  <TableCell style={{fontWeight:"bold"}}>Paket Baterai</TableCell>
                  <TableCell style={{fontWeight:"bold"}}>OTR/OFTR</TableCell>
                  <TableCell style={{fontWeight:"bold"}}>Edit</TableCell>
              </TableRow>

              
                    {oneOrders?.order_details?.map((orders)=>{
                        return(
                            <>
                            <TableBody>
                            <TableCell>{orders.qty}</TableCell>
                            <TableCell>{orders.vehicleType?.vehicleModel?.name} {orders.vehicleType?.vehicleColor?.name}</TableCell>
                            <TableCell>{orders.vehiclepackage?.description}</TableCell>
                            <TableCell>
                                {orders.otr === 0 ? 
                                    <span>OFF THE ROAD</span> : 
                                 orders.otr === 1 &&  
                                    <span>OFF THE ROAD</span>
                                    }
                            </TableCell>
                            <TableCell>
                                <select onChange={(e)=>setVehicleNew(JSON.parse(e.target.value))}>
                                    <option>Tukar Motor</option>
                                    {motor?.map((motor)=>{
                                        return(
                                            <option value={JSON.stringify(motor)}>{motor.vehicleModel.name} - {motor.vehicleColor.name}</option>
                                        )
                                    })}
                                </select>
                            
                                <select onChange={(e)=>setpaketNew(e.target.value)}>
                                    <option>Tukar Motor</option>
                                    {vehicleNew.vehiclepackage?.map((paket)=>{
                                        return(
                                            <option value={paket.id}>{paket.description}</option>
                                        )
                                    })}
                                </select>

                                <button onClick={(e)=>handleChangeMotor(e,orders)}>Ubah Pesanan</button>
                            </TableCell>
                            </TableBody>

                            </>
                        )
                    })}
                        
              
            </Table>
            
            
      </Box>
    </Modal>

    <Modal
      open={openBuyers}
      onClose={()=>setOpenBuyers(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{overflow:"auto", top:"0"}}
    >
      <Box sx={style} style={{textAlign:"center"}}>

            <Table >
              <TableRow >
                  <TableCell style={{fontWeight:"bold"}} className='tid'>Nama</TableCell>
                  <TableCell style={{fontWeight:"bold"}}>HP</TableCell>
                  <TableCell style={{fontWeight:"bold"}}>NIK</TableCell>
                  <TableCell style={{fontWeight:"bold"}}>Nama Ayah</TableCell>
                  <TableCell style={{fontWeight:"bold"}}>Antrian</TableCell>
                  <TableCell style={{fontWeight:"bold"}}>Status</TableCell>
                  <TableCell style={{fontWeight:"bold"}}>Alamat KTP</TableCell>
                  <TableCell style={{fontWeight:"bold"}}>Alamat Pengiriman</TableCell>
                  <TableCell style={{fontWeight:"bold"}}>Foto KTP</TableCell>
              </TableRow>

                    <TableBody>
                        <TableCell>{oneOrders?.order_customer?.name}</TableCell>
                        <TableCell>{oneOrders?.order_customer?.phoneNumber}</TableCell>
                        <TableCell>{oneOrders?.order_customer?.ktp}</TableCell>
                        <TableCell>{oneOrders?.order_customer?.mother_name}</TableCell>
                        <TableCell>{oneOrders?.order_customer?.no_antrian}</TableCell>
                        <TableCell>{oneOrders?.order_customer?.status}</TableCell>
                        <TableCell>{oneOrders?.order_customer?.order_addresses[0]?.address}, {oneOrders?.order_customer?.order_addresses[0]?.kecamatan}, {oneOrders?.order_customer?.order_addresses[0]?.kota}, {oneOrders?.order_customer?.order_addresses[0]?.provinsi}</TableCell>
                        <TableCell>{oneOrders?.order_customer?.order_addresses[1]?.address}, {oneOrders?.order_customer?.order_addresses[1]?.kecamatan}, {oneOrders?.order_customer?.order_addresses[1]?.kota}, {oneOrders?.order_customer?.order_addresses[1]?.provinsi}</TableCell>
                        <TableCell>{oneOrders?.order_customer?.ktp_image == null ? <><p>Belum Upload</p></>
                                :
                                <button onClick={()=>window.open(`https://ecgobikeid.com/${oneOrders?.order_customer?.ktp_image}`)}>Bukti</button>}
                            </TableCell>
                    </TableBody>
                   
                        
              
            </Table>
            
            
      </Box>
    </Modal>

      <input onChange={(e)=>setSearch(e.target.value)} placeholder="Search" style={{height:"2.5rem"}}></input>
      <button onClick={getData} style={{height:"2.5rem"}}>Search</button>

      
      &nbsp;
      <select style={{height:"2.3rem"}} onChange={(e)=>{setPerPage(e.target.value)}}>
            <option disabled selected>Per Page</option>
            <option value='all'>all</option>
            <option value='10'>10</option>
            <option value='30'>30</option>
            <option value='50'>50</option>
    </select>  
      &nbsp;

      <select onChange={(e)=>setPayment_status(e.target.value) } style={{height:"2.5rem"}}>
        <option value=''>Filter By</option>
        <option value='PAID'>Payment Status - PAID</option>
        <option value='UNPAID'>Payment Status - UNPAID</option>
        <option value='PARTIAL'>Payment Status - PARTIAL</option>
      </select>
      <button onClick={(e)=>getData(1)} style={{height:"2.5rem"}}>Filter</button>
      <button type="button"  className='addUserBtn'onClick={handleExcel }>{loading ?<BeatLoader loading={loading} color='white' size={10}/> : 'Export Now'}</button>
     
      {/* <button type="button"  className='addUserBtn' style={{display:excelData.length  && 'none'}} onClick={handleExcel}>Export Excel</button> */}
    <br/>
    <br/>

    <Paper>
        <Table >
        
            <TableRow >
                <TableCell style={{fontWeight:"bold"}} className='tid'>ID</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Tanggal</TableCell>
                <TableCell style={{fontWeight:"bold"}}>HP</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Nama</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Invoice Number</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Status</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Payment Status</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Dealer</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Kode Antrian</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Buyer Details</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Order Details</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Payment Details</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Action</TableCell>
            </TableRow>
        
            
            <TableBody>
                {orders?.map((ad)=>{
                    return (
                        <>
                            <TableRow key={ad.id}>
                                <TableCell >{ad.id}</TableCell>
                                <TableCell >{ad.createdAt.slice(0,10)}</TableCell>
                                <TableCell >{ad?.order_customer?.phoneNumber}</TableCell>
                                <TableCell >{ad.order_customer?.name}</TableCell>
                                <TableCell >{ad.invoice_number}</TableCell>
                                <TableCell >{ad.order_customer?.customer_type}</TableCell>
                                <TableCell >{ad.payment_status}</TableCell>
                                <TableCell >{ad.payment_type == 'dealer' ?  ad.station?.name :<></> }</TableCell>
                                <TableCell >{ad?.order_customer?.no_antrian}</TableCell>
                                <TableCell ><button onClick={(e)=>getDetail2(e, ad)}>Detail</button></TableCell>
                                <TableCell ><button onClick={(e)=>getDetail1(e, ad)}>Detail</button></TableCell>
                                <TableCell ><button onClick={(e)=>getDetail(e, ad)}>Detail</button></TableCell>
                                <TableCell ><button onClick={(e)=>handleApprove(e, ad)}>Approve</button>&nbsp;<button onClick={(e)=>handleReject(e, ad)}>Reject</button></TableCell>   
                            </TableRow>
                        </>
                    )
                })}
            </TableBody>
            
        </Table>
    </Paper>  
    
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
        containerClassName={'pagination'}
        pageClassName={'pageContainer'}
        pageLinkClassName={'changePageButton'}
        activeClassName={'active'}
      />
  </div>
  )
}

export default Order