import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios'
import { useLocation } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import Modal from '@material-ui/core/Modal';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import BeatLoader from "react-spinners/BeatLoader";
import ReactPaginate from 'react-paginate';
import { Button } from '@material-ui/core';


export default function Dailypayment() {
    const formatter = new Intl.NumberFormat('id', {
        style:'currency',
        currency:'IDR',
        maximumFractionDigits: 0
      })
    const location = useLocation()
    const [orders, setOrders] = useState()
    const [pageCount, setpageCount] = useState()

    function getData(){
        axios.get(`${process.env.REACT_APP_BASEURL}/newAdmin/rentbike/payments?currentPage=1&orderBy=id&dir=desc&perPage=all&userId=${location.state.userId}`, {
            headers : {
                'token' : localStorage.getItem('token')
            }
        }).then((response)=>
          {
         setpageCount(response.data.data.count)
            setOrders(response.data.data.rows)
          }
        ) 
    }

    useEffect(()=>{
        getData()
    },[])

  return (

    <div style={{paddingTop:'5rem'}}>
      {pageCount === 0 ?  <a>No Daily Payment</a>
      :  
    <Paper>
    <Table style={{paddingTop:'5rem'}}>
    
        <TableRow >
            <TableCell style={{fontWeight:"bold"}} className='tid'>ID</TableCell>
            <TableCell style={{fontWeight:"bold"}}>Tanggal</TableCell>
            <TableCell style={{fontWeight:"bold"}}>Amount</TableCell>
            <TableCell style={{fontWeight:"bold"}}>Payment Status</TableCell>
            <TableCell style={{fontWeight:"bold"}}>Payment Type</TableCell>
            <TableCell style={{fontWeight:"bold"}}>Paid At</TableCell>
        </TableRow>
    
        
        <TableBody>
            {orders?.map((ad)=>{
                return (
                    <>
                        <TableRow key={ad.id}>
                            <TableCell>{ad.id}</TableCell>
                            <TableCell>{ad.createdAt.slice(0,10)}</TableCell>
                            <TableCell>{formatter.format(ad?.amount)}</TableCell>
                            <TableCell>{ad.paymentStatus}</TableCell>
                            <TableCell>{ad.paymentType}</TableCell>
                            <TableCell>{ad.paidAt?.slice(0,10)} / { ad.paidAt?.slice(11,20)}</TableCell>
                        </TableRow>
                    </>
                )
            })}
        </TableBody>
        
    </Table>
</Paper>  
}
</div>
    
  )
}
